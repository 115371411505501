import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Avatar, CircularProgress, Tooltip } from '@material-ui/core'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'

import * as CONSTANTS from '../../../../utils/constants'
import * as TICKET_REQUESTS from '../../../../redux/actions/tickets'
import * as NOTIFICATIONS from '../../../../utils/notification'
import { setImagesClickListeners } from '../../../../utils/helpers'

import AddComment from './AddComment'
import Attachments from '../../../common/Attachments'
import SimpleModal from '../../../common/SimpleModal'

import {
    PictureAsPdf as PDFIcon,
    PersonPin as PersonIcon,
    Assignment as XlsIcon
} from '@material-ui/icons'


const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 50,
        minHeight: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '6px 20px'
    },
    titleText: {
        fontSize: 20,
        fontWeight: 500
    },
    contentContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 0px 8px',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 75px)'
    },
    leftAuto: {
        marginLeft: 'auto'
    },
    content: {
        padding: '8px 28px'
    },
    pointer: {
        cursor: 'pointer'
    },
    icon: {
        fontSize: 22,
        color: '#3d4147',
    },
    commentIcon: {
        borderRadius: '0%'
    },
    extraSize: {
        fontSize: 58
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    equalsFlex: {
        flex: 1
    },
    paddingTop: {
        paddingTop: 18
    },
    paddingLeft: {
        paddingLeft: 8
    },
    paddingRight: {
        paddingRight: 12
    },
    marginTop: {
        marginTop: 20
    },
    commentsContainer: {
        padding: '6px 14px',
        overflow: 'auto'
    },
    comments: {
        paddingBottom: 12,
        borderBottom: '1px solid rgba(0,0,0,0.15)',
    },
    comment: {
        paddingBottom: 14
    },
    buttons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    buttonElement: {
        marginLeft: 'auto'
    },
    spinnerStyle: {
        height: '35px !important',
        width: '150px !important'
    },
    button: {
        marginLeft: 12,
        backgroundColor: '#ffffff',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '1.5px 1.5px 5px 0px rgba(0,0,0,0.25)',
        color: '#4D8FDC',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            opacity: 0.75
        }
    },
    circle: {
        height: 10,
        width: 10,
        borderRadius: '50%',
        backgroundColor: 'red'
    },
    verticalLine: {
        height: 'calc(100% - 10px)',
        marginLeft: 4
    },
    commenterInitialsContainer: {
        width: 40,
        height: 40,
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    fontBold: {
        fontWeight: 'bold'
    },
    commenterAndDateContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 8,
        paddingTop: 2
    },
    commenterName: {
        fontSize: 14,
        fontWeight: 500,
        color: 'black'
    },
    dateText: {
        fontSize: 12,
        fontWeight: 400,
        color: 'black'
    },
    commentContainer: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        fontWeight: 400,
        color: 'black',
        marginTop: '-8px'
    },
    commentContainerChanges: {
        marginTop: '4px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: 14,
        fontWeight: 400,
        color: 'black'
    },
    subjectText: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingRight: 4
    },
    imageStyle: {
        objectFit: 'contain',
        width: '100%'
    },
    circularProgress: {
        height: '20px !important',
        width: '20px !important',
        color: 'white',
        marginRight: 5
    },
    processText: {
        position: 'relative',
        top: '-4px'
    },
    commentFlexContainer: {
        flex: 1
    },
    dateAndTimeContainer: {
        marginLeft: 'auto'
    },
    generatePDFSpinner: {
        width: '30px !important',
        height: '30px !important'
    },
    generatePDfText: {
        fontSize: '16px',
        paddingLeft: '12px'
    },
    progress: {
        width: 20,
        height: 20,
        margin: 'auto'
    }
})

class TicketComments extends Component {

    state = {
        comment: '',
        reset: false,
        loading: false,
        loadingClosed: false,
        openImageModal: false,
        imageSrc: '',
        loadingReport: false,
        filesToUpload: [],
        isSending: false
    }

    onCreate = () => {
        if (!this.state.comment) return NOTIFICATIONS.error(this.props.language.utils.fillComment)
        this.setState({ isSending: true })
        this.props.createComment(this.props.ticketId, { comment: this.state.comment, writtenBy: this.props.loginReducer._id, status: this.props.ticket.status }).then(ticket => {
            if (this.state.filesToUpload.length) {
                let form = new FormData()
                this.state.filesToUpload.forEach((file, index) => {
                    form.append(`file-${index}`, file)
                })

                return this.props.uploadFiles(ticket._id, ticket.comments.length, form).then(() => {
                    this.props.getTicket().then(() => {
                        this.setImagesTag()
                    })
                    this.setState({ comment: '', reset: true, filesToUpload: [], isSending: false })
                })
            }
            else {
                this.props.getTicket().then(() => {
                    this.setImagesTag()
                })
                this.setState({ comment: '', reset: true, filesToUpload: [], isSending: false })
            }
        }).catch(err => {
            console.log(err)
            this.setState({ isSending: false })
        })
    }

    setFiles = files => {
        this.setState({ filesToUpload: files })
    }

    setComment = newComment => {
        this.setState({ comment: newComment, reset: false })
    }

    returnField = (value, field) => {
        switch (field) {
            case 'priority': return this.props.language.comments.priority[value]
            case 'status': return this.props.language.comments.status[value]
            case 'type': return this.props.language.comments.type[value] || value.toLowerCase()
            case 'assignee': return value

            default: return value
        }
    }

    returnColor = (status, field, currentStatus) => {
        if (field === 'status') {
            const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === status)
            if (statusIndex > -1) return CONSTANTS.STATUS[statusIndex].color
            else return 'black'
        }
        else {
            return 'black'
        }
    }

    openImageModal = src => {
        this.setState({
            openImageModal: true,
            imageSrc: src
        })
    }

    componentDidMount() {
        this.setImagesTag()
    }

    setImagesTag = () => {
        setImagesClickListeners(src => this.openImageModal(src))
    }

    renderStatusButton = () => {
        let { classes } = this.props
        if (!this.props.ticket && !this.props.ticket.status) return

        let changeStatusHandler = status => {
            if (!this.state.comment && !this.state.comment.length) {
                this.setState({ loading: false, loadingClosed: false })
                return NOTIFICATIONS.error(this.props.language.toastr.ticket.changeStatusWithoutComment)
            }

            if (this.props.ticket && !this.props.ticket.assignedCompany) {
                this.setState({ loading: false, loadingClosed: false })
                return NOTIFICATIONS.error(this.props.language.toastr.ticket.changeStatusWithoutAssignedCompany)
            }

            this.props.changeStatus(this.props.ticket._id, status, this.state.comment)
                .then(() => {
                    if (this.state.filesToUpload.length) {
                        let form = new FormData()
                        this.state.filesToUpload.forEach((file, index) => {
                            form.append(`file-${index}`, file)
                        })

                        this.props.uploadFiles(this.props.ticket._id, this.props.ticket.comments.length + 1, form).then(() => {
                            this.props.getTicket()
                        })
                    }
                    else {
                        this.props.getTicket()
                    }
                })
                .then(() => this.setState({ comment: '', reset: true }))
                .then(() => {
                    this.setState({ loading: false, loadingClosed: false })
                    NOTIFICATIONS.success(this.props.language.toastr.ticket.statusChanged)
                })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.toastr.ticket.errorChangingStatus)
                    this.setState({ loading: false, loadingClosed: false })
                })
        }

        switch (this.props.ticket.status) {
            case CONSTANTS.TICKET_STATUSES.NEW: return <Button
                disabled={this.state.loading}
                onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.PENDING))}
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.PENDING, color: 'white' }}
                className={`${this.state.loading ? classes.spinnerStyle : ''}`}
            >
                {this.state.loading ? <div><CircularProgress className={this.props.classes.circularProgress} /><span className={this.props.classes.processText}>{this.props.language.utils.processing}</span></div> : this.props.language.buttons.move + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.PENDING]}
            </Button>
            case CONSTANTS.TICKET_STATUSES.PENDING: return <Button
                disabled={this.state.loading}
                onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.VERIFY))}
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.VERIFY, color: 'white' }}
                className={`${this.state.loading ? classes.spinnerStyle : ''}`}
            >
                {this.state.loading ? <div><CircularProgress className={this.props.classes.circularProgress} /><span className={this.props.classes.processText}>{this.props.language.utils.processing}</span></div> : this.props.language.buttons.moveTo + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.VERIFY]}
            </Button>
            case CONSTANTS.TICKET_STATUSES.VERIFY: return <>
                {this.props.loginReducer.role !== CONSTANTS.USER && this.props.loginReducer.role !== CONSTANTS.FM ? <Button
                    disabled={this.state.loading}
                    onClick={() => {
                        this.setState({ loadingClosed: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.CLOSED))
                    }}
                    style={{ backgroundColor: CONSTANTS.STATUS_COLORS.CLOSED, color: 'white', marginRight: 10 }}
                    className={`${this.state.loadingClosed ? classes.spinnerStyle : ''}`}
                >
                    {this.state.loadingClosed ? <div><CircularProgress className={this.props.classes.circularProgress} /><span className={this.props.classes.processText}>{this.props.language.utils.processing}</span></div> : this.props.language.buttons.moveTo + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.CLOSED]}
                </Button> : null}
                <Button
                    disabled={this.state.loading}
                    onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.PENDING))}
                    style={{ backgroundColor: CONSTANTS.STATUS_COLORS.PENDING, color: 'white' }}
                    className={`${this.state.loading ? classes.spinnerStyle : ''}`}
                >
                    {this.state.loading ? <div><CircularProgress className={this.props.classes.circularProgress} /><span className={this.props.classes.processText}>{this.props.language.utils.processing}</span></div> : this.props.language.buttons.move + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.PENDING]}
                </Button>
            </>

            default: return null
        }
    }

    checkForRole = () => {
        if (this.props.loginReducer.role === CONSTANTS.FM || this.props.loginReducer.role === CONSTANTS.PM || this.props.loginReducer.role === CONSTANTS.ADMIN || this.props.loginReducer.isOfficial || (this.props.ticket.assignedCompany && this.props.ticket.assignedCompany.official && this.props.loginReducer._id === this.props.ticket.assignedCompany.official._id))
            return true
        else
            return false
    }

    setPadding = (value, status) => {
        const newPaddingValue = 0
        const pendingPaddingValue = 30
        const verifyPaddingValue = 70
        const closedPaddingValue = 110

        if (value === 'new') return newPaddingValue
        else if (value === 'pending') return pendingPaddingValue
        else if (value === 'verify') return verifyPaddingValue
        else if (value === 'closed') return closedPaddingValue
        else {
            if (status === 'new') return newPaddingValue
            else if (status === 'pending') return pendingPaddingValue
            else if (status === 'verify') return verifyPaddingValue
            else if (status === 'closed') return closedPaddingValue
            else return 0
        }
    }

    generatePdf = () => {
        this.setState({ loadingReport: true }, () => {
            this.props.generatePdf(this.props.ticket._id)
                .then(() => {
                    NOTIFICATIONS.success(this.props.language.toastr.ticket.reportGenerated)
                    this.setState({ loadingReport: false })
                })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.toastr.ticket.reportNotGenerated)
                    this.setState({ loadingReport: false })
                })
        })
    }

    generateXls = () => {
        this.setState({ loadingReport: true }, () => {
            this.props.generateXls(this.props.ticket._id)
                .then(() => {
                    NOTIFICATIONS.success(this.props.language.toastr.ticket.reportGenerated)
                    this.setState({ loadingReport: false })
                })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.toastr.ticket.reportNotGenerated)
                    this.setState({ loadingReport: false })
                })
        })
    }

    setCurvedLine = (currentStatus, nextStatus) => {
        if (currentStatus === 'new') return {
            paddingLeft: 30,
            height: 40,
            border: `2px solid ${this.returnColor(nextStatus, 'status')}`,
            borderTop: 'none',
            borderRight: 'none',
            width: 1,
            borderBottomLeftRadius: '80%',
            position: 'relative',
            left: 3,
        }
        if (currentStatus === 'pending' && nextStatus === 'verify') return {
            paddingLeft: 30,
            height: 40,
            border: `2px solid ${this.returnColor(nextStatus, 'status')}`,
            borderTop: 'none',
            borderRight: 'none',
            width: 9,
            position: 'relative',
            left: 35,
            borderBottomLeftRadius: '80%',
        }
        if (currentStatus === 'verify' && nextStatus === 'pending') return {
            paddingLeft: 30,
            height: 40,
            border: `2px solid ${this.returnColor(nextStatus, 'status')}`,
            borderLeft: 'none',
            borderTop: 'none',
            borderBottomRightRadius: '80%',
            width: 8,
            position: 'relative',
            left: 34,
        }
        if (currentStatus === 'verify' && nextStatus === 'closed') return {
            paddingLeft: 30,
            height: 40,
            border: `2px solid ${this.returnColor(nextStatus, 'status')}`,
            borderTop: 'none',
            borderRight: 'none',
            width: 9,
            position: 'relative',
            left: 75,
            borderBottomLeftRadius: '80%',
        }
        return null
    }


    onDownloadImage = () => {
        let a = document.createElement("a");
        a.href = this.state.imageSrc
        let extension = this.state.imageSrc.split(';')[0].split('/').pop()
        a.download = `Image.${extension}`
        a.click()
        this.setState({ openImageModal: false })
    }

    render() {
        let { classes } = this.props
        const loggedUserProfileImage = this.props.loginReducer.profileImagePath ? `${CONSTANTS.BACKEND_URL}/static/${this.props.loginReducer.profileImagePath}` : ''
        return (
            <>
                <SimpleModal
                    size="lg"
                    title={this.props.language.titles.ticketImage}
                    withCancelButton={false}
                    open={this.state.openImageModal}
                    onCancel={() => this.setState({ openImageModal: false })}
                    onClose={() => this.setState({ openImageModal: false })}
                    cancelButtonText={this.props.language.buttons.close}
                    acceptButtonText={this.props.language.buttons.download}
                    onAccept={this.onDownloadImage} >
                    <img className={classes.imageStyle} src={this.state.imageSrc}></img>
                </SimpleModal>
                <div className={classes.container}>
                    <div className={classes.titleContainer}>
                        <>
                            <SimpleModal
                                maxWidth='sm'
                                open={this.state.loadingReport}>
                                <Button ><CircularProgress className={classes.generatePDFSpinner} /><span className={classes.generatePDfText}>{this.props.language.toastr.ticket.reportIsGenerating}</span>  </Button>
                            </SimpleModal>
                            <span className={classes.titleText}>{this.props.language.titles.historyAndComments}</span>
                            <div className={classes.leftAuto}>
                                <Tooltip title={this.props.language.tooltip.generatePDF}>
                                    <Button className={classes.button} onClick={this.generatePdf}>
                                        <PDFIcon className={`${classes.icon} ${classes.pointer}`} />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={this.props.language.tooltip.generateXLS}>
                                    <Button className={classes.button} onClick={this.generateXls}>
                                        <XlsIcon className={`${classes.icon} ${classes.pointer}`} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </>
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={`${classes.commentsContainer} ${classes.flexColumn}`}>
                            <div id='comments' className={classes.comments}>
                                {this.props.ticket.comments.map((comment, index) => {
                                    const commenterProfileImage = comment.writtenBy.profileImage ? `${CONSTANTS.BACKEND_URL}/static/${comment.writtenBy.profileImage.path}` : ''
                                    return (
                                        <>
                                            {!comment.statusChange ?
                                                <div className={classes.flexRow} style={{ paddingLeft: this.setPadding(comment.status) }}>
                                                    <div className={classes.paddingRight}>
                                                        {index === 0 ? <div className={classes.circle}></div> : null}
                                                        <div className={classes.verticalLine} style={{ borderLeft: `2px solid ${this.returnColor(comment.status, 'status')}` }}></div>
                                                        <div className={classes.circle} style={{ backgroundColor: `${this.returnColor(comment.status, 'status')}` }}></div>
                                                    </div>
                                                    <div className={`${classes.flexColumn} ${classes.commentFlexContainer}`}>
                                                        <div className={`${classes.equalsFlex} ${classes.flexRow} ${classes.marginTop}`}>
                                                            <div className={classes.commenterInitialsContainer} style={{
                                                                backgroundColor: this.returnColor(comment.status, 'status'),
                                                            }}>
                                                                {commenterProfileImage ? <Avatar aria-label="recipe" src={commenterProfileImage} className={`${classes.icon} ${classes.commentIcon} ${classes.extraSize}`} /> : <span className={classes.fontBold}>{`${comment.writtenBy.firstName.charAt(0).toUpperCase()}${comment.writtenBy.lastName.charAt(0).toUpperCase()}`}</span>}

                                                            </div>
                                                            <div className={classes.commenterAndDateContainer}>
                                                                <span className={classes.commenterName}>{`${comment.writtenBy.firstName} ${comment.writtenBy.lastName} <${comment.writtenBy.email}>`}</span>
                                                                <span className={classes.dateText}>{` ${comment.writtenBy.company ? comment.writtenBy.company.name + ' - ' : ''}${comment.writtenBy.role}`} </span>
                                                            </div>
                                                            <div className={classes.dateAndTimeContainer} >
                                                                <span className={classes.dateText}>{`${comment.writtenAt ? moment(comment.writtenAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT) : this.props.language.comments.undefined}`}</span>
                                                            </div>
                                                        </div>
                                                        <div className={classes.equalsFlex}>
                                                            <div className={classes.commentContainer}>
                                                                {index === 0 ? <p className={classes.subjectText}>{`${this.props.language.utils.subject} ${this.props.ticket.subject}.`}<br></br></p> : null}
                                                                {ReactHtmlParser(comment.comment)}
                                                            </div>
                                                        </div>
                                                        <Attachments
                                                            files={comment.files}
                                                            id={this.props.ticketId}
                                                            onDownload={this.props.downloadTicketFiles}
                                                        />
                                                    </div>
                                                </div>
                                                : <>
                                                    {comment.field === 'status' ? <div style={this.setCurvedLine(comment.status, comment.comment)}></div> : null}
                                                    <div className={classes.flexRow} style={{ paddingLeft: this.setPadding(comment.comment, comment.status) }}>
                                                        <div className={classes.paddingRight}>
                                                            {index === 0 ? <div className={classes.circle}></div> : null}
                                                            <div className={classes.verticalLine} style={{ borderLeft: `2px solid ${this.returnColor(comment.comment, 'status', comment.status)}` }}></div>
                                                            <div className={classes.circle} style={{ backgroundColor: `${this.returnColor(comment.comment, 'status', comment.status)}` }}></div>
                                                        </div>
                                                        <div className={`${classes.flexColumn} ${classes.commentFlexContainer}`}>
                                                            <div className={`${classes.equalsFlex} ${classes.flexRow} ${classes.marginTop}`}>
                                                                <div className={classes.commenterInitialsContainer} style={{
                                                                    backgroundColor: this.returnColor(comment.comment, 'status')
                                                                }}>
                                                                    {commenterProfileImage ? <Avatar aria-label="recipe" src={commenterProfileImage} className={`${classes.icon} ${classes.commentIcon} ${classes.extraSize}`} /> : <span className={classes.fontBold}>{`${comment.writtenBy.firstName.charAt(0).toUpperCase()}${comment.writtenBy.lastName.charAt(0).toUpperCase()}`}</span>}
                                                                </div>
                                                                <div className={classes.commenterAndDateContainer}>
                                                                    <span className={classes.commenterName}>{`${this.props.language.comments.ticketChange.toUpperCase()} ${this.props.language.comments.change[comment.field].toUpperCase()}`}</span>
                                                                    <span className={classes.dateText}>{`${comment.writtenBy.company ? comment.writtenBy.company.name + ' - ' : ''}${comment.writtenBy.role}`} </span>
                                                                </div>
                                                                <div className={classes.dateAndTimeContainer}>
                                                                    <span className={classes.dateText}>{`${moment(comment.writtenAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}`}</span>
                                                                </div>
                                                            </div>
                                                            <div className={classes.equalsFlex}>
                                                                <div className={`${classes.commentContainerChanges}`}>
                                                                    <span>{`${this.props.language.utils.modified} ${this.props.language.comments.from}`}</span>&nbsp;
                                                                    <span className={classes.fontBold} style={{ color: this.returnColor(comment.changedFrom, comment.field) }}>{this.returnField(comment.changedFrom, comment.field).toUpperCase()}</span>&nbsp;
                                                                    <span>{this.props.language.comments.in}</span>&nbsp;
                                                                    <span className={classes.fontBold} style={{ color: this.returnColor(comment.comment, comment.field) }}>
                                                                        {this.returnField(comment.comment, comment.field).toUpperCase()}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <Attachments
                                                                files={comment.files}
                                                                id={this.props.ticketId}
                                                                onDownload={this.props.downloadTicketFiles}
                                                            />
                                                        </div>
                                                    </div></>}
                                        </>
                                    )
                                })}
                            </div>
                            {this.props.ticket && this.props.ticket.status && !(this.props.ticket.status == CONSTANTS.TICKET_STATUSES.CLOSED) && <div className={`${classes.postComment} ${classes.paddingTop} ${classes.equalsFlex} ${classes.flexRow}`}>
                                <div>
                                    {loggedUserProfileImage ? <Avatar aria-label="recipe" src={loggedUserProfileImage} className={`${classes.icon} ${classes.extraSize}`} /> : <PersonIcon className={`${classes.icon} ${classes.extraSize}`} />}
                                </div>
                                <div className={`${classes.paddingLeft} ${classes.equalsFlex} ${classes.flexColumn}`}>
                                    <AddComment
                                        setFiles={files => this.setFiles(files)}
                                        showFilesUpload={true}
                                        setComment={newComment => this.setComment(newComment)}
                                        reset={this.state.reset} />
                                    <div className={`${classes.paddingTop} ${classes.leftAuto} ${classes.buttons}`}>
                                        {this.state.isSending ? <CircularProgress className={this.props.classes.progress} /> : <>
                                            <div>
                                                <Button onClick={() => this.onCreate()} color="primary" className={`${classes.leftAuto} ${classes.button}`} style={{ marginLeft: 0 }}>{this.props.language.buttons.comment}</Button>
                                            </div>
                                            {this.checkForRole() && <div className={classes.buttonElement}>
                                                {this.renderStatusButton()}
                                            </div>}

                                        </>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    createComment: (ticketId, comment) => dispatch(TICKET_REQUESTS.createComment(ticketId, comment)),
    uploadFiles: (ticketId, index, form) => dispatch(TICKET_REQUESTS.uploadFiles(ticketId, index, form)),
    changeStatus: (id, status, comment) => dispatch(TICKET_REQUESTS.update(id, { status, commentToAdd: comment })),
    generatePdf: id => dispatch(TICKET_REQUESTS.generatePDf(id)),
    generateXls: id => dispatch(TICKET_REQUESTS.generateXls(id)),
    downloadTicketFiles: (ticketId, fileId, filename) => dispatch(TICKET_REQUESTS.download(ticketId, fileId, filename))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketComments))