import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

let styles = theme => ({
  tooltipBackground: {
    opacity: '0.9 !important',
    backgroundColor: 'white',
    border: '1px solid #e8e8e8 !important'
  },
})

let HtmlTooltip = props => {
  let { classes } = props

  return (
    <div >
      <Tooltip title={<React.Fragment>{props.title}</React.Fragment>} classes={{ tooltip: classes.tooltipBackground }} >
        <div onClick={props.onClick} color={props.color || 'secondary'} aria-label={props.tooltip}>
          {props.icon}
        </div>
      </Tooltip>
    </div>

  )
}

export default withStyles(styles)(HtmlTooltip)