import queryString from 'query-string'

import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'
import { errorHandler } from '../../utils/handlers'
import FileSaver from 'file-saver'

const TICKET_ROUTE = '/tickets'

export const get = query => dispatch => axios.get(`${TICKET_ROUTE}?from=${query.from}&limit=${query.limit}&search=${query.search ? query.search : ''}&filter=${query.filter ? query.filter : ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const generateExport = (from, until, building, company) => dispatch => {
    let query = {}

    if (from) query.from = from
    if (until) query.until = until
    if (building) query.building = building
    if (company) query.company = company

    return axios.get(`${TICKET_ROUTE}/generate/xls/multiple?${queryString.stringify(query)}`, {
        method: 'GET',
        responseType: 'arraybuffer'
    })
        .then(response => {
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `tickets.xls`)
        })
        .catch(errorHandler)
}

export const getById = id => dispatch => axios.get(`${TICKET_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const update = (id, ticket) => dispatch => axios.put(`${TICKET_ROUTE}/${id}`, { ticket })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const del = id => dispatch => axios.delete(`${TICKET_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(TICKET_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)


export const generatePDf = id => dispatch => axios(`${TICKET_ROUTE}/${id}/generate/pdf`, {
    method: 'GET',
    responseType: 'blob'
})
    .then(response => {
        let blob = new Blob([response.data], { type: "application/pdf" })
        FileSaver.saveAs(blob, `Ticket-${id}.pdf`)
    })
    .catch(errorHandler)

export const generateXls = id => dispatch => axios(`${TICKET_ROUTE}/${id}/generate/xls`, {
    method: 'GET',
    responseType: 'arraybuffer'
})
    .then(response => {

        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, `Ticket-${id}.xls`)
    })
    .catch(errorHandler)

export const createComment = (id, comment) => dispatch => axios.patch(`${TICKET_ROUTE}/${id}/comments`, { comment })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadFiles = (ticketId, index, form) => dispatch => axios.post(`${TICKET_ROUTE}/${ticketId}/files/${index}`, form)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const setFavorite = (id, userId) => dispatch => axios.put(`${TICKET_ROUTE}/${id}`, { ticket: { setFavorite: userId } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const unsetFavorite = (id, userId) => dispatch => axios.put(`${TICKET_ROUTE}/${id}`, { ticket: { unsetFavorite: userId } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const setTicketType = (ticketType) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({ type: TYPES.SET_TICKET_TYPE, payload: { ticketType } })
        resolve()
    })
}
export const download = (ticketId, fileId, filename) => dispatch => axios(`${TICKET_ROUTE}/${ticketId}/files/${fileId}`, {
    method: 'GET',
    responseType: 'blob'
})
    .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')      
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
    })
    .catch(errorHandler)
export const getTicketsForCalendar = () => dispatch => axios.get(`${TICKET_ROUTE}/calendar`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)
export const schedule = (id, startTime, endTime, edited) => dispatch => axios.put(`${TICKET_ROUTE}`, { id, startTime, endTime, edited })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)
export const getNotifications = (today) => dispatch => axios.get(`${TICKET_ROUTE}/notifications/${today}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)