import React, { Component } from 'react'
import { connect } from 'react-redux'

import Sidebar from './Sidebar'

class RootSidebar extends Component {

    render() {

        return (
            <Sidebar
                listItems={this.props.items}
                superAdmin={this.props.superAdmin}
            />
        )
    }
}

const mapStateToProps = reducers => ({
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(RootSidebar)