import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'

import * as CONSTANTS from '../../../utils/constants'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    reports: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    box: {
        height: '100%',
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid rgba(0, 0, 0, 0.13);',
        '&:last-child': {
            borderRight: '1px solid transparent'
        }
    },
    boxContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        padding: '10px 0px 10px 30px',
        boxSizing: 'border-box'
    },
    spaceLeft: {
    },
    boldFont: {
        fontWeight: 'bold',
        fontSize: 25
    },
    title: {
        fontSize: 22,
        paddingBottom: 4,
        fontWeight: 500,
    },
    ticketIcon:{
        maxHeight:'20px',
        maxWidth: '20px',
        float: 'right',
        paddingRight: '12px',
        paddingTop: '4px'
    }
})

let Reports = props => {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <div className={classes.reports}>
                <div className={classes.box} >
                    <div className={`${classes.boxContent} ${classes.spaceLeft}`} onClick={props.onTotalClicked}>
                        <div>
                            <span className={classes.title}>
                            {props.totalTitle}
                            </span>
                            <img src="assets/ticketsicon.png" className={classes.ticketIcon}></img>
                        </div>
                        <span className={classes.boldFont} >
                            {props.total}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={`${classes.boxContent} ${classes.spaceLeft}`} onClick={props.onOpenedClicked}>
                        <span className={classes.title}>
                            {props.newTitle}
                        </span>
                        <span className={classes.boldFont} style={{ color: CONSTANTS.STATUS_COLORS.NEW }}>
                            {props.newlyOpened}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={`${classes.boxContent} ${classes.spaceLeft}`} onClick={props.onPendingClicked}>
                        <span className={classes.title}>
                            {props.pendingTitle}
                        </span>
                        <span className={classes.boldFont} style={{ color: CONSTANTS.STATUS_COLORS.PENDING }}>
                            {props.pending}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={`${classes.boxContent} ${classes.spaceLeft}`} onClick={props.onVerifyClicked}>
                        <span className={classes.title}>
                            {props.verifyTitle}
                        </span>
                        <span className={classes.boldFont} style={{ color: CONSTANTS.STATUS_COLORS.VERIFY }}>
                            {props.verify}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <div className={`${classes.boxContent} ${classes.spaceLeft}`} onClick={props.onClosedClicked}>
                        <span className={classes.title}>
                            {props.closedTitle}
                        </span>
                        <span className={classes.boldFont} style={{ color: CONSTANTS.STATUS_COLORS.CLOSED }}>
                            {props.closed}
                        </span>
                    </div>
                </div>
            </div>
        </Card >
    )
}

export default Reports