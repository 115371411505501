import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, withStyles, OutlinedInput } from '@material-ui/core'

let selector = props => {
    let { classes } = props
    let [value, setValue] = useState(null)

    useEffect(() => {
        if (props.value) setValue(props.value)
    }, [props.value])

    let onInputChange = event => {
        setValue(event.target.value)
        props.onChange(event)
    }

    return (
        <FormControl classes={{
            root: classes.formControlRoot
        }} variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.label} shrink htmlFor="outlined-simple">{props.label}</InputLabel>
            <Select
                disabled={props.disabled}
                native
                fullWidth={true}
                error={props.error}
                value={value || 0}
                onChange={onInputChange}
                label={props.label}
                input={
                    <OutlinedInput name={props.name} id='outlined-simple' />
                }
            >
                <option aria-label="None" value="" />
                {props.options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
            </Select>
        </FormControl>

    )
}

let styles = () => ({
    container: {
        width: '100%',
        height: '100%'
    },
    formControlRoot: {
        width: '100%'
    },
    label:{
        backgroundColor: 'white',
        paddingRight: '5px',
        paddingLeft: '2px'
    }
})

export default withStyles(styles)(selector)