import React, { Component } from 'react'
import { withStyles, Button, InputAdornment, Tooltip, CircularProgress } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { connect } from 'react-redux'

import InputGenerator from '../../common/InputGenerator'
import Files from './Files'
import Header from '../../common/Header'
import Form from '../../common/FormGenerator'
import Offers from './Offers'
import CreateCompanyModal from '../modals/CreateCompany'

import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as NOTIFICATION from '../../../utils/notification'

class CompanyBilling extends Component {

    initialCompanyFields = [
        { value: 0, type: 'number', label: this.props.language.labels.accountsNumber, name: 'accountsLimit', disabled: true },
        { value: 0, type: 'number', label: this.props.language.labels.buildingsNumber, name: 'buildingsLimit', disabled: true },
        { value: 0, type: 'number', label: this.props.language.labels.accountPrice, name: 'accountPrice' },
        { value: 0, type: 'number', label: this.props.language.labels.buildingPrice, name: 'buildingPrice' },
        { value: 0, type: 'number', label: this.props.language.labels.accountsTotal, name: 'accountsTotal', disabled: true },
        { value: 0, type: 'number', label: this.props.language.labels.buildingsTotal, name: 'buildingsTotal', disabled: true },
        { value: 0, type: 'number', label: this.props.language.labels.discount, name: 'discount' },
        { value: 0, type: 'date', label: this.props.language.labels.billingStartingDate, name: 'billingStartingDate' },
        { value: 0, type: 'number', label: this.props.language.labels.totalPrice, name: 'totalPrice', disabled: true },
        { type: 'dropdownSelector', name: 'contractPeriod', utils: this.props.language.labels.contractPeriod, value: '1', options: [] },
        { value: 0, type: 'number', label: this.props.language.labels.discountContractPeriod, name: 'contractPeriodDiscount' }
    ]

    state = {
        company: {},
        done: false,
        companyFields: this.initialCompanyFields.map(field => ({ ...field })),
        offerEmailModal: false,
        openEditModal: false,
        loadingOffer: false,
        sendingOffer: false,
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.ID !== nextProps.match.params.ID)
            this.getCompany()
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany = () => {
        this.props.getCompany(this.props.match.params.ID)
            .then(company => {
                this.setState({
                    company,
                    done: true
                })
                this.populateCompany(company)
            })
    }

    populateCompany = async (company) => {
        let newFields = [...this.state.companyFields].map(obj => ({ ...obj }))

        Object.keys(company).forEach(key => {
            const currentIndex = newFields.findIndex(index => index.name === key)

            if (currentIndex > -1) {
                newFields[currentIndex].value = company[key]
            }

        })

        let monthsIndex = newFields.findIndex(index => index.name === 'contractPeriod')

        const months = [...this.props.language.months.map((month, index) => ({
            name: index + 1,
            value: false,
            label: month
        }))]
        newFields[monthsIndex].options = months

        newFields = [...this.calculateFields(newFields)].map(obj => ({ ...obj }))

        this.setState({
            companyFields: newFields
        })
    }

    onChangeHandler = event => {
        let jsonData = {}
        this.state.companyFields.forEach(element => jsonData[element.name] = element.value)
        let companyFieldsCopy = this.state.companyFields.map(field => ({ ...field }))

        if (event.target.name === 'accountPrice') {
            jsonData['accountsTotal'] = event.target.value * jsonData['accountsLimit']
        }

        if (event.target.name === 'buildingPrice') {
            jsonData['buildingsTotal'] = event.target.value * jsonData['buildingsLimit']
        }

        jsonData['totalPrice'] = jsonData['accountsTotal'] + jsonData['buildingsTotal']

        if (event.target.name === 'discount') {
            jsonData['totalPrice'] = jsonData['totalPrice'] - (event.target.value / 100 * jsonData['totalPrice'])
        }

        jsonData[event.target.name] = event.target.type === 'number' ? Number(event.target.value) : event.target.value

        this.setState({
            companyFields: companyFieldsCopy.map(field => ({ ...field, value: jsonData[field.name] }))
        })
    }

    calculateFields = (companyFieldsCopy) => {
        let jsonData = {}
        companyFieldsCopy.forEach(element => jsonData[element.name] = element.value)

        jsonData['accountsTotal'] = jsonData['accountPrice'] * jsonData['accountsLimit']
        jsonData['buildingsTotal'] = jsonData['buildingPrice'] * jsonData['buildingsLimit']
        jsonData['totalPrice'] = (jsonData['accountsTotal'] + jsonData['buildingsTotal']) - (jsonData['discount'] / 100 * jsonData['totalPrice'])

        companyFieldsCopy.map(field => field.value = jsonData[field.name])
        return companyFieldsCopy
    }

    onAcceptHandler = event => {
        let jsonData = {}
        this.state.companyFields.forEach(element => jsonData[element.name] = element.value)
        let price = jsonData['totalPrice'] * jsonData['contractPeriod'] - jsonData['contractPeriodDiscount'] / 100 * jsonData['totalPrice'] * jsonData['contractPeriod']

        let offer = {
            sentTo: event.email,
            accountPrice: jsonData['accountPrice'],
            buildingPrice: jsonData['buildingPrice'],
            discount: jsonData['discount'],
            contractPeriodDiscount: jsonData['contractPeriodDiscount'],
            billingStartingDate: jsonData['billingStartingDate'],
            contractPeriod: jsonData['contractPeriod'],
            totalPrice: price
        }

        this.setState({
            sendingOffer: true
        })
        this.props.uploadOffer(this.props.match.params.ID, offer)
            .then(() => {
                this.getCompany()
                this.setState({
                    offerEmailModal: false,
                    sendingOffer: false
                })

                NOTIFICATION.success(this.props.language.toastr.company.offerSuccessfullyUploaded)
            })
            .catch(() => {
                this.setState({
                    sendOffer: false
                })
                NOTIFICATION.error(this.props.language.toastr.company.offerUnsuccessfullyUploaded)
            })
    }

    onCloseEditHandler = () => {
        this.getCompany()
        this.setState({
            openEditModal: false
        })
    }

    generatePDF = () => {
        let jsonData = {}
        this.state.companyFields.forEach(element => jsonData[element.name] = element.value)
        let price = jsonData['totalPrice'] * jsonData['contractPeriod'] - jsonData['contractPeriodDiscount'] / 100 * jsonData['totalPrice'] * jsonData['contractPeriod']

        this.setState({
            loadingOffer: true
        })

        let offer = {
            accountPrice: jsonData['accountPrice'],
            buildingPrice: jsonData['buildingPrice'],
            discount: jsonData['discount'],
            contractPeriodDiscount: jsonData['contractPeriodDiscount'],
            billingStartingDate: jsonData['billingStartingDate'],
            contractPeriod: jsonData['contractPeriod'],
            totalPrice: price
        }

        this.props.generatePDF(this.props.match.params.ID, this.state.company.name, offer)
            .then(() => {
                NOTIFICATION.success(this.props.language.toastr.company.offerSuccessfullyGenerated)
                this.setState({ loadingOffer: false })
            })
            .catch(() => {
                NOTIFICATION.error(this.props.language.toastr.company.offerUnsuccessfullyGenerated)
                this.setState({ loadingOffer: false })
            })
    }

    InputWrapper = props => <div>
        <InputGenerator
            key={props.key}
            disabled={props.disabled}
            fullWidth={true}
            InputLabelProps={props.shrink ? { shrink: true } : {}}
            margin="dense"
            label={props.label ? props.label : null}
            {...props.input}
            {...props}
            value={props.value}
            endAdornment={props.endAdornment}
            onChange={props.onChange}
        />
    </div>

    render() {

        if (this.state.done) {
            let { classes } = this.props
            let jsonMap = {}
            this.state.companyFields.forEach(field => {
                jsonMap[field.name] = field
            })

            return (
                <div className={classes.container}>
                    <div className={classes.header}>
                        <Header
                            title={this.state.company.name}
                            simple
                            profileText={this.props.language.headerTooltip.profile}
                        />
                    </div>
                    <div className={classes.bodyContainer}>
                        <div className={classes.contentContainer}>
                            <div className={classes.fieldsContainer}>
                                <div className={classes.importantTextContainer}>
                                    <span className={classes.importantText}>{this.props.language.titles.offer}</span>
                                    <span>
                                        <Tooltip placement={'left'} title={this.props.language.titles.editCompany}>
                                            <Button onClick={() => this.setState({ openEditModal: true })}><Edit /></Button>
                                        </Tooltip>
                                    </span>
                                </div>
                                <div className={classes.fieldsFirstRow}>
                                    <div className={`${classes.fieldsColumn} ${classes.inputField}`}>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['accountsLimit']} key={'accountsLimit'} {...jsonMap['accountsLimit']} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'></InputAdornment>} />
                                        </div>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['buildingsLimit']} key={'buildingsLimit'}{...jsonMap['buildingsLimit']} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'></InputAdornment>} />
                                        </div>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['billingStartingDate']} key={'billingStartingDate'} {...jsonMap['billingStartingDate']} onChange={this.onChangeHandler} />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsColumn} ${classes.inputField}`}>
                                        <div className={` ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['accountPrice']} key={'accountPrice'} {...jsonMap['accountPrice']} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                                        </div>
                                        <div className={` ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['buildingPrice']} key={'buildingPrice'} {...jsonMap['buildingPrice']} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                                        </div>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['discount']} key={'discount'} {...jsonMap['discount']} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'>{this.props.language.utils.percent}</InputAdornment>} />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsColumn} ${classes.inputField}`}>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['accountsTotal']} key={'accountsTotal'} {...jsonMap['accountsTotal']} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                                        </div>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['buildingsTotal']} key={'buildingsTotal'} {...jsonMap['buildingsTotal']} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                                        </div>
                                        <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['totalPrice']} key={'totalPrice'} {...jsonMap['totalPrice']} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.importantTextContainer}>
                                    <span className={classes.importantText}>{this.props.language.titles.contractPeriod}</span>
                                </div>
                                <div className={classes.fieldsSecondRow}>
                                    <div className={classes.contractFields}>
                                        <div className={` ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['contractPeriod']} key={'contractPeriod'} onChange={this.onChangeHandler} />
                                        </div>
                                        <div className={` ${classes.paddingTopButton}`}>
                                            <this.InputWrapper shrink={true} input={jsonMap['contractPeriodDiscount']} key={'contractPeriodDiscount'} onChange={this.onChangeHandler} endAdornment={<InputAdornment position='end'  >{this.props.language.utils.percent}</InputAdornment>} />
                                        </div>
                                    </div>
                                    <div className={classes.buttonsContainer}>
                                        <div className={` ${classes.paddingTopButton} ${classes.offerButtonsPadding}`}>
                                            <Button className={classes.fullWidth} color="secondary" variant="contained" onClick={this.generatePDF}>{this.state.loadingOffer ? <><CircularProgress className={classes.generatePDFSpinner} /> <span className={classes.spinnerPadding}>{this.props.language.toastr.company.offerIsGenerating}</span> </> : this.props.language.buttons.previewOffer}</Button>
                                        </div>
                                        <div className={` ${classes.paddingTopButton} `}>
                                            <Button className={classes.fullWidth} onClick={() => this.setState({
                                                offerEmailModal: true
                                            })} color="secondary" variant="contained">{this.props.language.buttons.sendOffer}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.filesContainer}>
                            <Offers
                                companyId={this.props.match.params.ID}
                                company={this.state.company}
                                getCompanyDetails={() => this.getCompany()}
                                offers={this.state.company.offers && this.state.company.offers.length ? this.state.company.offers : []}
                            />
                        </div>
                        <div className={classes.filesContainer}>
                            <Files
                                companyId={this.props.match.params.ID}
                                getCompanyDetails={() => this.getCompany()}
                                files={this.state.company.invoices && this.state.company.invoices.length ? this.state.company.invoices : []}
                            />
                        </div>
                    </div>
                    <CreateCompanyModal
                        open={this.state.openEditModal}
                        onClose={() => this.onCloseEditHandler()}
                        superAdmin={this.props.superAdmin ? true : false}
                        edit={true}
                        companyId={this.props.match.params.ID}
                    />
                    <Form
                        open={this.state.offerEmailModal}
                        title={this.props.language.titles.specifyEmail}
                        acceptButtonText={this.state.sendingOffer ? <><CircularProgress className={`${classes.generatePDFSpinner} ${classes.spinnerColorWhite}`} /> <span className={classes.spinnerPadding}>{this.props.language.toastr.company.offerIsSending}</span> </> : this.props.language.buttons.sendOffer}
                        cancelButtonText={this.props.language.buttons.cancel}
                        withCancelButton
                        onAccept={event => this.onAcceptHandler(event)}
                        fields={[
                            { value: '', type: 'text', label: this.props.language.labels.email, name: 'email' }
                        ]}
                        maxWidth='xs'
                        onCancel={() => this.setState({ offerEmailModal: false })}
                    />
                </div>
            )
        }
        else return null
    }
}

let styles = () => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px',
    },
    bodyContainer: {
        padding: '0px 25px 0px 30px',
        height: 'calc(100% - 70px)',
        overflow: 'auto'
    },
    contentContainer: {
        backgroundColor: 'white',
        justifySelf: "center",
        padding: '25px',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        boxSizing: ' border-box',
        margin: '30px 0 30px 0'
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    fieldsFirstRow: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '25px',
        marginBottom: '20px'
    },
    fieldsColumn: {
        paddingRight: '45px',
        '&:last-child': {
            paddingRight: 0
        }
    },
    inputField: {
        maxWidth: '380px',
        width: '100%'
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    filesContainer: {
        backgroundColor: 'white',
    },
    fieldsSecondRow: {
        display: 'flex',
        flexDirection: 'column'
    },
    contractFields: {
        paddingRight: '45px',
        '&:last-child': {
            paddingRight: 0
        },
        maxWidth: '380px'
    },
    importantTextContainer: {
        paddingBottom: '25px',
        display: 'flex'
    },
    importantText: {
        fontSize: 20,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: '1'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '400px',
        justifyContent: 'space-between',
        padding: '8px 0',
        maxWidth: '380px'
    },
    fullWidth: {
        width: '100%'
    },
    offerButtonsPadding: {
        paddingRight: "25px"
    },
    generatePDFSpinner: {
        width: '15px !important',
        height: '15px !important'
    },
    spinnerColorWhite: {
        color: 'white !important'
    },
    spinnerPadding: {
        paddingLeft: '10px'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getCompany: id => dispatch(COMPANY_REQUESTS.getById(id)),
    uploadOffer: (id, offer) => dispatch(COMPANY_REQUESTS.uploadOffer(id, offer)),
    generatePDF: (id, companyName, offer) => dispatch(COMPANY_REQUESTS.generatePDf(id, companyName, offer))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyBilling))