import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import * as NOTIFICATIONS from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import * as USERS_REQUEST from '../../redux/actions/users'

import Card from './cards/Card'
import CreateUserModal from './create/Create'
import Header from '../common/Header'

import Pagination from '../common/Pagination'

let generateRows = (props, users, onDeleteHandler, onEditHandler, classes, message) => users.map(user => {

    return (
        <div className={classes.spacing}>
            <Card
                language={props.language}
                onSelect={() => onEditHandler(user)}
                item={user}
                hideDelete={props.profile.role === 'admin' && props.profile._id === user._id}
                subtitle={user ? user.title : '-'}
                onDelete={() => {
                    let userAllowedDeletion = window.confirm(message + user.firstName + ' ' + user.lastName)

                    if (userAllowedDeletion) {
                        onDeleteHandler(user._id)
                    }
                }}
            />
        </div>
    )
})

const Users = props => {
    let { classes } = props
    let [users, setUsers] = useState([])
    let [selectedUser, setSelectedUser] = useState(null)
    let [count, setCount] = useState(0)
    let [search, setSearch] = useState('')
    let [showCreateUser, setShowCreateUser] = useState(false)
    let [changedRole, setChangedRole] = useState(props.match.params.FILTER)

    let [from, setFrom] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE)

    const filterMapping = filter => {
        if(props.superAdmin) return 'superAdmin'
        if (filter === 'pms') return 'pm'
        if (filter === 'fms') return 'fm'
        if (filter === 'users') return 'user'
        return filter
    }

    const onDeleteHandler = id => props.removeUser(id)
        .then(() => getUsers()
            .then(() => {
                setShowCreateUser(false)
                NOTIFICATIONS.success(props.language.toastr.user.successDelete)
            }))

    const onEditHandler = user => {
        setSelectedUser(user)
        setShowCreateUser(true)
    }

    const onSearchHandler = value => {
        setFrom(0)
        setSearch(value)
    }

    const changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
    }

    const changePageHandler = currentFrom => {
        setFrom(currentFrom)
    }

    const checkRoleChange = role => {
        if(changedRole !== role) {
            setFrom(0)
            setChangedRole(role)
        }
    }

    useEffect(() => {
        getUsers()
    }, [rowsPerPage, from, search, props.match.params.FILTER])

    let filter = filterMapping(props.match.params.FILTER)
    checkRoleChange(props.match.params.FILTER)

    const getUsers = () => {
        return props.getUsers({ role: filter || '', search, from: from, limit: rowsPerPage })
        .then(data => {
            setCount(data.count)
            setUsers(data.users)
            return true
        })
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <Header
                        placeholderText={props.language.utils.searchUserText}
                        title={props.language.customers}
                        buttonText={props.language.create}
                        onSearch={filter => onSearchHandler(filter)}
                        onClick={() => setShowCreateUser(true)}
                        addText={props.language.headerTooltip.user}
                        profileText={props.language.headerTooltip.profile}
                    />
                </div>
                <div className={classes.container}>

                    {count > 0 ? generateRows(props, users, onDeleteHandler, onEditHandler, classes, props.language ? props.language.deleteUserMessage : '') : <div className={classes.noResultsText} > <span>{props.language.utils.noResults}</span></div>}

                    <div className={`${classes.container} ${classes.pagination}`}>
                        <Pagination
                            from={from}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPageHandler}
                            changePageHandler={changePageHandler}
                        ></Pagination>
                    </div>
                </div >


            </div>
            {showCreateUser && <CreateUserModal
                open={showCreateUser}
                user={selectedUser}
                superAdmin={props.superAdmin}
                onCancel={() => {
                    setShowCreateUser(false)
                    setSelectedUser(null)
                }}
                onAccept={() => {
                    getUsers()
                    setShowCreateUser(false)
                    setSelectedUser(null)
                }}
            />}
        </>
    )
}
const styles = theme => ({
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    wrapper: {
        width: 'calc(100% - 216px)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9FBFF'
    },
    noResultsText: {
        paddingLeft: 30,
        paddingTop: 20
    },
    container: {
        width: '100%',
        padding: '18px 18px 0px 18px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
    },
    line: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    spacing: {
        margin: 10,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    cardContainer: {
        padding: '18px'
    },
    disableBorder: {
        borderBottom: 'none'
    },
    surfaceTitle: {
        paddingTop: 20
    },
    title: {
        paddingBottom: 5,
        margin: 0
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    pagination: {
        justifyContent: 'flex-end'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    profile: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getUsers: options => dispatch(USERS_REQUEST.get(options)),
    removeUser: id => dispatch(USERS_REQUEST.remove(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Users))