import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const BUILDINGS_ROUTE = '/buildings'

export const get = query => dispatch => axios.get(`${BUILDINGS_ROUTE}?all=${query.all ? query.all : ''}&search=${query.search ? query.search : ''}&from=${query.from || ''}&limit=${query.limit || ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${BUILDINGS_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getBuildingServices = id => dispatch => axios.get(`${BUILDINGS_ROUTE}/${id}/services`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(BUILDINGS_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${BUILDINGS_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const edit = (id, data) => dispatch => axios.put(`${BUILDINGS_ROUTE}/${id}`, { building: { ...data } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadImage = (id, images) => dispatch => axios.post(`${BUILDINGS_ROUTE}/${id}/image`, images)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)