import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Tooltip } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../../utils/constants'
import AddBuilding from './AddBuilding/AddBuilding'
import * as NOTIFICATIONS from '../../../../utils/notification'
import * as COMPANIES_REQUESTS from '../../../../redux/actions/companies'
import * as USERS_REQUESTS from '../../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import Form from '../../../common/FormGenerator'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 130px)'
    },
    list: {
        maxHeight: '90%',
        overflow: 'auto'
    },
    addListItem: {
        backgroundColor: '#4caf50',
        cursor: 'pointer',
        color: '#fafafa'
    },
    editListItem: {
        backgroundColor: '#757575',
        cursor: 'pointer',
        color: '#fafafa'
    },
    selectedBuilding: {
        border: '3px solid #4D8FDC !important',
        borderRadius: 5,
        backgroundColor: 'rgba(0,0,0,0.1) !important',
        color: '#4D8FDC !important'
    },
    buildingsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    building: {
        width: 60,
        height: 50,
        cursor: 'pointer',
        boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.25)',
        backgroundColor: '#ffffff',
        margin: '10px 0px',
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: '#9B9B9B',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#F2F2F2'
        },
        '&:first-child': {
            marginTop: 20
        },
        '&:last-child': {
            marginBottom: 20
        }
    },
    buildingInitials: {
        width: '100%',
        whiteSpace: 'no-wrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 'bold',
        fontSize: 18
    },
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    tooltipImage: {
        width: 70,
        height: 70,
        borderRadius: '50%'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    tooltipTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        color: '#9B9B9B',
        paddingBottom: 6
    },
    tooltipSecondaryTitle: {
        fontWeight: 400,
        fontSize: 14,
        color: '#000000'
    },
    tooltipDataContainer: {
        justifyContent: 'center',
        padding: '8px 12px',
        boxSizing: 'border-box',
        width: 'calc(100% - 70px)'
    },
    tooltipStyle: {
        backgroundColor: 'white',
        width: '500px !important',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: 2
    },
    actionsIcon: {
        color: '#4D8FDC'
    },
    actionsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    subtitleText: {
        fontSize: 13,
        fontWeight: 400,
        paddingLeft: 5,
        paddingRight: 5,
        marginBottom: 0,
        marginTop: '-8px',
        paddingBottom: 0,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '12px 0px',
        opacity: 0.6
    },
    hr: {
        border: 0,
        height: 2,
        background: "#095484",
        flex: 1,
    },
    hrMargin: {
        marginLeft: 3
    }
})


let BuildingsList = props => {
    let { classes } = props
    let [showAddBuildingModal, setShowAddBuildingModal] = React.useState(false)
    let [buildings, setBuildings] = React.useState([])
    let [showEditModal, setShowEditModal] = React.useState(false)
    let [editFields, setEditFields] = React.useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
        { value: '', type: 'text', label: props.language.labels.cui, name: 'cui' },
        { value: '', type: 'text', label: props.language.labels.mainBusiness, name: 'mainBusiness' },
        { type: 'dropdownSelector', disabled: true, name: 'type', utils: props.language.labels.type.type, value: '', options: [] },
        { type: 'dropdownSelector', name: 'official', utils: props.language.labels.official, value: '', options: [] },
        { type: 'fixedMultiSelect', name: 'services', utils: props.language.labels.service, value: [], options: [] }
    ])

    let editCompany = newCompany => props.editCompany(props.companyId, newCompany)
        .then(() => getCompanyData())
        .then(() => {
            setShowEditModal(false)
            NOTIFICATIONS.success(props.language.toastr.company.successEdit)
        })
        .catch(err => {
            setShowEditModal(false)
            NOTIFICATIONS.error(props.language.toastr.company.errorEdit)
        })

    let deleteCompany = () => {
        if (window.confirm(props.language.toastr.company.wishToDeleteCompany)) {
            props.deleteCompany(props.companyId)
                .then(() => NOTIFICATIONS.success(props.language.toastr.company.successDelete))
                .then(() => props.history.push('/companies'))
                .catch(() => NOTIFICATIONS.error(props.language.toastr.company.errorDelete))
        }
        else
            NOTIFICATIONS.success(props.language.toastr.company.errorDelete)
    }

    React.useEffect(() => {
        getCompanyData()
        Promise.all([
            props.getCompany(props.companyId),
            // Populate types
            props.getTags()
                .then(result => {
                    const types = [{ name: '', value: false, label: '' }].concat([...result.map(type => ({
                        name: type.name,
                        value: false,
                        label: type.name
                    }))])

                    let newFields = [...editFields]
                    let indexOfType = newFields.findIndex(field => field.name == 'type')

                    if (indexOfType < 0) return

                    newFields[indexOfType].options = types

                    setEditFields(newFields)

                    return true
                }),
            // Populate possible officials
            props.getUsers()
                .then(({ users }) => {
                    let companyUsers = users.filter(user => user.company && user.company._id === props.companyId)

                    const userOptions = [{ name: '', value: false, label: '' }].concat([...companyUsers].map(user => ({
                        name: user._id,
                        value: false,
                        label: `${user.firstName} ${user.lastName}`
                    })))

                    let newFields = [...editFields]
                    let indexOfOfficial = newFields.findIndex(field => field.name == 'official')

                    if (indexOfOfficial < 0) return

                    newFields[indexOfOfficial].options = userOptions

                    setEditFields(newFields)

                    return true
                }),
            props.getServices()
                .then(result => {
                    let newFields = [...editFields]
                    let indexOfTags = newFields.findIndex(field => field.name == 'services')

                    if (indexOfTags < 0) return false

                    newFields[indexOfTags].options = result.map(tag => ({ name: tag.name, label: tag.name, value: false }))

                    setEditFields(newFields)
                }),
        ]).then(result => {
            let company = result[0]
            // Options are populated, now fields can be populated
            let newFields = [...editFields]
            let indexOfTags = newFields.findIndex(field => field.name == 'services')
            if (company.type && company.type.toLowerCase() !== 'contractor') {
                newFields = newFields.filter(field => field.name !== 'services')
            }


            newFields.forEach(field => {
                if (field.name === 'official') {
                    field.value = company[field.name] ? company[field.name]._id : ''
                } else if (field.name === 'services') {
                    let mappedServices = company.services.map(service => ({ name: service, label: service, value: true }))
                    field.defaultValue = mappedServices
                    field.value = mappedServices
                } else field.value = company[field.name]
            })

            if (company.type === CONSTANTS.CONTRACTOR) {
                let defaultName = newFields[indexOfTags].defaultValue.map(value => value.name)
                let newFieldsOptions = newFields[indexOfTags].options
                let newFieldsOptionsCopy = []
                if (newFields[indexOfTags].defaultValue && newFields[indexOfTags].defaultValue.length) {
                    defaultName.forEach(name => {
                        newFieldsOptionsCopy = newFieldsOptions.filter(option => option.name !== name)
                        newFieldsOptions = newFieldsOptionsCopy
                    })
                    newFields[indexOfTags].options = newFieldsOptionsCopy
                }
            }

            setEditFields(newFields)
        })

    }, [props.companyId, showEditModal])

    let getCompanyData = () => props.getCompany(props.companyId)
        .then(result => {
            setBuildings(result.buildings.map(companyInBuilding => ({
                ...companyInBuilding,
                name: companyInBuilding.building.name,
                subText: `${props.language.labels.contactPhone} ${companyInBuilding.official ? companyInBuilding.official.phoneNumber : '-'}, ${props.language.labels.email} ${companyInBuilding.official ? companyInBuilding.official.email : '-'}`
            })))
            if (result.buildings.length) props.buildingChanged(result.buildings[0]._id)
            else return props.buildingChanged(null)
        })

    let onCancelHandler = () => {
        setShowAddBuildingModal(false)
    }

    let onAddBuildingHandler = data => {
        return props.addBuilding(props.companyId, data)
            .then(() => getCompanyData())
            .then(() => {
                setShowAddBuildingModal(false)
                return NOTIFICATIONS.success(props.language.toastr.building.succesfullyAdded)
            })
            .catch(() => {
                setShowAddBuildingModal(false)
                return NOTIFICATIONS.error(props.language.toastr.building.unsuccesfullyAdded)
            })
    }

    return (
        <>
            <Form
                open={showEditModal}
                title={`${props.language.buttons.edit} ${props.company ? props.company.name : ''}`}
                acceptButtonText={props.language.buttons.edit}
                cancelButtonText={props.language.buttons.cancel}
                deleteButtonText={props.language.buttons.delete}
                withCancelButton={false}
                onAccept={editCompany}
                onDelete={deleteCompany}
                fields={editFields}
                maxWidth='xs'
                onCancel={() => setShowEditModal(false)}
            />
            <div className={classes.list}>
                <div className={classes.actionsContainer}>
                    <Tooltip placement={"top-right"} title={props.language.utils.newBuilding}>
                        <div onClick={() => setShowAddBuildingModal(true)} className={classes.building}>
                            <AddIcon className={classes.actionsIcon} />
                        </div>
                    </Tooltip>
                    <Tooltip placement={"top-right"} title={props.language.utils.editFirst}>
                        <div onClick={() => setShowEditModal(true)} className={classes.building}>
                            <EditIcon className={classes.actionsIcon} />
                        </div>
                    </Tooltip>
                    <Tooltip placement={"top-right"} title={props.language.utils.deleteFirst}>
                        <div onClick={() => deleteCompany()} className={classes.building}>
                            <DeleteIcon className={classes.actionsIcon} />
                        </div>
                    </Tooltip>
                </div>
                <div className={classes.buildingsContainer}>
                    <div className={classes.actions}>
                        <div className={`${classes.hr} ${classes.hrMargin}`}></div>
                        <h5 className={classes.subtitleText}>{props.language.utils.buildingList}</h5>
                        <div className={classes.hr}></div>
                    </div>
                    {buildings.map((building, index) => {
                        let buildingInitial = building.building.name.split(' ').map(word => word.charAt(0).toUpperCase())
                        let buildingImage = building.building.image ? `${CONSTANTS.BACKEND_URL}/static/${building.building.image.path}` : 'https://via.placeholder.com/150'
                        return (
                            <Tooltip classes={{
                                tooltip: classes.tooltipStyle
                            }} placement={"top-right"} title={<div className={classes.tooltipContainer}>
                                <img alt='' src={buildingImage} className={classes.tooltipImage} />
                                <div className={`${classes.flexColumn} ${classes.tooltipDataContainer}`}>
                                    <span className={classes.tooltipTitle}>{building.name}</span>
                                    <span className={classes.tooltipSecondaryTitle}>{building.building.contact.email}</span>
                                    <span className={classes.tooltipSecondaryTitle}>{building.building.contact.phoneNumber}</span>
                                </div>
                            </div>}>
                                <div
                                    className={`${classes.building} ${props.currentBuilding === building._id ? classes.selectedBuilding : ''}`}
                                    onClick={() => props.buildingChanged(building._id)}>
                                    <span className={classes.buildingInitials}>{buildingInitial}</span>
                                </div>
                            </Tooltip>
                        )
                    })}
                </div>
            </div>
            <AddBuilding
                open={showAddBuildingModal}
                onSubmit={data => onAddBuildingHandler(data)}
                onCancel={onCancelHandler}
            />
        </>
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    addBuilding: (id, building) => dispatch(COMPANIES_REQUESTS.addBuilding(id, building)),
    getCompanies: () => dispatch(COMPANIES_REQUESTS.get({ all: true })),
    getCompany: id => dispatch(COMPANIES_REQUESTS.getById(id)),
    editCompany: (id, newCompany) => dispatch(COMPANIES_REQUESTS.edit(id, newCompany)),
    deleteCompany: id => dispatch(COMPANIES_REQUESTS.remove(id)),
    getServices: () => dispatch(TAG_REQUESTS.getAll({ type: 'services' })),
    getTags: () => dispatch(TAG_REQUESTS.getAll({ type: 'company' })),
    getUsers: () => dispatch(USERS_REQUESTS.get({ all: true })),
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BuildingsList))) 