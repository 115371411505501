import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const REPORTS_ROUTE = '/reports'

export const get = buildings => dispatch => {
    let queryParams = ''

    if(buildings && buildings.length) { 
        queryParams += '?buildings=' + JSON.stringify(buildings)
    }

    return axios.get(REPORTS_ROUTE + queryParams)
        .then(response => ({ ...response.data }))
        .catch(errorHandler)
}

export const getBuildings = () => dispatch => axios.get(REPORTS_ROUTE + '/buildings')
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getTicketCount = () => dispatch => axios.get(REPORTS_ROUTE + '/count')
    .then(response => ({ ...response.data }))
    .catch(errorHandler)