import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip } from '@material-ui/core'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'

import * as TEAM_REQUESTS from '../../../redux/actions/teams'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputField: {
        flex: 1
    },
    paddingTopButton: {
        padding: '8px 0px'
    }
})

class CreateTeamModal extends Component {

    initialTeamFields = [
        { value: '', type: 'text', label: this.props.language.labels.name, name: 'name' },
        { value: [], type: 'fixedMultiSelect', utils: this.props.language.labels.teamMembers, options: [], name: 'members' },
        { value: '', type: 'dropdownSelector', utils: this.props.language.labels.teamLeader, options: [], name: 'teamLeader' }
    ]

    state = {
        teamFields: this.initialTeamFields
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open)
            this.populateFields()
    }

    getUsers = (options) => this.props.getUsers(options ? { ...options } : { all: true })
        .then(data => data.users)

    InputWrapper = ({ input, key, shrink, onChange }) => {
        return <div>
            <InputGenerator
                key={key}
                fullWidth={true}
                InputLabelProps={shrink ? { shrink: true } : {}}
                margin="dense"
                {...input}
                onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
            />
        </div>
    }

    modifyFields = (fields, toEdit, newValues) => {
        const teamCopy = this.state.teamFields.map(field => ({ ...field }))
        fields.forEach((field, position) => {
            const fieldIndex = teamCopy.findIndex(index => index.name === field)
            if (fieldIndex > -1) {
                teamCopy[fieldIndex][toEdit[position]] = newValues[position]
            }
        })
        this.setState({ teamFields: teamCopy })
    }

    onCloseHandler = () => {
        this.props.onClose()
    }

    onAcceptHandler = () => {
        let jsonMap = {}
        this.state.teamFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const newTeam = {
            name: jsonMap['name'].value,
            members: jsonMap['members'].value.map(member => member.name),
            teamLeader: jsonMap['teamLeader'].value
        }

        this.props.create({ team: newTeam })
            .then(() => {
                this.props.onClose()
                this.setState({ teamFields: this.initialTeamFields })
                NOTIFICATIONS.success(this.props.language.toastr.team.successCreate)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.team.errorCreate)
            })
    }

    onChangeHandler = event => {
        const currentIndex = this.state.teamFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            const teamFieldsCopy = this.state.teamFields.map(field => ({ ...field }))
            teamFieldsCopy[currentIndex].value = event.target.value
            this.setState({ teamFields: teamFieldsCopy })
        }
    }


    populateFields = async () => {
        let allUsers = await this.getUsers()
        let possibleLeaders = await this.getUsers({ all: true })


        let users = allUsers.map(user => ({
            name: user._id,
            value: false,
            label: `${user.firstName} ${user.lastName}`
        }))

        let possibleTeamLeaders = [{ name: '', value: false, label: '' }].concat(possibleLeaders.map(leader => ({
            name: leader._id,
            value: false,
            label: `${leader.firstName} ${leader.lastName}`
        })))

        this.modifyFields(['members', 'teamLeader'], ['options', 'options'], [users, possibleTeamLeaders])
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let { classes } = this.props
        let jsonMap = {}
        this.state.teamFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['name']} key={'name'} />
                </div>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['members']} key={'members'} />
                </div>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['teamLeader']} key={'teamLeader'} />
                </div>

            </div>
        )
    }

    render() {
        return (
            <>
                <SimpleModal
                    open={this.props.open}
                    acceptButtonText={this.props.language.buttons.create}
                    cancelButtonText={this.props.language.buttons.cancel}
                    onCancel={() => this.onCloseHandler()}
                    onAccept={() => this.onAcceptHandler()}
                    canSubmitForm={true}
                    maxWidth={'sm'}
                    title={this.props.language.titles.createTeam}
                >
                    {this.renderFields()}
                </SimpleModal>
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    create: team => dispatch(TEAM_REQUESTS.create(team)),
    getUsers: options => dispatch(USER_REQUESTS.get(options))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateTeamModal))