import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Companies from './companies/Companies'
import Editable from './editable/Editable'
import Header from '../../common/Header'
import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'

const Details = props => {
    let { classes } = props

    let [building, setBuilding] = useState(null)

    useEffect(() => {
        props.getBuilding(props.match.params.ID)
            .then(building => setBuilding(building))
    }, [])

    return (
        <div className={classes.container}>
        <div className={classes.headerContainer}>
             <div className={classes.header}>
                <Header
                    simple
                    title={props.language.buildings}
                    profileText={props.language.headerTooltip.profile}
                />
             </div>
            </div>
            <div className={classes.contentContainer}>
                <div className={classes.editableContainer}>
                    <Editable
                        building={building ? building : null}
                    />
                </div>
                <div className={classes.usersContainer}>
                    <Companies
                        buildingId={building ? building._id : props.match.params.ID}
                    />
                </div>
            </div>
        </div>
    )
}
const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    contentContainer: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#F9FBFF'
    },
    headerContainer: {
        display : 'flex',
        backgroundColor: '#F9FBFF',
        flexDirection : 'column'
    },
    editableContainer: {
        height: '100%',
        flex: 1,
        marginLeft: 10
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px' 
    },
    usersContainer: {
        height: 'calc(100% - 40px)',
        flex: 2,
        margin: '20px 20px 20px 20px'

    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getBuilding: id => dispatch(BUILDING_REQUESTS.getById(id))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Details)))