import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, Button, MenuItem, FormControlLabel, Select, withStyles, Avatar, Tooltip } from '@material-ui/core'

import {
    PersonPin as PersonIcon
} from '@material-ui/icons'


const styles = theme => ({
    details: {
        cursor: 'pointer'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147',
        padding: '0px 0px 2px 6px',
        width: '220px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'

    },
    smallDetailsText: {
        fontSize: 10,
        fontWeight: 300,
        color: '#3d4147',
        padding: '1px 0px 1px 6px'
    },
    detailsIcon: {
        fontSize: 48,
        color: '#3d4147',
        marginLeft: '-8px'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    titleText: {
        color: '#00458B',
        fontWeight: 500,
        fontSize: 14,
        paddingLeft: 6
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    detailsContainer: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '225px'
    }
})

function CheckboxMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [items, setItems] = useState(props.items)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onChange = name => {
        // Name is user id
        props.onChange(name)
        handleClose()
    }
    return <>
        <div aria-controls="checkbox-menu" aria-haspopup="true" onClick={handleClick} color={props.color ? props.color : 'default'}>
            {props.initialText}
        </div>
        <Menu
            style={{ maxHeight: 400, maxWidth: 350 }}
            id="checkbox-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {items.map((item, index) =>
                <MenuItem onClick={() => onChange(item.name)} key={`${index}-${item.name}`}>
                    <div className={props.classes.details}>
                        <div className={props.classes.detailsAssigned}>
                            {item.profileImage ? <Avatar aria-label="recipe" src={item.profileImage} /> : <PersonIcon className={props.classes.detailsIcon} />}
                            <div className={props.classes.flexColumn}>
                                <Tooltip title={item.company} placement={"top"}>
                                    <span className={`${props.classes.detailsText} ${props.classes.textEllipsis}`}>{item.company}</span>
                                </Tooltip>
                                <div className={`${props.classes.flexColumn} ${props.classes.detailsContainer}`}>
                                    <Tooltip placement={'top-end'} title={item.label}>
                                        <span className={`${props.classes.detailsText} ${props.classes.textEllipsis} ${props.classes.titleText}`} >{item.label}</span>
                                    </Tooltip>
                                    <Tooltip placement={'top-end'} title={item.fullOfficialDetails}>
                                        <span className={`${props.classes.detailsText} ${props.classes.textEllipsis}`}>{item.officialDetails}</span>
                                    </Tooltip>

                                </div>
                            </div>
                        </div>
                    </div>
                </MenuItem>
            )}
        </Menu>
    </>
}

CheckboxMenu.propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    initialText: PropTypes.object.isRequired
}

export default withStyles(styles)(CheckboxMenu)