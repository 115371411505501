import React, { useState, useEffect } from 'react'
import { withStyles, Paper, Button, Typography } from '@material-ui/core'
import { Announcement as AnnouncementIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import InputGenerator from '../../common/InputGenerator'

import Header from '../../common/Header'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as NOTIFICATION from '../../../utils/notification'


let MailSettings = props => {
    let { classes, language } = props
    let [settings, setSettings] = useState({})


    let onChangeHandler = event => {
        if (typeof event.persist === 'function')
            event.persist()
        setSettings(prevState => ({ ...prevState, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }))
    }

    useEffect(() => {
        props.getMailSettings()
            .then(data => {
                setSettings({ ...data })
            })
    }, [])

    let sendSettings = () => {
        props.setMailSettings(settings)
            .then(() => NOTIFICATION.success(language.toastr.email.success))
            .catch(() => NOTIFICATION.error(language.toastr.email.error))
    }


    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header
                    title={props.language.header.mail}
                    simple
                    profileText={props.language.headerTooltip.profile}
                />
            </div>
            <div className={classes.contentContainer}>
                <Paper className={classes.announcement}>
                    <AnnouncementIcon className={classes.announcementIcon} />
                    <div>
                        <Typography>
                            {props.language.info.mail.useOwnSmtp}<br />
                            {props.language.info.mail.password}
                        </Typography>
                    </div>
                </Paper>
                <Paper className={classes.form}>
                    <div className={`${classes.checkbox} ${classes.useSMTP}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.ownSmtp}
                            className={classes.checkbox}
                            name='ownSmtp'
                            onChange={onChangeHandler}
                            value={settings.ownSmtp || false}
                        />
                    </div>
                    <InputGenerator
                        label={props.language.labels.host}
                        type='text'
                        margin="dense"
                        name='host'
                        onChange={onChangeHandler}
                        value={settings.host || ''}
                    />
                    <InputGenerator
                        label={props.language.labels.port}
                        type='number'
                        margin="dense"
                        name='port'
                        onChange={onChangeHandler}
                        value={settings.port || 0}
                    />
                    <div className={classes.checkbox}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.secure}
                            name='secure'
                            onChange={onChangeHandler}
                            value={settings.secure || false}
                        />
                    </div>
                    <InputGenerator
                        label={props.language.labels.user}
                        type='text'
                        margin="dense"
                        name='user'
                        onChange={onChangeHandler}
                        value={settings.user || ''}
                    />
                    <InputGenerator
                        label={props.language.labels.password}
                        type='password'
                        margin="dense"
                        name='password'
                        onChange={onChangeHandler}
                        value={settings.password || ''}
                    />
                    <Button onClick={sendSettings} className={props.classes.button} variant='contained' color='primary'>{props.language.buttons.mail}</Button>
                </Paper>
            </div>
        </div>
    )
}

let styles = () => ({
    container: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#f7f8fa',
        padding: '0px 25px 0px 30px',
        boxSizing: 'border-box'
    },
    header: {
        height: 70
    },
    form: {
        padding: 20
    },
    announcement: {
        padding: 20,
        display: 'flex',
        '& div': {
            marginLeft: 20
        },
        marginBottom: 30,
        marginTop: 30
    },
    checkbox: {
        position: 'relative',
        left: '-10px'
    },
    useSMTP: {
        paddingBottom: '10px',
        '& span': {
            fontWeight: '500 !important',
            fontSize: '13px'
        }
    },
    button: {
        marginTop: '20px'
    },
    announcementIcon: {
        fontSize: 30,
        color: 'rgba(0, 0, 0, 0.87)',
        marginTop: 2
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    setMailSettings: settings => dispatch(COMPANY_REQUESTS.setMailSettings(settings)),
    getMailSettings: () => dispatch(COMPANY_REQUESTS.getMailSettings())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MailSettings))