import React, { Component } from 'react'
import { JssProvider } from 'react-jss'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import {
    Assignment as AssignmentIcon,
    People as PeopleIcon,
    Domain as DepartmentsIcon,
    Assessment as AssessmentIcon,
    ContactSupport as SupportIcon,
    Wc as TeamsIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    Timer as ActiveIcon,
    InsertDriveFile as NewIcon,
    VerifiedUser as VerifyIcon,
    AccountBox as CustomerIcon,
    AccountCircle as AgentIcon,
    HowToReg as AdminIcon,
    Close as ClosedIcon,
    BusinessCenter as CompaniesIcon,
    DomainOutlined as BuildingIcon,
    LocalOffer as TagIcon,
    Mail as MailIcon,
    GroupWork as UserTeamsIcon,
    Info as InfoFormIcon,
    Notifications as NotificationsIcon,
    Receipt as InvoiceIcon
} from '@material-ui/icons'

import RootSidebar from './sidebar/RootSidebar'
import Navbar from './navbar/Navbar'
import Dashboard from './dashboard/Charts'
import Tickets from './tickets/Tickets'
import Users from './users/Users'
import TicketDetails from './tickets/details/Details'
import UserDetails from './users/details/Details'
import Login from './Login/Login'
import Reset from './resetPass/Reset'
import Companies from './companies/Companies'
import CompanyDetails from './companies/details/Details'
import CompanyBilling from './companies/billingInfo/BillingInfo'
import Buildings from './buildings/Buildings'
import BuildingDetails from './buildings/details/Details'
import Tags from './settings/tags/Tags'
import Teams from './teams/Teams'
import TeamDetails from './teams/details/Details'
import Support from './support/Support'
import Mail from './settings/email/Email'
import InfoForms from './infoForms/InfoForms'
import Notifications from './settings/notifications/Notifications'
import SimpleModal from './common/SimpleModal'
import Invoices from './invoices/Invoices'

import { checkForRole } from '../utils/helpers'

import * as LOGIN from '../redux/actions/login'
import * as CONSTANTS from '../utils/constants'
import * as USER_REQUESTS from '../redux/actions/users'

import '../styles/Sidebar.css'
import '../styles/Login.css'
import loginReducer from '../redux/reducers/login'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    navbar: {
        height: 75,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#0F2557',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '0px 15px',
        color: '#f7f8fa',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
    },
    sidebar: {
        width: 300,
        height: '100%',
    },
    licenseText: {
        maxHeight: '500px',
        overflow: 'auto'
    }
})

class Root extends Component {

    state = {
        renderPage: false,
        superAdminSidebar: [
            {
                label: 'companies',
                to: '/companies',
                icon: <CompaniesIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.SUPER_ADMIN]
            },
            {
                label: 'users',
                to: '/users',
                icon: <PeopleIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.SUPER_ADMIN]
            },
        ],
        sidebarItems: [
            {
                label: 'dashboard',
                to: '/',
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM],
                hasAccess: false,
                icon: <DashboardIcon />
            },
            {
                label: 'tickets',
                to: '/tickets',
                nested: true,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.USER],
                expandedText: 'tickets',
                icon: <AssignmentIcon />,
                nestedComponents: [
                    {
                        to: '/tickets/pending',
                        label: 'ticketPending',
                        icon: <ActiveIcon />
                    },
                    {
                        to: '/tickets/new',
                        label: 'ticketNew',
                        icon: <NewIcon />
                    },
                    {
                        to: '/tickets/verify',
                        label: 'ticketVerify',
                        icon: <VerifyIcon />

                    },
                    {
                        to: '/tickets/closed',
                        label: 'ticketClosed',
                        icon: <ClosedIcon />

                    }
                ]
            },
            {
                label: 'infoForms',
                to: '/infoForms',
                icon: <InfoFormIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM]
            },
            {
                label: 'companies',
                to: '/companies',
                icon: <CompaniesIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.SUPER_ADMIN]
            },
            {
                label: 'buildings',
                to: '/buildings',
                icon: <BuildingIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM]
            },
            {
                label: 'invoices',
                to: '/invoices',
                icon: <InvoiceIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN]
            },
            // {
            //     label: 'teams',
            //     to: '/teams',
            //     icon: <UserTeamsIcon />
            // }
            {
                label: 'users',
                nested: true,
                expandedText: 'users',
                to: '/users',
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.SUPER_ADMIN],
                icon: <PeopleIcon />,
                nestedComponents: [
                    {
                        to: '/users/pms',
                        label: 'pm',
                        icon: <CustomerIcon />
                    },
                    {
                        to: '/users/fms',
                        label: 'fm',
                        icon: <AgentIcon />
                    },
                    {
                        to: '/users/users',
                        label: 'user',
                        icon: <AdminIcon />

                    }
                ]
            },
            // {
            //     label: 'teams',
            //     to: '/teams',
            //     icon: <TeamsIcon />
            // },
            // {
            //     label: 'departments',
            //     to: '/departments',
            //     icon: <DepartmentsIcon />
            // },
            // {
            //     label: 'raports',
            //     to: '/raports',
            //     icon: <AssessmentIcon />
            // },
            {
                label: 'settings',
                icon: <SettingsIcon />,
                expandedText: 'settings',
                nested: true,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN],
                nestedComponents: [
                    {
                        to: '/settings/tags',
                        label: 'tags',
                        icon: <TagIcon />
                    },
                    {
                        to: '/settings/mail',
                        label: 'mail',
                        icon: <MailIcon />
                    },
                    {
                        to: '/settings/notifications',
                        label: 'notifications',
                        icon: <NotificationsIcon />
                    }
                ]
            },
            {
                label: 'support',
                to: '/support',
                icon: <SupportIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.USER]
            }
        ]
    }

    setRoles = loginData => {
        let sidebar = this.state.sidebarItems.filter(item => item !== null)

        let sidebarItemsCopy = sidebar.map(item => ({ ...item }))
        for (const el in sidebarItemsCopy) {
            let canAccess = checkForRole(sidebarItemsCopy[el].accessFor, loginData.role)
            sidebarItemsCopy[el].hasAccess = canAccess
        }

        this.setState({ sidebarItems: sidebarItemsCopy, renderPage: true })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loginReducer.role !== nextProps.loginReducer.role) {
            this.setRoles(nextProps.loginReducer)
        }
    }

    componentDidMount() {
        this.props.isLogged().then(() => {
            this.setRoles(this.props.loginReducer)
        })
            .catch(() => {
                this.setState({ renderPage: true })
            })
    }

    render() {
        let { classes } = this.props
        if (this.state.renderPage) {
            if (!this.props.loginReducer.isLogged) return (
                <JssProvider>
                    <Router>
                        <Switch>
                            <Route path="/reset/:TOKEN/:ID" exact component={Reset} />
                            <Route path="**" component={Login} />
                        </Switch>
                    </Router>
                </JssProvider>
            )

            if (this.props.loginReducer && !this.props.loginReducer.licenseAccepted)
                return <div className='loginContainer'>
                    <SimpleModal
                        open={!this.props.loginReducer.licenseAccepted}
                        maxWidth={'sm'}
                        title={this.props.language.titles.acceptLicense}
                        acceptButtonText={this.props.language.buttons.accept}
                        onAccept={() => {
                            this.props.acceptLicense(this.props.loginReducer._id, localStorage.getItem("tokenHelpdeskSolvvo"))
                                .then(updatedUser => {
                                    this.props.updateToken(updatedUser)
                                })
                        }}>
                        <div className={classes.licenseText}>
                            <p>{this.props.language.acceptLicenseText.title}</p>
                            <p>{this.props.language.acceptLicenseText.statement}</p>
                            <p>{this.props.language.acceptLicenseText.textA}</p>
                            <p>{this.props.language.acceptLicenseText.textB}</p>
                            <p>{this.props.language.acceptLicenseText.personalInfo}</p>
                            <p>{this.props.language.acceptLicenseText.display}</p>  
                            <p>{this.props.language.acceptLicenseText.storage}</p>
                            <p>{this.props.language.acceptLicenseText.rightsI}</p>
                            <p>{this.props.language.acceptLicenseText.rightsII}</p>
                            <p>{this.props.language.acceptLicenseText.rightsIII}</p>
                            <p>{this.props.language.acceptLicenseText.rightsIV}</p>
                            <p>{this.props.language.acceptLicenseText.rightsV}</p>
                            <p>{this.props.language.acceptLicenseText.rightsVI}</p>
                            <p>{this.props.language.acceptLicenseText.contact}</p>
                            <p>{this.props.language.acceptLicenseText.complaints}</p>
                            <p>{this.props.language.acceptLicenseText.info}</p>
                        </div>
                    </SimpleModal>
                </div>
            if (this.props.loginReducer.role === CONSTANTS.SUPER_ADMIN) {
                return (
                    <JssProvider>
                        <Router>
                            <div className={classes.content}>
                                <div className='sidebar'>
                                    <RootSidebar
                                        items={this.state.sidebarItems}
                                        superAdmin={this.props.loginReducer.role === CONSTANTS.SUPER_ADMIN}
                                    />
                                </div>
                                <Switch>
                                    <Route path="/" exact render={props => <Companies {...props} superAdmin={true} />} />
                                    <Route path="/companies" exact render={props => <Companies {...props} superAdmin={true} />} />
                                    <Route path="/companies/:ID" exact render={props => <CompanyBilling {...props} superAdmin={true} />} />
                                    <Route path="/users" exact render={props => <Users {...props} superAdmin={true} />} />
                                </Switch>
                            </div>
                        </Router>
                    </JssProvider>
                )
            } else {
                return (
                    <JssProvider>
                        <Router>
                            <div className={classes.container}>
                                {/* <div className={classes.navbar}>
                                    <Navbar />
                                </div> */}
                                <div className={classes.content}>
                                    <div className='sidebar'>
                                        <RootSidebar
                                            items={this.state.sidebarItems}
                                        />
                                    </div>
                                    <Switch>
                                        <Route path="/" exact component={checkForRole([CONSTANTS.USER, CONSTANTS.FM], this.props.loginReducer.role) ? Tickets : Dashboard} />
                                        {<Route path="/tickets" exact component={Tickets} />}
                                        {checkForRole([CONSTANTS.PM, CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/companies" exact component={Companies} />}
                                        {checkForRole([CONSTANTS.PM, CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/companies/:ID" exact component={CompanyDetails} />}
                                        <Route path="/tickets/:FILTER(pending|new|verify|closed)" exact component={Tickets} />
                                        <Route path="/tickets/:ID" exact component={TicketDetails} />
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/users" exact component={Users} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/users/:FILTER(pms|fms|users)" exact component={Users} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM], this.props.loginReducer.role) && <Route path="/users/:ID" exact component={UserDetails} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM], this.props.loginReducer.role) && <Route path="/buildings" exact component={Buildings} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM], this.props.loginReducer.role) && <Route path="/buildings/:ID" exact component={BuildingDetails} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/invoices" exact component={Invoices} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/settings/tags" exact component={Tags} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/settings/mail" exact component={Mail} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/settings/notifications" exact component={Notifications} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM], this.props.loginReducer.role) && <Route path="/infoForms" exact component={InfoForms} />}
                                        <Route path="/support" exact component={Support} />
                                        {/* <Route path="/teams" exact component={Teams} />
                                        <Route path="/teams/:ID" exact component={TeamDetails} /> */}
                                        <Redirect from="*" to={checkForRole([CONSTANTS.USER, CONSTANTS.FM], this.props.loginReducer.role) ? Tickets : Dashboard} />
                                    </Switch>
                                </div>
                            </div>
                        </Router>
                    </JssProvider>
                )
            }
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    loginReducer: reducers.loginReducer,
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    isLogged: () => dispatch(LOGIN.isLogged()),
    acceptLicense: (id, token) => dispatch(USER_REQUESTS.acceptLicense(id, token)),
    updateToken: token => dispatch(LOGIN.updateToken(token))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Root))