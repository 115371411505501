import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import moment from 'moment'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'

import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as TICKET_REQUESTS from '../../../redux/actions/tickets'

const styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paddingTopButton: {
        padding: '5px 0px'
    },
    timePickerContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    closedTimePickerContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '300px'
    },
    marginLeftContainer: {
        marginLeft: '15px'
    },
    inputField: {
        flex: 1
    }
})

class ScheduleTicketModal extends Component {

    initialTicketFields = [
        { type: 'dateTime', name: 'startTime', label: this.props.language.labels.startDate },
        { type: 'dateTime', name: 'endTime', label: this.props.language.labels.endDate },
    ]

    state = {
        ticketFields: this.initialTicketFields,
        modifiedSchedule: false,
        openPicker: false
    }

    InputWrapper = ({ input, key, shrink, onChange, onFocus }) => <div>
        <InputGenerator
            key={key}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onFocus={event => onChange ? onChange(event) : this.onChangeHandler(event)}
            onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
            onToggle={event => this.onToggleHandler(event)}
        />
    </div>

    onToggleHandler = async event => {
        if (event) {
            setTimeout(() => {
                this.setState({ openPicker: true })
            }, 100)
        }
        else {
            setTimeout(() => {
                this.setState({ openPicker: false })
            }, 50)
        }
    }

    onChangeHandler = async event => {
        const currentIndex = this.state.ticketFields.findIndex(index => index.name === event.target.name)
        let ticketFieldsCopy = null

        if (currentIndex > -1) {
            ticketFieldsCopy = this.state.ticketFields.map(field => ({ ...field }))
        }

        ticketFieldsCopy[currentIndex].value = event.target.value
        this.setState({ ticketFields: ticketFieldsCopy, modifiedSchedule: true, openPicker: true })
    }

    onCloseHandler = () => {
        this.props.scheduleTicketModalHandler(false)
        this.setState({ ticketFields: this.initialTicketFields, modifiedSchedule: false })
    }

    onAcceptHandler = () => {
        let ticket = {}
        let isOk = true
        let edited = false

        let fieldsCopy = [...this.state.ticketFields].map(field => ({ ...field }))
        this.state.ticketFields.forEach(field => {
            ticket[field.name] = field.value
        })

        fieldsCopy.forEach(field => {
            field.error = false
            if (!field.value) {
                isOk = false
                field.error = true
            }
        })
        this.setState({ ticketFields: fieldsCopy })

        let startTime = moment(ticket.startTime, 'h:mma')
        let endTime = moment(ticket.endTime, 'h:mma')
        let minHour = moment(startTime).hour(CONSTANTS.MIN_TIME).minute(0)
        let maxHour = moment(endTime).hour(CONSTANTS.MAX_TIME).minute(0)

        if (startTime.isAfter(endTime))
            return NOTIFICATIONS.error(this.props.language.toastr.ticket.errorInvalidSchedule)
        else if (startTime.isBefore(minHour) || endTime.isAfter(maxHour))
            return NOTIFICATIONS.error(this.props.language.toastr.ticket.errorWorkHours)


        this.props.getTicket(this.props.ticketId).then(response => {
            if (response.endTime && response.endTime.length)
                edited = (this.props.loginReducer.role === CONSTANTS.PM && response.edited === false) ? false : true
            if (isOk && this.state.modifiedSchedule) {
                this.props.scheduleTicket(this.props.ticketId, ticket.startTime, ticket.endTime, edited)
                    .then(() => {
                        NOTIFICATIONS.success(this.props.language.toastr.ticket.successSchedule)
                        this.props.getTickets()
                        this.props.triggerNotifications()
                        this.props.scheduleTicketModalHandler(false)
                        this.setState({ modifiedSchedule: false })
                    })
                    .catch(() => {
                        NOTIFICATIONS.error(this.props.language.toastr.ticket.errorSchedule)
                        this.setState({ ticketFields: this.initialTicketFields })
                    })
            } else if (!isOk) {
                return NOTIFICATIONS.error(this.props.language.toastr.ticket.incompleteFields)
            } else if (!this.state.modifiedSchedule) {
                return NOTIFICATIONS.error(this.props.language.toastr.ticket.modifyFields)
            }
        })
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.ticketFields.forEach(field => {
            jsonMap[field.name] = field
        })
        return (
            <div className={this.props.classes.flexColumn}>
                <div className={this.state.openPicker ? this.props.classes.closedTimePickerContainer : this.props.classes.timePickerContainer}>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['startTime']} key={'startTime'} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton} ${this.props.classes.marginLeftContainer}`}>
                        <InputWrapper shrink={true} input={jsonMap['endTime']} key={'endTime'} />
                    </div>
                </div>
            </div>
        )

    }

    render() {
        const { language } = this.props
        return <>
            <SimpleModal
                open={this.props.open}
                onCancel={() => this.onCloseHandler()}
                onAccept={() => this.onAcceptHandler()}
                acceptButtonText={this.props.language.buttons.schedule}
                cancelButtonText={this.props.language.buttons.cancel}
                canSubmitForm={true}
                maxWidth={'sm'}
                title={this.props.language.titles.scheduleTicket}
            >
                {this.renderFields()}
            </SimpleModal>
        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    scheduleTicket: (id, startTime, endTime, edited) => dispatch(TICKET_REQUESTS.schedule(id, startTime, endTime, edited)),
    getTicket: id => dispatch(TICKET_REQUESTS.getById(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ScheduleTicketModal))