import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import * as TICKET_REQUESTS from '../../redux/actions/tickets'
import * as COMPANY_REQUESTS from '../../redux/actions/companies'
import * as NOTIFICATIONS from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'

import List from './list/List'
import Header from '../common/Header'
import CreateTicketModal from './modals/CreateTicketModal'
import ConfirmationDialog from '../common/ConfirmationDialog'
import Pagination from '../common/Pagination'
import ScheduleTicketModal from './modals/ScheduleTicketModal'

const Tickets = props => {

    let { classes } = props
    let [openModal, setOpenModal] = useState(false)
    let [openScheduleModal, setOpenScheduleModal] = useState(false)
    let [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    let [rows, setRows] = useState([])
    let [count, setCount] = useState(0)
    let [from, setFrom] = useState(props.history.location.state ? props.history.location.state : 0)
    let [search, setSearch] = useState('')
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE)
    let [ticketToDelete, setTicketToDelete] = useState({})
    let [ticketIdForCalendar, setTicketIdForCalendar] = useState(null)
    let [notificationsNo, setNotificationsNo] = useState(0)
    let [withoutAdd, setWithoutAdd] = useState(false)


    useEffect(() => {
        if(props.loginReducer.role !== CONSTANTS.PM)
            checkCompany()
        getTicketsHandler({ filter: props.match.params.FILTER, search, from })
        getNotifications()
    }, [props.match.params.FILTER, search, from])

    let filterOptionHandler = options => {
        let filterOption = ''
        if (options && options.filter) {
            filterOption = options.filter
        }
        else if (options && options.filter === undefined) {
            filterOption = ''
        }
        else {
            filterOption = props.match.params.FILTER
        }

        return filterOption
    }

    let getTicketsHandler = options => {
        const filterOptions = filterOptionHandler(options)
        return props.getTickets({
            from: from,
            search: search,
            limit: rowsPerPage,
            filter: filterOptions
        }).then(({ tickets, count }) => {
            setCount(count)
            setRows(tickets.map((ticket, index) => ({
                index: index + 1,
                _id: ticket._id,
                identifier: ticket._id.slice(-6),
                status: ticket.status || 'OPEN',
                subject: ticket.subject || '**',
                author: ticket.author ? `${ticket.author.firstName} ${ticket.author.lastName}` : '**',
                role: ticket.author ? (ticket.author.role).toUpperCase() : '**',
                company: ticket.assignedCompany ? ticket.assignedCompany.name : '**',
                companyId: ticket.assignedCompany ? ticket.assignedCompany._id : '**',
                building: ticket.building ? ticket.building.name : '**',
                service: ticket.services ? `${ticket.services[0]}` : '**',
                createdAt: ticket.createdAt || '**',
                favoritedBy: ticket.favoritedBy ? ticket.favoritedBy : [],
                updated: moment(ticket.updatedAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT) || '**',
                seenBy: ticket.seenBy || [],
                seen: ticket.seen,
                priority: ticket.priority,
                edited: ticket.edited
            })))
            setOpenConfirmationModal(false)
        })
    }

    let onSearchHandler = value => {
        setFrom(0)
        setSearch(value)
    }

    let calendarHandler = (value, id) => {
        setOpenScheduleModal(value)
        setTicketIdForCalendar(id)
    }

    let onDeleteTicketHandler = () => {
        props.deleteTicket(ticketToDelete._id).then(() => {
            NOTIFICATIONS.success(props.language.toastr.ticket.successDelete)
            getTicketsHandler()
            getNotifications()
        })
            .catch(() => {
                NOTIFICATIONS.error(props.language.toastr.ticket.errorDelete)
            })
    }
    let changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
    }

    let changePageHandler = currentFrom => {
        props.history.push({ state: currentFrom })  
        setFrom(currentFrom)
    }
    let getNotifications = () => {
        if (props.loginReducer.role === CONSTANTS.ROLES.PM || props.loginReducer.role === CONSTANTS.ROLES.FM || props.loginReducer.isOfficial) {
            let today = new Date()
            props.getNotifications(today).then(response => {
                setNotificationsNo(response.count)
            })
        }
    }
    let checkCompany = () => {
        let regex = new RegExp(CONSTANTS.CONTRACTOR_REGEX,'i')
        if(props.loginReducer.role === CONSTANTS.FM)
            setWithoutAdd(true)
        else
            props.getCompany(props.loginReducer.companyId).then(company => {
                if(company.type){
                    if(company.type.match(regex))
                        setWithoutAdd(true)
                }
                
            })
    }

    return (
        <div className={classes.container}>
            <ConfirmationDialog
                open={openConfirmationModal}
                cancelButtonText={props.language.buttons.cancel}
                acceptButtonText={props.language.buttons.delete}
                text={`${props.language.utils.delete}`}
                onCancel={() => setOpenConfirmationModal(false)}
                onClose={() => setOpenConfirmationModal(false)}
                onAccept={() => onDeleteTicketHandler()}
            />
            <CreateTicketModal
                getTickets={() => getTicketsHandler()}
                creatTicketModalHandler={value => setOpenModal(value)}
                open={openModal}
                triggerNotifications={() => getNotifications()}
            />
            <ScheduleTicketModal
                scheduleTicketModalHandler={value => setOpenScheduleModal(value)}
                open={openScheduleModal}
                ticketId={ticketIdForCalendar}
                triggerNotifications={() => getNotifications()}
                getTickets={() => getTicketsHandler()}
            />
            <div className={classes.header}>
                <Header
                    placeholderText={props.language.utils.searchTicketText}
                    title={props.language.titles.ticketTable}
                    buttonText={props.language.buttons.create}
                    export
                    withoutAdd={withoutAdd}
                    onSearch={filter => onSearchHandler(filter)}
                    onClick={() => setOpenModal(true)}
                    addText={props.language.headerTooltip.ticket}
                    profileText={props.language.headerTooltip.profile}
                    notificationsNo={notificationsNo}
                    getTickets={() => getTicketsHandler()}
                />
            </div>
            <div className={classes.listContainer}>
                {count > 0 ? <List
                    deleteTicket={ticket => {
                        setTicketToDelete(ticket)
                        setOpenConfirmationModal(true)
                    }}
                    from={from}
                    rows={rows}
                    getTickets={() => getTicketsHandler()}
                    calendarHandler={(value, id) => calendarHandler(value, id)}
                /> : <div className={classes.noResultsText} > <span>{props.language.utils.noResults}</span></div>}
                <Pagination
                    from={from}
                    count={count}
                    page={props.history.location.state / rowsPerPage}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPageHandler}
                    changePageHandler={changePageHandler}
                />
            </div>
        </div >
    )
}

const styles = theme => ({
    container: {
        width: 'calc(100% - 216px) ',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    noResultsText: {
        paddingLeft: 30,
        paddingTop: 20
    },
    listContainer: {
        overflow: 'auto',
        height: 'calc(100% - 70px)',
        width: '100%'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getTickets: options => dispatch(TICKET_REQUESTS.get(options)),
    deleteTicket: ticket => dispatch(TICKET_REQUESTS.del(ticket)),
    getNotifications : today => dispatch(TICKET_REQUESTS.getNotifications(today)),
    getCompany : id => dispatch(COMPANY_REQUESTS.getById(id))
})


export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Tickets)))