import React from 'react'

import { DateTimePicker } from 'react-widgets'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import DateRangeIcon from '@material-ui/icons/DateRange'
import ScheduleIcon from '@material-ui/icons/Schedule'

import { withStyles } from '@material-ui/core/styles'
import 'react-widgets/dist/css/react-widgets.css'

import { DATE_TIME_SELECTOR_FORMAT } from '../../utils/constants'

moment.locale('ro-RO')
momentLocalizer()

const styles = theme => ({
    inputContainer: {
        paddingTop: '20px',
        width: '100% !important',
        borderColor: '#e2e5ed !important'
    },
    inputContainerNoPadding: {
        width: '100% !important',
        borderColor: '#e2e5ed !important'
    }
})

class DateTimeSelector extends React.Component {
    state = {
        value: null
    }

    render() { 
        return <div className={this.props.noPadding ? this.props.classes.inputContainerNoPadding : this.props.classes.inputContainer}>
            <DateTimePicker 
                culture={'ro'}
                defaultValue={null}
                format={DATE_TIME_SELECTOR_FORMAT}
                dateIcon={<DateRangeIcon />}
                timeIcon={<ScheduleIcon />}
                placeholder={this.props.label}
                onChange={values => this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: values,
                    }
                })}
                onToggle={this.props.onToggle}
            />
        </div>
    }
}
 
export default withStyles(styles)(DateTimeSelector);