import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const TEAM_ROUTE = '/teams'

export const create = team => dispatch => axios.post(`${TEAM_ROUTE}`, team)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const get = query => dispatch => axios.get(`${TEAM_ROUTE}?all=${query.all || false}&from=${query.from || ''}&limit=${query.limit || ''}&search=${query.search ? query.search : ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${TEAM_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const edit = (id, team) => dispatch => axios.put(`${TEAM_ROUTE}/${id}`, team)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${TEAM_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)