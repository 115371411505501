import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Delete as DeleteIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../../utils/constants'
import * as NOTIFICATION from '../../../../utils/notification'
import { BACKEND_URL } from '../../../../utils/constants'
import * as TEAM_REQUESTS from '../../../../redux/actions/teams'
import * as USER_REQUESTS from '../../../../redux/actions/users'

import SimpleTable from '../../../common/SimpleTable'
import ConfirmationDialog from '../../../common/ConfirmationDialog'
import AddUsers from './AddUsers'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 130px)'
    },
    button: {
        backgroundColor: '#00458B',
        marginRight: '10px',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
        color: 'white',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: '#00458B',
            opacity: 0.75
        }
    }
})

class TeamUsers extends Component {
    userToDelete


    state = {
        count: 0,
        from: 0,
        rows: [],
        search: '',
        allRows: [],
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        openConfirmationModal: false,
        openAddMemberConfirmationModal: false
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.team.members.length !== nextProps.team.members.length) {
            this.mapUsers(nextProps.team)
        }
    }

    mapUsers = (team) => {
        this.setState({
            count: team.members.length,
            rows: [...team.members].map((member, index) => ({
                index: index + 1,
                image: { type: 'image', value: member.profileImage ? `${BACKEND_URL}/static/${member.profileImage.path}` : "https://vectorified.com/images/profile-icon-png-27.png" },
                _id: member._id,
                name: `${member.firstName} ${member.lastName}`,
                email: member.email,
                title: member.title,

            })),
            allRows: [...team.members].map((member, index) => ({
                index: index + 1,
                image: { type: 'image', value: member.profileImage ? `${BACKEND_URL}/static/${member.profileImage.path}` : "https://vectorified.com/images/profile-icon-png-27.png" },
                _id: member._id,
                name: `${member.firstName} ${member.lastName}`,
                email: member.email,
                title: member.title,

            }))
        }, this.setUsers)
    }

    setUsers = () => {
        let usersCopy = this.state.allRows.map(field => ({ ...field }))
        usersCopy = usersCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: usersCopy })
    }

    componentDidMount() {
        this.mapUsers(this.props.team)
    }

    onSearch = search => {
        let usersCopy = this.state.allRows.map(field => ({ ...field }))
        usersCopy = usersCopy.filter(user => user.name.includes(search) || user.email.includes(search))
        usersCopy = usersCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: usersCopy })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.setUsers)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.setUsers)
    }

    onDeleteHandler = () => {
        const newMembers = this.props.team.members.filter(member => member._id !== this.userToDelete._id)
        let newTeam = { team: { members: newMembers } }
        this.props.edit(this.props.team._id, newTeam).then(
            () => {
                NOTIFICATION.success(this.props.language.toastr.user.successDelete)
                this.props.getTeam()
                this.setState({ openConfirmationModal: false })
            }
        )
    }

    closeAddUserModal = () => this.setState({ openAddMemberConfirmationModal: false })

    render() {
        let { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.contentContainer}>
                    <ConfirmationDialog
                        open={this.state.openConfirmationModal}
                        cancelButtonText={this.props.language.buttons.cancel}
                        acceptButtonText={this.props.language.buttons.delete}
                        text={this.props.language.utils.delete}
                        onClose={() => this.setState({ openConfirmationModal: false })}
                        onCancel={() => this.setState({ openConfirmationModal: false })}
                        onAccept={this.onDeleteHandler}
                    />
                    <AddUsers
                        open={this.state.openAddMemberConfirmationModal}
                        getTeam={this.props.getTeam}
                        team={this.props.team}
                        populate={this.state.shouldPopulate}
                        close={this.closeAddUserModal}
                    />
                    <SimpleTable
                        title={this.props.language.titles.usersTeamTable}
                        header={this.props.language.tableHeaders.usersTeam}
                        rows={this.state.rows}
                        onClickRow={item => {
                            this.props.history.push(`/users/${item._id}`)
                        }}
                        headerButton={{
                            tooltip: this.props.language.buttons.add,
                            onClick: (event) => {
                                this.setState({ openAddMemberConfirmationModal: true })
                            },
                            icon: <Button className={classes.button} >
                                {this.props.language.buttons.add}
                            </Button>
                        }}
                        disableBorders
                        search={{
                            onSearch: filter => this.onSearch(filter)
                        }}
                        actions={[
                            {
                                tooltip: this.props.language.buttons.delete,
                                onClick: (event, row) => {
                                    this.userToDelete = row
                                    this.setState({ openConfirmationModal: true })
                                },
                                icon: <DeleteIcon />
                            }
                        ]}
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    edit: (teamId, newTeam) => dispatch(TEAM_REQUESTS.edit(teamId, newTeam)),
    getUsers: teamId => dispatch(USER_REQUESTS.get({ all: true, teamId: teamId })),
    getAllUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    get: options => dispatch(USER_REQUESTS.get(options))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeamUsers)))