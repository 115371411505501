import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import SimpleModal from '../../../../common/SimpleModal'
import InputGenerator from '../../../../common/InputGenerator'
import * as USER_REQUESTS from '../../../../../redux/actions/users'
import * as BUILDING_REQUESTS from '../../../../../redux/actions/buildings'
import * as NOTIFICATION from '../../../../../utils/notification'

const styles = theme => ({
    extraPadding: {
        padding: '6px 0px 6px 0px',
        flex: 1
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    }
})

let AddBuilding = props => {
    let initialFields = [
        {
            type: 'simpleSelector',
            name: 'building',
            label: props.language.utils.selectBuilding,
            options: [],
            value: '',
            shrink: false,
            onChange: event => onFieldChangeHandler(event.target.name, event.target.value)
        },
        {
            type: 'simpleSelector',
            name: 'official',
            label: props.language.utils.selectOfficial,
            options: [],
            value: null,
            shrink: false,
            onChange: event => onFieldChangeHandler(event.target.name, event.target.value)
        }
    ]
    let [buildings, setBuildings] = React.useState([])
    let [users, setUsers] = React.useState([])
    let [fields, setFields] = React.useState(initialFields)

    let onFieldChangeHandler = (name, value) => {
        let newFields = [...fields]
        let indexOfChangedField = newFields.findIndex(field => field.name == name)

        if (indexOfChangedField < 0) return NOTIFICATION.error(props.language.error)

        newFields[indexOfChangedField].value = value

        setFields(newFields)
    }

    React.useEffect(() => {
        populateFields()
    }, [props.open])

    let populateFields = async () => {
        let buildingsResult = await props.getBuildings()
        let usersResult = await props.getUsers()

        setBuildings(buildingsResult.buildings)
        setUsers(usersResult.users)

        let newFields = [...fields]
        let indexOfUsers = fields.findIndex(field => field.name === 'official')
        let indexOfBuilding = fields.findIndex(field => field.name === 'building')

        if (indexOfUsers < 0 || indexOfBuilding < 0) return NOTIFICATION.error(props.language.addBuilding.errors.populate)

        let usersOptions = [].concat([...users.map(user => ({
            name: `${user.firstName} ${user.lastName}`,
            value: user._id,
            label: user._id
        }))])

        let buildingOptions = [].concat([...buildings.map(building => ({
            name: building.name,
            value: building._id,
            label: building._id
        }))])

        newFields[indexOfUsers].options = usersOptions
        newFields[indexOfBuilding].options = buildingOptions

        setFields(newFields)
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.language.addBuilding.title}
            acceptButtonText={props.language.create}
            cancelButtonText={props.language.cancel}
            withCancelButton={true}
            onCancel={() => {
                props.onCancel()
                setFields(initialFields)
            }}
            onAccept={() => {
                let data = {}

                fields.forEach(field => data[field.name] = field.value)

                props.onSubmit(data)
                setFields(initialFields)
            }}

        >
            <div className={props.classes.flexColumn}>
                {fields.map(field => (
                    <div className={props.classes.extraPadding}>
                        <InputGenerator {...field} />
                    </div>
                ))}
            </div>
        </SimpleModal >
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getBuildings: () => dispatch(BUILDING_REQUESTS.get({ all: true })),
    getUsers: () => dispatch(USER_REQUESTS.get({ all: true }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddBuilding))