import React from 'react'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Delete as DeleteIcon, Highlight as CreatedByIcon, Directions as AssignedToIcon, Business as BuildingIcon,
    StarBorder as NotSetFavIcon, Star as SetFavIcon, Person as MyTicketIcon, VisibilityOff as NewModificationsIcon,
    CalendarToday as CalendarIcon
} from '@material-ui/icons'
import moment from 'moment'

import * as CONSTANTS from '../../../utils/constants'
import * as TICKET_REQUESTS from '../../../redux/actions/tickets'
import * as NOTIFICATION from '../../../utils/notification'

const styles = theme => ({
    container: {
        width: '100%',
        overflow: 'auto'
    },
    starIconMargin: {
        marginRight: 6
    },
    leftHeaderButton: {
        fontSize: '32px',
        marginRight: '5px',
        color: '#0f2557'
    },
    legend: {
        fontWidth: 'bold',
        fontSize: 12,
        textAlign: '-webkit-center',
        alignContent: 'center',
        marginLeft: '3px',
        color: 'black',

    },
    alignLegend: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '3px'
    },
    margin: {
        margin: '4px'
    },
    bottomLegend: {
        display: 'flex',
        bottom: 0,
        //position: 'absolute'
    },
    statusButton: {
        maxHeight: '30px',
        height: '23px',
        width: 'max-content',
        width: '90px',
        borderRadius: 8,
        fontWeight: 'bold'
    },
    priorityButton: {
        maxHeight: '30px',
        height: '23px',
        width: 'max-content',
        width: '90px',
        borderRadius: 8,
        border: '1px solid black',
        color: 'black !important'
    },
    squareStatus: {
        color: 'white',
        fontWidth: 'bold',
        fontSize: 14,
        textAlign: 'center'
    },
    prioritySquare: {
        fontSize: 14,
        textAlign: 'center',
        color: 'black !important'
    },
    dataContainer: {
        padding: '20px 30px'
    },
    ticketContainer: {
        cursor: 'pointer',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '123px',
        margin: '20px 0px',
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: '#F2F2F2'
        }
    },
    ticketFirstRow: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: 10
    },
    ticketSecondRow: {
        flex: 1,
        padding: '0px 0px 10px 10px'
    },
    ticketThirdRow: {
        flex: 1, display: 'flex',
        flexDirection: 'row',
        padding: '0px 0px 10px 7px'
    },
    firstRowText: {
        padding: '2px 18px 0px 0px',
        fontSize: 15,
        color: 'black',
        fontWeight: 400
    },
    thirdRowText: {
        position: 'relative',
        top: '-5px',
        fontWeight: '600',
        fontSize: 12,
        paddingLeft: 4
    },
    createdAtText: {
        fontWeight: '600',
        fontSize: 12
    },
    deleteContainer: {
        marginLeft: 'auto',
        fontSize: 16,
        cursor: 'pointer',
        display: 'flex'
    },
    rightPadding: {
        paddingRight: 18
    },
    buildingContainer: {
        marginLeft: 'auto',
        width: 'max-content',
        border: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: '#f6f6f6',
        padding: '5px',
        fontSize: 12,
        maxWidth: '350px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buildingText: {
        position: 'relative',
        top: '-4px',
        paddingLeft: 8
    },
    serviceContainer: {
        maxHeight: '30px',
        height: '23px',
        width: 'max-content',
        backgroundColor: '#F2F2F2',
        borderRadius: 3,
        padding: '2px 6px',
        fontSize: 15
    },
    smallText: {
        fontSize: 12
    },
    companyElipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px'
    },
    myTicketIcon: {
        marginRight: '4px',
        padding: 2,
        marginTop: '3px',
    },
    boldText: {
        fontWeight: 'bold'
    },
    actionButtons: {
        width: 30,
        height: 30,
        padding: 2
    },
    hiddenSubject: {
        maxWidth: '400px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
})

class TicketsList extends React.Component {
    populateLegend = () => {
        let { classes } = this.props
        const newArray = CONSTANTS.STATUS.map(legendItem =>
            <div className={classes.alignLegend}><div className={classes.margin}><div style={{ backgroundColor: legendItem.color }} className={classes.squareStatus}>
                {this.props.language.labels.status[legendItem.name].substr(0, 1).toUpperCase()}
            </div></div> <p className={classes.legend}>{this.props.language.labels.status[legendItem.name].toUpperCase()}</p></div>)
        return newArray;
    }

    findColor = value => {
        const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === value)
        if (statusIndex > -1) {
            const statusColor = CONSTANTS.STATUS[statusIndex].color
            return statusColor
        }
    }

    renderService = (status, value) => {
        const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === status)
        if (statusIndex > -1) {
            const statusColor = CONSTANTS.STATUS[statusIndex].color
            return (
                <div className={this.props.classes.serviceContainer} style={{
                    color: `${statusColor}`,
                    borderLeft: `3px solid ${statusColor}`,
                }}>
                    {value}
                </div>
            )
        }
    }

    renderSquare = value => {
        const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === value)
        if (statusIndex > -1) {
            const statusColor = CONSTANTS.STATUS[statusIndex].color
            return (
                <div className={this.props.classes.squareStatus}>
                    <Button style={{ color: 'white', backgroundColor: statusColor }} className={this.props.classes.statusButton}>
                        {this.props.language.labels.status[value].toLowerCase()}
                    </Button>
                </div>
            )
        }
    }

    onSearchHandler = filter => {
        this.getTicketsHandler(filter)
    }

    onSearch = search => this.setState({ search }, this.getTicketsHandler)

    onClickItem = (event, ticket) => {
        if (event.target.tagName === 'path' || event.target.tagName === 'svg' || event.target.parentNode.id === 'deleteIcon' || event.target.parentNode.id === 'favIcon') {
            event.preventDefault()
            return
        } else {
            let newSeenBy = [...ticket.seenBy]

            let seenByIndex = newSeenBy.indexOf(this.props.loggedUser._id)
            if (seenByIndex === -1) newSeenBy.push(this.props.loggedUser._id)
            this.props.editTicket(ticket._id, { seenBy: newSeenBy, seen: true })
                .then(() => this.props.history.push({ pathname: `/tickets/${ticket._id}`, state: this.props.from }))
        }
    }

    checkIsFav = ticket => {
        if (ticket.favoritedBy && ticket.favoritedBy.length === 0) return false

        let isFav = ticket.favoritedBy.includes(this.props.loggedUser._id)

        return isFav
    }

    render() {
        let { classes } = this.props
        //let mappedLegend = this.populateLegend()

        return (
            <div className={classes.container}>
                <div className={classes.dataContainer}>
                    {
                        this.props.rows.map(ticket => {
                            let disabled = ticket.edited === true
                            return (
                                <div onClick={(event) => this.onClickItem(event, ticket)} className={classes.ticketContainer} style={{ borderLeft: `4px solid ${this.findColor(ticket.status)}` }}>
                                    <div className={classes.ticketFirstRow}>
                                        <span className={`${classes.firstRowText} ${!ticket.seen ? classes.boldText : ''}`}>#{ticket._id.substr(CONSTANTS.TICKETS_ID_LENGTH)}</span>
                                        <Tooltip title={ticket.subject} placement='top'>
                                            <span className={`${classes.firstRowText} ${classes.hiddenSubject} ${!ticket.seen ? classes.boldText : ''}`}>{ticket.subject}</span>
                                        </Tooltip>
                                        <div className={classes.rightPadding}>
                                            {this.renderSquare(ticket.status)}
                                        </div>
                                        <div className={classes.rightPadding}>

                                            <div className={this.props.classes.prioritySquare}>
                                                <Button className={this.props.classes.priorityButton} disabled>
                                                    {this.props.language.labels.priority[ticket.priority].toLowerCase()}
                                                </Button>
                                            </div>
                                        </div>
                                        <div> {!ticket.seen ? <NewModificationsIcon /> : null}  </div>


                                        <div className={classes.deleteContainer}>
                                            {this.props.loggedUser.role === CONSTANTS.PM ?
                                                <Tooltip title={this.props.language.tooltip.calendar} placement='top'>
                                                    <Button className={classes.actionButtons} onClick={() => this.props.calendarHandler(true, ticket._id)}>
                                                        <CalendarIcon />
                                                    </Button>
                                                </Tooltip>
                                                : (this.props.loggedUser.role === CONSTANTS.FM
                                                    || (this.props.loggedUser.isOfficial && (ticket.companyId === this.props.loggedUser.companyId))) ? disabled ?
                                                <Tooltip title={this.props.language.tooltip.alreadyScheduled} placement='top'>
                                                    <div>
                                                        <Tooltip title={this.props.language.tooltip.calendar} placement='top'>
                                                            <Button disabled={disabled} className={classes.actionButtons} onClick={() => this.props.calendarHandler(true, ticket._id)}>
                                                                <CalendarIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </Tooltip> : <Tooltip title={this.props.language.tooltip.calendar} placement='top'>
                                                    <Button disabled={disabled} className={classes.actionButtons} onClick={() => this.props.calendarHandler(true, ticket._id)}>
                                                        <CalendarIcon />
                                                    </Button>
                                                </Tooltip> : null}
                                            {this.props.loggedUser.username === ticket.author ? <Tooltip title={this.props.language.tooltip.myTicket} placement='top'><MyTicketIcon className={`${classes.myTicketIcon}`} /></Tooltip> : null}
                                            {!this.checkIsFav(ticket) ? <Tooltip title={this.props.language.tooltip.addToFavorites} placement='top'><Button className={classes.actionButtons} onClick={() => {
                                                this.props.setFavorite(ticket._id, this.props.loggedUser._id).then(() => {
                                                    NOTIFICATION.success(this.props.language.toastr.ticket.addToFav)
                                                    this.props.getTickets()
                                                })
                                            }}><NotSetFavIcon id='favIcon' /></Button></Tooltip> : <Tooltip title={this.props.language.tooltip.removeFromFavorites} placement='top'><Button className={classes.actionButtons} onClick={() => {
                                                this.props.unsetFavorite(ticket._id, this.props.loggedUser._id).then(() => {
                                                    NOTIFICATION.success(this.props.language.toastr.ticket.removeFromFav)
                                                    this.props.getTickets()
                                                })
                                            }}><SetFavIcon id='favIcon' /></Button></Tooltip>}
                                            {this.props.loggedUser.role === CONSTANTS.USER && !this.props.loggedUser.isOfficial ? this.props.loggedUser.username === ticket.author ? <Tooltip title={this.props.language.tooltip.deleteTicket} placement='top'><Button className={classes.actionButtons} onClick={() => {
                                                this.props.deleteTicket(ticket)
                                            }} ><DeleteIcon /></Button></Tooltip> : null : <Tooltip title={this.props.language.tooltip.deleteTicket} placement='top'><Button className={classes.actionButtons} onClick={() => {
                                                this.props.deleteTicket(ticket)
                                            }} ><DeleteIcon /></Button></Tooltip>}
                                        </div>
                                    </div>
                                    <div className={classes.ticketSecondRow}>
                                        {this.renderService(ticket.status, ticket.service)}
                                    </div>
                                    <div className={classes.ticketThirdRow}>
                                        <div className={classes.rightPadding}><CreatedByIcon /> <span className={classes.thirdRowText}>{ticket.author}</span></div>
                                        <Tooltip placement="top-end" title={ticket.company}>
                                            <div className={`${classes.rightPadding} ${classes.companyElipsis}`}>
                                                <AssignedToIcon /><span className={classes.thirdRowText}>{ticket.company}</span>
                                            </div>
                                        </Tooltip>
                                        <div><span className={classes.smallText}>{this.props.language.labels.createdAt} </span><span className={classes.createdAtText}>{moment(ticket.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}</span></div>
                                        <Tooltip title={ticket.building}>
                                            <div className={classes.buildingContainer}><BuildingIcon /><span className={classes.buildingText}>{ticket.building}</span></div>
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/* <div className={classes.bottomLegend}>
                    {mappedLegend}
                </div> */}
            </div >
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    tickets: reducers.ticketsReducer,
    loggedUser: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    setFavorite: (ticketId, userId) => dispatch(TICKET_REQUESTS.setFavorite(ticketId, userId)),
    unsetFavorite: (ticketId, userId) => dispatch(TICKET_REQUESTS.unsetFavorite(ticketId, userId)),
    editTicket: (ticketId, newTicket) => dispatch(TICKET_REQUESTS.update(ticketId, newTicket))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketsList)))