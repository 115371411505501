import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, NoteAdd as PreviewOffer } from '@material-ui/icons'
import moment from 'moment'

import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'

import Table from '../../common/SimpleTable'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    }
})


class Offers extends Component {

    state = {
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        rows: [],
        allRows: [],
        openUploadModal: false,
        file: null,
        uploadRef: React.createRef()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.offers.length !== nextProps.offers.length) {
            this.setOffers(nextProps.offers)
        }
    }
    componentDidMount() {
        this.setOffers(this.props.offers)
    }

    setOffers = offers => {
        this.setState({
            rows: offers.map((offer, index) => ({ index: index + 1, emailSentTo: offer.sentTo, billingStartingDate: moment(offer.billingStartingDate).format(CONSTANTS.DEFAULT_DATE_FORMAT), value: offer.totalPrice })),
            allRows: offers.map((offer, index) => ({ index: index + 1, emailSentTo: offer.sentTo, billingStartingDate: moment(offer.billingStartingDate).format(CONSTANTS.DEFAULT_DATE_FORMAT), value: offer.totalPrice })),
            count: offers.length
        }, this.setOffersChangePage)
    }

    setOffersChangePage = () => {
        let offersCopy = this.state.allRows.map(field => ({ ...field }))
        if (this.state.search) {
            offersCopy = offersCopy.filter(file => file.name.includes(this.state.search))
        }
        offersCopy = offersCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: offersCopy })
    }

    setSearch = search => {
        this.setState({ search }, () => {
            this.onSearch()
        })
    }

    onSearch = () => {
        let filesCopy = [...this.state.allRows].map(field => ({ ...field }))
        filesCopy = filesCopy.filter(file => file.emailSentTo.includes(this.state.search))
        let searchCount = filesCopy.length
        filesCopy = filesCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: filesCopy, count: searchCount })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.setOffersChangePage)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.setOffersChangePage)
    }


    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <Table
                    title={this.props.language.titles.sentOffers}
                    header={this.props.language.tableHeaders.sentOffers}
                    rows={this.state.rows}
                    onClickRow={() => { }}
                    search={{
                        onSearch: this.setSearch
                    }}
                    actions={[
                        {
                            tooltip: this.props.language.tooltip.previewOffer,
                            onClick: (_, offer) => {
                                let toSendOffer = this.props.offers[offer.index - 1]
                                this.props.generatePDF(this.props.companyId, this.props.company.name, toSendOffer)
                                    .then(() => {
                                        NOTIFICATIONS.success(this.props.language.toastr.company.offerSuccessfullyGenerated)
                                    })
                                    .catch(() => {
                                        NOTIFICATIONS.error(this.props.language.toastr.company.offerUnsuccessfullyGenerated)
                                    })
                            },
                            icon: <PreviewOffer />
                        },
                        {
                            tooltip: this.props.language.buttons.deleteFile,
                            onClick: (_, file) => {
                                let confirmed = window.confirm(this.props.language.toastr.file.confirmDelete)

                                if (!confirmed) return

                                this.props.deleteOffer(this.props.companyId, this.props.offers[file.index - 1]).then(() => {
                                    this.props.getCompanyDetails()
                                    this.setOffers(this.props.offers)
                                    NOTIFICATIONS.success(this.props.language.toastr.file.successDelete)
                                })
                                    .catch(() => {
                                        NOTIFICATIONS.error(this.props.language.toastr.file.errorDelete)
                                    })
                            },
                            icon: <DeleteIcon />
                        }
                    ]}
                    count={this.state.count}
                    hasNoMargin={true}
                    rowsPerPage={this.state.rowsPerPage}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    deleteOffer: (id, offerIndex) => dispatch(COMPANY_REQUESTS.deleteOffer(id, offerIndex)),
    generatePDF: (id, companyName, offer) => dispatch(COMPANY_REQUESTS.generatePDf(id, companyName, offer))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Offers))