import React, { useState, useEffect } from 'react'
import { withStyles, InputAdornment } from '@material-ui/core'
import { ErrorOutline as InformIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import validator from 'validator'

import InputGenerator from '../../common/InputGenerator'
import SimpleModal from '../../common/SimpleModal'
import AnafButton from '../../common/AnafButton'

import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
        endAdornment={props.endAdornment}
    />
</div>

let Create = props => {
    let { classes } = props

    let initialFields = [
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
        { value: 0, type: 'number', label: props.language.labels.totalSurface, name: 'totalArea' },
        { value: 0, type: 'number', label: props.language.labels.commercialSurface, name: 'commercialArea' },
        { value: 0, type: 'number', label: props.language.labels.officeSurface, name: 'officeArea' },
        { value: 0, type: 'number', label: props.language.labels.utilSurface, name: 'usableArea' },
        { value: '', type: 'phone', label: props.language.labels.contactPhone, name: 'phoneNumber', default: '+' },
        { value: '', type: 'text', label: props.language.labels.website, name: 'website' },
        { value: '', type: 'text', label: props.language.labels.mail, name: 'email' },
        { type: 'simpleSelector', name: 'owner', label: props.language.labels.propertyOwner, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'facilityManager', label: props.language.labels.facilityManager, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'propertyManager', label: props.language.labels.propertyManager, value: null, options: [], shrink: false },
        {
            type: 'fixedMultiSelect',
            name: 'services',
            utils: props.language.labels.services,
            options: [],
            value: []
        }
    ]

    let [fields, setFields] = useState([...initialFields].map(obj => ({ ...obj })))
    let [cui, setCui] = useState('')

    let onCuiChange = event => setCui(event.target.value)

    let getServices = type => props.getServices(type)
        .then(data => data)

    useEffect(() => {
        if (props.building) {
            return populate()
        }


        let getAllUsers = props.getAllUsers()
        let getAllServices = getServices('services')

        Promise.all([getAllUsers, getAllServices]).then(values => {
            let options = values[0].users.map(user => ({
                name: `${user.firstName} ${user.lastName}`,
                value: user._id,
                role: user.role
            }))

            let servicesOptions = [...values[1].map(type => ({
                name: type.name,
                value: true,
                label: type.name
            }))]

            let newFields = [...fields]

            let ownerIndex = newFields.findIndex(field => field.name === 'owner')
            let facilityManagerIndex = newFields.findIndex(field => field.name === 'facilityManager')
            let propertyManagerIndex = newFields.findIndex(field => field.name === 'propertyManager')
            let servicesIndex = newFields.findIndex(field => field.name === 'services')

            if (ownerIndex < 0 || facilityManagerIndex < 0 || propertyManagerIndex < 0 || servicesIndex < 0) {
                return NOTIFICATION(props.language.toastr.building.populateError)
            }

            newFields[ownerIndex].options = options
            newFields[facilityManagerIndex].options = options.filter(person => person.role === props.language.comments.userRole.fm)
            newFields[propertyManagerIndex].options = options.filter(person => person.role === props.language.comments.userRole.pm)
            newFields[servicesIndex].defaultValue = servicesOptions
            newFields[servicesIndex].value = servicesOptions.map(service => ({ ...service, value: true })).filter(service => service.name)
            newFields[servicesIndex].options = servicesOptions

            setFields(newFields)
        })


        return () => { }
    }, [props.building], [props.open])

    let populate = () => {
        let newFields = [...fields]
        Object.keys(props.building).forEach(key => {
            let fieldIndex = newFields.findIndex(f => f.name === key)

            if (fieldIndex <= -1) return

            newFields[fieldIndex].value = props.building[key]
            newFields[fieldIndex].disabled = false
        })

        setFields(newFields)
    }

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(field => field.name == event.target.name)
        let newFields = [...fields]

        if (!(fieldIndex > -1)) return

        if (event.target.name === 'phoneNumber')
            newFields[fieldIndex].value = `${event.target.default}${event.target.value}`
        else if ((event.target.name === 'owner' || event.target.name === 'facilityManager' || event.target.name === 'propertyManager') && event.target.value === '')
            newFields[fieldIndex].value = null
        else
            newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let createHandler = () => {
        let data = {}
        data.area = {}
        data.contact = {}
        data.cui = cui

        fields.forEach(field => {
            if (field.name === 'totalArea') {
                data.area.total = field.value
            } else if (field.name === 'usableArea') {
                data.area.usable = field.value
            } else if (field.name === 'officeArea') {
                data.area.office = field.value
            } else if (field.name === 'commercialArea') {
                data.area.commercial = field.value
            } else if (field.name === 'email' || field.name === 'website' || field.name === 'phoneNumber') {
                data.contact[field.name] = field.value
            } else if (field.name === 'services')
                data.services = field.value ? field.value.map(field => field.name) : field.defaultValue.map(field => field.name)
            else data[field.name] = field.value
        })

        let fieldsCopy = [...fields]
        let isOk = true

        fieldsCopy.forEach(field => {
            field.error = false
            switch (field.name) {
                case 'name':
                    if (!validator.isLength(data['name'], { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.BUILDING_MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'email':
                    if (!validator.isEmpty(data.contact['email']))
                        if (!validator.isEmail(data.contact['email'])) {
                            field.error = true
                            isOk = false
                        }
                    break
                case 'website':
                    if (!validator.isEmpty(data.contact['website']))
                        if (!validator.isURL(data.contact['website'])) {
                            field.error = true
                            isOk = false
                        }
                    break
                case 'phoneNumber':
                    if (!validator.isEmpty(data.contact['phoneNumber']))
                        if (!validator.isMobilePhone(data.contact['phoneNumber'], "any", { strictMode: true })) {
                            if (!data.contact['phoneNumber'].startsWith('+402')) {
                                field.error = true
                                isOk = false
                            }
                        }
                    break
                default:
                    break
            }
        })
        setFields(fieldsCopy)

        if (!isOk) {
            return NOTIFICATION.error(props.language.toastr.building.errors)
        }

        if (!props.building) return props.createBuilding({ building: data })
            .then(() => {
                props.onAccept()
                clearForm()
                NOTIFICATION.success(props.language.toastr.building.successCreate)
            })
            .catch((err) => {
                err.message.includes(CONSTANTS.LIMIT_REACHED_CODE) ?
                    NOTIFICATION.error(props.language.toastr.building.buildingsLimitReached) :
                    NOTIFICATION.error(props.language.toastr.building.errorCreate)
            })

        return props.editBuilding(props.building._id, { building: data })
            .then(() => {
                props.onAccept()
                clearForm()
                NOTIFICATION.success(props.language.toastr.building.successedit)
            })
            .catch(() => NOTIFICATION.error(props.language.toastr.building.errorEdit))

    }

    let onAnafButtonClickedHandler = anafData => {
        let newFields = [...fields]

        let indexOfName = newFields.findIndex(field => field.name == 'name')

        if (indexOfName < 0) return

        newFields[indexOfName].value = anafData.denumire

        setFields(newFields)
    }

    let clearForm = () => {
        let newFields = [...initialFields].map(obj => ({ ...obj }))
        setFields(newFields)
    }

    let renderFields = () => {
        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['name']}
                        input={jsonMap['name']}
                        key={'name'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>
                <div className={`${classes.inputField} ${classes.flexRow} ${classes.inputPadding}`}>
                    <div className={`${classes.inputField} ${classes.paddingRight}`} >
                        <InputWrapper
                            {...jsonMap['owner']}
                            input={jsonMap['owner']}
                            key={'owner'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                    <div className={`${classes.inputField} ${classes.paddingRight}`} >
                        <InputWrapper
                            {...jsonMap['facilityManager']}
                            input={jsonMap['facilityManager']}
                            key={'facilityManager'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={classes.inputField}>
                        <InputWrapper
                            {...jsonMap['propertyManager']}
                            input={jsonMap['propertyManager']}
                            key={'propertyManager'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                </div>
                <div className={`${classes.inputField} ${classes.flexRow} ${classes.inputPadding}`}>
                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['totalArea']}
                            input={jsonMap['totalArea']}
                            key={'totalArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>
                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['usableArea']}
                            input={jsonMap['usableArea']}
                            key={'usableArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['commercialArea']}
                            input={jsonMap['commercialArea']}
                            key={'commercialArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>

                    <div className={classes.inputField}>
                        <InputWrapper
                            {...jsonMap['officeArea']}
                            input={jsonMap['officeArea']}
                            key={'officeArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        key={'phoneNumber'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        input={jsonMap['email']}
                        key={'email'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['website']}
                        input={jsonMap['website']}
                        key={'website'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['services']}
                        input={jsonMap['services']}
                        key={'services'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                {!props.building && <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <div className={classes.flexRow}>
                        <InformIcon className={classes.informIcon} />
                        <div className={classes.importantTextContainer}>
                            <span className={classes.importantText}>{props.language.titles.important}</span>
                        </div>
                    </div>
                    <p className={classes.infoText}>{props.language.utils.buildingWarning}</p>
                </div>}
            </div>
        )
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.language.titles.createBuilding}
            acceptButtonText={props.building ? props.language.edit : props.language.create}
            cancelButtonText={props.language.cancel}
            onCancel={() => {
                props.onCancel()
                clearForm()
            }}
            onAccept={createHandler}
        >
            <div className={classes.flexRow}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        label={props.language.labels.cui}
                        input={'cui'}
                        shrink={true}
                        onChange={onCuiChange} />
                </div>
                <div className={classes.anafButton}>
                    <AnafButton
                        cui={cui}
                        onAnafButtonClicked={onAnafButtonClickedHandler}
                    />
                </div>
            </div>
            {renderFields()}
        </SimpleModal>
    )
}

const styles = theme => ({
    paddingRight: {
        marginRight: 6
    },
    anafButton: {
        paddingTop: 10
    },
    informIcon: {
        color: '#f50057'
    },
    infoText: {
        color: 'rgba(0,0,0,0.5)',
        fontSize: 14,
        margin: 0,
        padding: 0,
        paddingLeft: 24,
        fontWeight: 500
    },
    importantTextContainer: {
        marginTop: 0,
        marginLeft: 4
    },
    importantText: {
        color: '#f50057',
        letterSpacing: 1,
        fontWeight: 'bold',
        fontSize: 12
    },
    titleStyle: {
        height: '30%',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        padding: '16px 8px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    anafButton: {
        height: '100%',
        padding: '17px 0px 0px 12px'
    },
    inputPadding: {
        padding: '8px 0px'
    }
})


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    createBuilding: building => dispatch(BUILDING_REQUESTS.create(building)),
    getAllUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    getServices: type => dispatch(TAG_REQUESTS.getAll({ type })),
    editBuilding: (id, building) => dispatch(BUILDING_REQUESTS.edit(id, building))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Create))
