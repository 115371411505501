import React, { Component } from 'react'
import { TextField, Button, CircularProgress, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import validator from 'validator'

import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'
import DisplayMessage from '../companies/details/displayMessage/DisplayMessage'

import '../../styles/Login.css'
import HelpdeskLogo from '../../assets/mainLogo.png'
import { TOKEN_EXPIRED, TOKEN_STATUS } from '../../utils/constants'

class ResetPassword extends Component {
    state = {
        isLoading: false,
        email: '',
        password: '',
        repeatPassword: '',
        usernameTouched: false,
        passwordTouched: false,
        canSubmit: false,
        loading: false,
        render : false
    }
    verifyTokenHandler = token => { 
        this.props.verifyToken(token).then(response => {
            if(response.status === TOKEN_STATUS && response.message === TOKEN_EXPIRED){
                this.setState({render : false})
            }
            else{
                this.setState({render : true})
            }
        })
    }

    componentDidMount() {
        this.verifyTokenHandler(this.props.match.params.TOKEN)
    }
    reset = () => {
        if(validator.isEmpty(this.state.password) || validator.isEmpty(this.state.repeatPassword)){
            NOTIFICATION.error(this.props.language.utils.allFields)
        }
        else{
            if(this.state.password === this.state.repeatPassword){
                this.props.resetPassword(this.props.match.params.ID, this.state.password)
                .then(() => {
                    NOTIFICATION.success(this.props.language.utils.passwordReset)
                    this.props.history.push("/")
                }).catch(e => {
                    NOTIFICATION.error(this.props.language.utils.failResetPassword)
                })
            }
            else{
                NOTIFICATION.error(this.props.language.login.loginForm.passwordsDidNotMatch)
            }
        }
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        let { resetForm } = this.props.languageReducer
        let { classes } = this.props

        return this.state.render ? (
            <div className='loginContainer'>
                <div className={classes.titleContainer}>
                    {/* <span className={classes.firstTitle}>{this.props.language.title}</span> */}
                    <img src={HelpdeskLogo} alt="Logo" className={classes.logo} />
                    <span className={classes.secondTitle}>{this.props.language.secondTitle}</span>
                </div>
                <div className={classes.formContainer}>
                    <div className={classes.inputs}>
                        <TextField
                            variant="outlined"
                            className='loginInput'
                            margin="dense"
                            label={resetForm.new}
                            onChange={event => this.onChange(event)}
                            name='password'
                            type='password'
                        />
                        <TextField
                            className='loginInput'
                            label={resetForm.retype}
                            variant="outlined"
                            margin="dense"
                            type='password'
                            onChange={event => this.onChange(event)}
                            onKeyPress={event => event.key === 'Enter' ? this.reset() : null}
                            name='repeatPassword'
                        />
                        <Button
                            className='loginButton'
                            variant='contained'
                            onClick={() => this.reset()} color='primary'>
                            {this.state.isLoading ? <CircularProgress className={classes.whiteColor} /> : resetForm.reset}
                        </Button>
                    </div>
                </div>
                <div className={classes.createdByContainer}>
                    <p className={classes.cincizerodoititle}><a className={classes.aHref} href="http://502.ro">Un produs <span>502</span>.</a></p>
                </div>
            </div>
            ) :  <div className='loginContainer'>
                    <div className={classes.messageContainer}>
                        <div >
                            <DisplayMessage
                                title={this.props.language.utils.linkExpired}
                                message={this.props.language.utils.goToLogin}
                                button
                                onClick={() => {this.props.history.push("/")}}
                            />
                        </div>
                    </div>
                </div>
    }
}

const styles = {
    whiteColor: {
        color: 'white',
        size: 24
    },
    createdByContainer: {
        position: 'fixed',
        top: '95%'
    },
    logo: {
        width: 310
    },
    aHref: {
        textDecoration: 'none',
        color: 'inherit'
    },
    fontBold: {
        fontWeight: 'bold'
    },
    cincizerodoititle: {
        fontStyle: 'italic',
        fontSize: 12,
        color: 'rgba(0,0,0,0.5)',
        cursor: 'pointer'
    },
    forgottPassword: {
        width: '100%',
        fontSize: 12,
        textAlign: 'center',
        marginTop: 20,
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        marginBottom: 20,
    },
    formContainer: {
        width: 500,
        height: 300,
        backgroundColor: '#ffffff',
        borderRadius: 8,
        marginTop: 20,
        marginBottom: 50,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid rgba(0,0,0,0.2)'
    },
    inputs: {
        width: '75%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    firstTitle: {
        fontSize: 48,
        letterSpacing: 5,
        fontWeight: 500,
        opacity: 0.7,
        paddingBottom: 12
    },
    secondTitle: {
        fontSize: 16,
        letterSpacing: 4
    },
    circularProgress: {
        height: '20px !important',
        width: '20px !important',
        color: 'white',
        marginRight: 5
    },
    processText: {
        position: 'relative',
        top: '-4px'
    },
    messageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}

const mapDispatchToProps = dispatch => ({
    verifyToken: token => dispatch(LOGIN.verifyToken(token)),
    resetPassword: (id, password) => dispatch(LOGIN.resetPassword(id,password)),
})

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.login,
    language: reducers.languageReducer.i18n
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))