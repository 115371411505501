import React, { Component } from 'react'
import { Tooltip, Button, withStyles } from '@material-ui/core'
import { AttachFile as FileIcon } from '@material-ui/icons'
import JoditEditor from './JoditEditor'


const styles = theme => ({
    filesContainer: {
        listStyle: 'none',
        backgroundColor: '#fefefe',
        border: '1px solid #e0e0e0',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 0,
        '& li': {
            display: 'flex',
            lineHeight: '32px',
            '& button': {
                marginLeft: 'auto'
            },
            borderBottom: '1px solid #e0e0e0'
        }
    },
    attachIcon: {
        marginTop: '6px',
        marginLeft: '10px',
        marginRight: '10px'
    }
})

class AddComment extends Component {
    state = {
        content: '',
        filesToUpload: []
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reset && !this.props.reset) {
            this.setState({ content: '', filesToUpload: [] })
        }
    }

    fileInput = React.createRef()

    removeItem = itemIndex => {
        let files = this.state.filesToUpload
        files.splice(itemIndex, 1)
        this.setState({
            filesToUpload: files
        })
        this.props.setFiles(files)
    }

    render() {
        const editor = React.createRef()

        let handleFileInput = event => {
            let files = Array.from(event.target.files)
            this.setState({
                filesToUpload: [...this.state.filesToUpload, ...files]
            })

            this.props.setFiles([...this.state.filesToUpload, ...files])
        }

        const config = {
            readonly: false,
            uploader: {
                insertImageAsBase64URI: true,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                format: 'json',
            },
            enableDragAndDropFileToEditor: true,
            buttons: [
                '|',
                'bold',
                'strikethrough',
                'underline',
                'italic',
                'eraser',
                '|',
                'superscript',
                'subscript',
                '|',
                'ul',
                'ol',
                '|',
                'outdent',
                'indent',
                '|',
                'font',
                'fontsize',
                'brush',
                'paragraph',
                '|',
                'image',
                'video',
                'table',
                'link',
                '|',
                'align',
                'undo',
                'redo',
                '\n',
                'selectall',
                'cut',
                'copy',
                'paste',
                'copyformat',
                '|',
                'hr',
                'symbol',
                'print',
                'preview',
                'find'
            ],
            extraButtons: [this.props.showFilesUpload ? {
                name: 'File',
                icon: '',
                tooltip: "Upload File",
                isActive: true,
                exec: () => {
                    if (this.fileInput.current)
                        this.fileInput.current.click()
                }
            } : undefined]
        }


        return (
            <>
                <JoditEditor
                    ref={editor}
                    value={this.state.content}
                    tabIndex={1}
                    config={config}
                    onChange={newContent => {
                        this.setState({ content: newContent }, () => this.props.setComment(this.state.content, this.state.filesToUpload.length ? this.filesData : null))
                    }}
                    props={this.props}
                />
                {this.state.filesToUpload.length ? <ul className={this.props.classes.filesContainer}>
                    {this.state.filesToUpload.map((file, index) =>
                        <li key={index}><FileIcon className={this.props.classes.attachIcon} /> {file.name} <Tooltip onClick={() => this.removeItem(index)} title='Sterge fisier'><Button>X</Button></Tooltip></li>
                    )}
                </ul> : null}
                <input multiple type="file" style={{ display: 'none' }} onChange={handleFileInput} ref={this.fileInput} />
            </>
        )
    }
}

export default withStyles(styles)(AddComment)