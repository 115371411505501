import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import Cards from './cards/Cards'
import InfoFormModal from './modals/Modal'
import Header from '../common/Header'
import Pagination from '../common/Pagination'

import * as NOTIFICATIONS from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import * as INFO_FORM_REQUESTS from '../../redux/actions/infoForms'


const styles = theme => ({
    container: {
        width: 'calc(100% - 216px)',
        flex: 1,
        display: 'flex',
        backgroundColor: '#F9FBFF',
        flexDirection: 'column'
    },
    noResultsText: {
        paddingLeft: 30,
        paddingTop: 20
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 80px)',
        overflow: 'auto'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    searchInput: {
        width: '180px',
        marginRight: '27px',
        border: '1px solid rgba(0,0,0,0.15)',
        padding: '0px 6px'
    }
})

class InfoForms extends React.Component {

    infoFormToDelete = {}
    infoFormToDisplay = {}

    state = {
        searchInput: '',
        openConfirmationModal: false,
        openModal: false,
        infoForms: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        edit: false
    }

    componentDidMount() {
        this.getInfoFormsHandler()
    }

    getInfoFormsHandler = () => {
        return this.props.get({
            from: this.state.from,
            search: this.state.search,
            limit: this.state.rowsPerPage
        }).then(({ infoForms, count }) => {
            this.setState({
                infoForms,
                count
            })
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.getInfoFormsHandler)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.getInfoFormsHandler)
    }

    onCloseHandler = () => {
        this.setState({
            openModal: false,
            edit: false
        })
    }

    onSearchHandler = filter => {
        this.setState({ search: filter, from: 0 }, this.getInfoFormsHandler)
    }

    onDeleteHandler = () => {
        this.props.delete(this.infoFormToDelete).then(() => {
            this.setState({ openConfirmationModal: false })
            this.getInfoFormsHandler()
            NOTIFICATIONS.success(this.props.language.toastr.infoForm.successDelete)
        })
            .catch(() => NOTIFICATIONS.error(this.props.language.toastr.infoForm.errorDelete))
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Header
                        placeholderText={this.props.language.utils.searchInfoForm}
                        title={this.props.language.titles.infoForms}
                        buttonText={this.props.language.buttons.create}
                        withoutAdd={this.props.loginReducer.role === CONSTANTS.ADMIN || this.props.loginReducer.role === CONSTANTS.PM || (!this.props.loginReducer.isOfficial && this.props.loginReducer.role !== CONSTANTS.FM) ? true : false}
                        onSearch={filter => this.onSearchHandler(filter)}
                        onClick={() => this.setState({ openModal: true })}
                        addText={this.props.language.headerTooltip.infoForm}
                        profileText={this.props.language.headerTooltip.profile}
                    />
                </div>
                <div className={classes.cardsContainer}>
                    <InfoFormModal
                        open={this.state.openModal}
                        onClose={() => this.onCloseHandler()}
                        edit={this.state.edit}
                        infoForm={this.infoFormToDisplay}
                        getForms={this.getInfoFormsHandler}
                    />
                    {this.state.infoForms && this.state.infoForms.length ? <Cards
                        onDelete={(infoFormId) => {
                            this.infoFormToDelete = infoFormId
                            if(window.confirm(this.props.language.utils.delete)){
                                this.onDeleteHandler()
                            }
                        }}
                        onClick={infoForm => {
                            this.infoFormToDisplay = infoForm
                            this.setState({ openModal: true, edit: true })
                        }}
                        infoForms={this.state.infoForms} />
                        : <div className={classes.noResultsText} > <span>{this.props.language.utils.noResults}</span></div>}
                    <Pagination
                        from={this.state.from}
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(INFO_FORM_REQUESTS.get(options)),
    delete: infoFormId => dispatch(INFO_FORM_REQUESTS.remove(infoFormId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoForms)))