import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardActionArea, CardContent, Tooltip, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

import * as CONSTANTS from '../../../utils/constants'

const useStyles = makeStyles({
  root: {
    width: 260,
    height: 130,
    borderLeft: '7px solid transparent'
  },
  media: {
    height: 85,
    width: '100%',
    objectFit: 'fill'
  },
  check: {
    float: 'right',
    fontSize: 30,
    color: '#00458B'
  },
  nameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 15
  }
})

let BuildingCard = props => {
  const classes = useStyles()

  return (
    <Card className={classes.root}
      style={props.checked ? { borderLeft: '7px solid #00458B' } : {}}
      onClick={props.onClick}
    >
      <CardActionArea>
        <img className={classes.media} style={props.checked ? { opacity: 0.4 } : {}} src={props.image || CONSTANTS.DEFAULT_BUILDING_IMAGE} alt={props.name}></img>
        <CardContent>
          <Tooltip title={props.name}>
            <Typography className={classes.nameText} gutterBottom variant="h6" component="h5">
              {props.name}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BuildingCard
