import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip, CircularProgress } from '@material-ui/core'
import { ErrorOutline as InformIcon } from '@material-ui/icons'
import validator from 'validator'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'
import AnafButton from '../../common/AnafButton'

import * as CONSTANTS from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as USERS_REQUESTS from '../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    informIcon: {
        color: '#f50057'
    },
    infoText: {
        color: 'rgba(0,0,0,0.5)',
        fontSize: 14,
        margin: 0,
        padding: 0,
        paddingLeft: 24,
        fontWeight: 500
    },
    importantTextContainer: {
        marginTop: 0,
        marginLeft: 4
    },
    importantText: {
        color: '#f50057',
        letterSpacing: 1,
        fontWeight: 'bold',
        fontSize: 12
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    circularProgress: {
        color: '#00458B',
        height: '25px !important',
        width: '25px !important',
        paddingRight: 8
    },
    serviceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    anafButton: {
        height: '100%',
        padding: '17px 0px 0px 12px'
    }
})

class CreateCompanyModal extends Component {

    initialCompanyFields = [
        { value: '', type: 'text', label: this.props.language.labels.cui, name: 'cui' },
        { value: '', type: 'text', label: this.props.language.labels.name, name: 'name' },
        { value: '', type: 'text', label: this.props.language.labels.mainBusiness, name: 'mainBusiness' },
        { value: 0, type: 'number', label: this.props.language.labels.accountsLimit, name: 'accountsLimit' },
        { value: 0, type: 'number', label: this.props.language.labels.buildingsLimit, name: 'buildingsLimit' },
        { type: 'dropdownSelector', name: 'building', utils: this.props.language.labels.building, value: '', options: [] },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        { type: 'dropdownSelector', name: 'official', utils: this.props.language.labels.official, value: '', options: [] },
        {
            type: 'fixedMultiSelect',
            utils: this.props.language.labels.service,
            name: 'services',
            options: [],
            value: []
        }
    ]

    state = {
        companyFields: this.props.superAdmin ? this.initialCompanyFields.filter(field => field.name === 'cui' || field.name === 'name' || field.name === 'mainBusiness' || field.name === 'building' || field.name === 'type' || field.name === 'accountsLimit' || field.name === 'companiesLimit' || field.name === 'buildingsLimit') : this.initialCompanyFields,
        isContractor: false,
        waitForServices: false,
        cui: ''
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !nextProps.edit) {
            this.populateFields()
        }

        if (nextProps.edit && nextProps.companyId) {
            this.populateCompany(nextProps.companyId)
        }
    }

    modifyCompanyFields = (fields, toEdit, newValues) => {
        const companyCopy = this.state.companyFields.map(field => ({ ...field }))
        fields.forEach((field, position) => {
            const fieldIndex = companyCopy.findIndex(index => index.name === field)
            if (fieldIndex > -1) {
                companyCopy[fieldIndex][toEdit[position]] = newValues[position]
            }
        })

        this.setState({ companyFields: companyCopy })
    }
    getTags = type => this.props.getTags(type)
        .then(data => data)

    getBuildings = () => this.props.getBuildings()
        .then(data => data.buildings)

    getUsers = () => this.props.getUsers()
        .then(data => data.users)

    populateFields = async () => {
        if (!this.props.superAdmin) {
            let companyTypes = await this.getTags('company')
            //let servicesTypes = await this.getTags('services')
            let allBuildings = await this.getBuildings({ isRoot: true })
            const types = [{ name: '', value: false, label: '' }].concat([...companyTypes.map(type => ({
                name: type.name,
                value: false,
                label: type.name
            }))])
            const buildings = [{ name: '', value: false, label: '' }].concat(allBuildings.map(building => ({
                name: building._id,
                value: false,
                label: building.name
            })))


            let allUsers = await this.getUsers()

            const users = [{ name: '', value: false, label: '' }].concat(allUsers.map(user => ({
                name: user._id,
                value: false,
                label: `${user.firstName} ${user.lastName}`
            })))



            // const serviceTypes = [{ name: '', value: false, label: '' }].concat([...servicesTypes.map(type => ({
            //     name: type.name,
            //     value: false,
            //     label: type.name
            // }))])

            this.modifyCompanyFields(['building', 'type', 'official'], ['options', 'options', 'options'], [buildings, types, users])
        }

    }

    populateCompany = async (companyId) => {
        let company = await this.props.getCompany(companyId)
        let newFields = [...this.state.companyFields].map(obj => ({ ...obj }))

        Object.keys(company).forEach(key => {
            const currentIndex = newFields.findIndex(index => index.name === key)

            if (currentIndex > -1) {
                newFields[currentIndex].value = company[key]
            }

        })

        this.setState({ companyFields: newFields })
    }

    InputWrapper = ({ disabled, input, key, shrink, onChange, label }) => <div>
        <InputGenerator
            key={key}
            disabled={disabled}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            label={label ? label : null}
            {...input}
            onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
        />
    </div>

    onChangeHandler = async event => {
        const currentIndex = this.state.companyFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            const companyFieldsCopy = this.state.companyFields.map(field => ({ ...field }))

            const buildingIndex = companyFieldsCopy.findIndex(index => index.name === 'building')
            const servicesIndex = companyFieldsCopy.findIndex(index => index.name === 'services')

            if (event.target.name === 'type') {

                if (companyFieldsCopy[buildingIndex].value === '' && event.target.value.toLowerCase() === CONSTANTS.COMPANY_TYPES.CONTRACTOR.toLowerCase()) {
                    return NOTIFICATIONS.error(this.props.language.utils.chooseBuilding)
                }

                let isContractor = event.target.value.toLocaleLowerCase() === CONSTANTS.COMPANY_TYPES.CONTRACTOR.toLocaleLowerCase()
                if (isContractor) {
                    this.setState({ waitForServices: true }, () => {
                        this.props.getBuildingServices(companyFieldsCopy[buildingIndex].value).then(buildingServices => {
                            const serviceTypes = [{ name: '', value: false, label: '' }].concat([...buildingServices.services.map(service => ({
                                name: service,
                                value: false,
                                label: service
                            }))])
                            companyFieldsCopy[servicesIndex].options = serviceTypes
                            companyFieldsCopy[servicesIndex].defaultValue = serviceTypes.map(service => ({ ...service, value: true })).filter(service => service.name)
                            companyFieldsCopy[servicesIndex].value = serviceTypes.map(service => ({ ...service, value: true })).filter(service => service.name)
                            this.setState({ isContractor: true, waitForServices: false })
                        })
                            .catch(() => {
                                this.setState({ waitForServices: false })
                            })
                    })
                }
                else {
                    this.setState({ isContractor: false })
                }
            }

            if (event.target.name === 'cui')
                this.state.cui = event.target.value

            companyFieldsCopy[currentIndex].value = event.target.value
            this.setState({ companyFields: companyFieldsCopy })
        }
    }

    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ companyFields: this.initialCompanyFields })
    }

    onAcceptHandler = () => {
        let jsonMap = {}
        let isOk = true
        this.state.companyFields.forEach(field => {
            jsonMap[field.name] = field
        })

        let fieldsCopy = [...this.state.companyFields].map(field => ({ ...field }))

        fieldsCopy.forEach(field => {
            field.error = false
            switch (field.name) {
                case 'name':
                    if (validator.isEmpty(jsonMap['name'].value)) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'mainBusiness':
                    if (validator.isEmpty(jsonMap['mainBusiness'].value)) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'building':
                    if (validator.isEmpty(jsonMap['building'].value) && this.props.loginReducer.role !== CONSTANTS.SUPER_ADMIN) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'type':
                    if (validator.isEmpty(jsonMap['type'].value) && this.props.loginReducer.role !== CONSTANTS.SUPER_ADMIN) {
                        field.error = true
                        isOk = false
                    }
                    break
                default:
                    break
            }
        })
        this.setState({ companyFields: fieldsCopy })

        if (!isOk) {
            return NOTIFICATIONS.error(this.props.language.toastr.company.errors)
        }

        const companyToAdd = !this.props.superAdmin ? {
            name: jsonMap['name'].value,
            services: this.state.isContractor ? jsonMap['services'].value.map(service => service.name) : null,
            type: jsonMap['type'].value,
            building: jsonMap['building'].value,
            official: jsonMap['official'].value,
            mainBusiness: jsonMap['mainBusiness'].value,
            cui: jsonMap['cui'] ? jsonMap['cui'].value : '',
            isRoot: false
        } : {
            name: jsonMap['name'].value,
            mainBusiness: jsonMap['mainBusiness'].value,
            accountsLimit: jsonMap['accountsLimit'].value,
            buildingsLimit: jsonMap['buildingsLimit'].value,
            cui: jsonMap['cui'] ? jsonMap['cui'].value : '',
            isRoot: true
        }


        this.props.create({ company: companyToAdd }).then(() => {
            this.props.onClose()
            this.setState({ companyFields: this.initialCompanyFields })
            NOTIFICATIONS.success(this.props.language.toastr.company.successCreate)
        })
            .catch((err) => {
                err.message.includes(CONSTANTS.LIMIT_REACHED_CODE) ?
                    NOTIFICATIONS.error(this.props.language.toastr.company.companiesLimitReached) :
                    NOTIFICATIONS.error(this.props.language.toastr.company.errorCreate)
            })
    }

    onEditHandler = () => {
        let jsonMap = {}
        this.state.companyFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const companyToEdit = {
            name: jsonMap['name'].value,
            mainBusiness: jsonMap['mainBusiness'].value,
            accountsLimit: jsonMap['accountsLimit'].value,
            buildingsLimit: jsonMap['buildingsLimit'].value,
            cui: jsonMap['cui'] ? jsonMap['cui'].value : '',
            isRoot: true
        }

        this.props.editCompany(this.props.companyId, companyToEdit).then(() => {
            this.props.onClose()
            this.setState({ companyFields: this.initialCompanyFields })
            NOTIFICATIONS.success(this.props.language.toastr.company.successEdit)
        })
            .catch(() => NOTIFICATIONS.error(this.props.language.toastr.company.errorEdit))
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let { classes } = this.props
        let jsonMap = {}
        this.state.companyFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.flexColumn}>
                <div className={this.props.classes.flexRow}>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['cui']} key={'cui'} />
                    </div>
                    <div className={this.props.classes.anafButton}>
                        <AnafButton
                            cui={this.state.cui}
                            onAnafButtonClicked={this.onAnafButtonClickedHandler}
                        />
                    </div>
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['name']} key={'name'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['mainBusiness']} key={'mainBusiness'} />
                </div>
                {this.props.superAdmin && <>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['accountsLimit']} key={'accountsLimit'} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['buildingsLimit']} key={'buildingsLimit'} />
                    </div>
                </>}
                {!this.props.superAdmin ? <>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['building']} key={'building'} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['type']} key={'type'} />
                    </div>
                    {<div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['official']} key={'official'} />
                    </div>}
                    {this.state.isContractor && <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        {this.state.waitForServices ? <div className={this.props.classes.serviceContainer}>
                            <CircularProgress className={this.props.circularProgress} />
                            <span className={this.props.classes.infoText}>{this.props.language.utils.waitServices}</span>
                        </div> :
                            < InputWrapper disabled={this.state.waitForServices} shrink={true} input={jsonMap['services']} key={'services'} />}
                    </div>}
                    {this.props.open && <div className={`${this.props.classes.flexRo} ${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <div className={classes.flexRow}>
                            <InformIcon className={classes.informIcon} />
                            <div className={classes.importantTextContainer}>
                                <span className={classes.importantText}>{this.props.language.titles.important}</span>
                            </div>
                        </div>
                        <p className={classes.infoText}>{this.props.language.utils.companyWarning}</p>
                    </div>}
                </> : null}

            </div>
        )
    }

    onAnafButtonClickedHandler = anafData => {
        let indexOfName = this.state.companyFields.findIndex(field => field.name == 'name')

        if (indexOfName < 0) {
            return
        }

        let newFields = [...this.state.companyFields]

        newFields[indexOfName].value = anafData.denumire || ''

        this.setState({ companyFields: newFields })
    }

    render() {

        return <>
            <SimpleModal
                open={this.props.open}
                onCancel={() => this.onCloseHandler()}
                onAccept={() => this.props.edit ? this.onEditHandler() : this.onAcceptHandler()}
                acceptButtonText={this.props.edit ? this.props.language.buttons.edit : this.props.language.buttons.create}
                cancelButtonText={this.props.language.buttons.cancel}
                canSubmitForm={true}
                maxWidth={'sm'}
                title={this.props.edit ? this.props.language.titles.editCompany : this.props.language.titles.createCompany}
            >
                <>
                    {this.renderFields()}
                </>
            </SimpleModal>

        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    create: company => dispatch(COMPANY_REQUESTS.create(company)),
    editCompany: (id, newCompany) => dispatch(COMPANY_REQUESTS.edit(id, newCompany)),
    getCompany: (id) => dispatch(COMPANY_REQUESTS.getById(id)),
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    getUsers: () => dispatch(USERS_REQUESTS.get({ all: true })),
    getBuildings: (query = {}) => dispatch(BUILDING_REQUESTS.get({ all: true, ...query })),
    getBuildingServices: (id) => dispatch(BUILDING_REQUESTS.getBuildingServices(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateCompanyModal))