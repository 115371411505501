import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../../utils/constants'
import * as TICKET_REQUESTS from '../../../../redux/actions/tickets'
import * as COMPANY_REQUESTS from '../../../../redux/actions/companies'
import * as NOTIFICATIONS from '../../../../utils/notification'

import AddUserModal from './AddUserModal'
import SimpleTable from '../../../common/SimpleTable'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        display: 'flex',
        margin: '0px 20px',
        flexDirection: 'column',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 130px)'
    },
    button: {
        backgroundColor: '#00458B',
        marginRight: '10px',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
        color: 'white',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: '#00458B',
            opacity: 0.75
        }
    }
})


class CompanyUsers extends Component {

    state = {
        count: 0,
        from: 0,
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        rows: [],
        allRows: [],
        createUserModal: false
    }

    componentDidMount() {
        this.mapUsers(this.props.company)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users !== this.props.users || prevProps.company !== this.props.company) {
            this.setState({
                count: this.props.users.length,
                rows: this.props.users.map((user, index) => ({
                    index: index + 1,
                    _id: user._id,
                    name: `${user.firstName} ${user.lastName}`,
                    phone: user.phoneNumber,
                    email: user.email,
                    title: user.title
                })),
                allRows: this.props.users.map((user, index) => ({
                    index: index + 1,
                    _id: user._id,
                    name: `${user.firstName} ${user.lastName}`,
                    phone: user.phoneNumber,
                    email: user.email,
                    title: user.title
                }))
            }, this.setUsers)
        }
    }

    onSearch = search => {
        let usersCopy = this.state.allRows.map(field => ({ ...field }))
        usersCopy = usersCopy.filter(user => user.name.includes(search) || user.email.includes(search))
        usersCopy = usersCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: usersCopy })
    }

    mapUsers = company => {
        this.setState({
            count: this.props.users.length,
            rows: this.props.users.map((user, index) => ({
                index: index + 1,
                _id: user._id,
                name: `${user.firstName} ${user.lastName}`,
                phone: user.phoneNumber,
                email: user.email,
                title: user.title
            })),
            allRows: this.props.users.map((user, index) => ({
                index: index + 1,
                _id: user._id,
                name: `${user.firstName} ${user.lastName}`,
                phone: user.phoneNumber,
                email: user.email,
                title: user.title
            }))
        }, this.setUsers)
    }

    setUsers = () => {
        let usersCopy = this.state.allRows.map(field => ({ ...field }))
        usersCopy = usersCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: usersCopy })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.setUsers)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.setUsers)
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <AddUserModal
                    open={this.state.createUserModal}
                    getCompany={this.props.getCompany}
                    getBuilding={this.props.getBuilding}
                    company={this.props.company}
                    building={this.props.building}
                    close={() => this.setState({ createUserModal: false })}
                />
                <div className={classes.contentContainer}>
                    <SimpleTable
                        title={this.props.language.titles.usersCompanyTable}
                        header={this.props.language.tableHeaders.usersCompany}
                        rows={this.state.rows}
                        onClickRow={item => { }}
                        headerButton={{
                            tooltip: this.props.language.buttons.add,
                            onClick: (event) => {
                                this.setState({ createUserModal: true })
                            },
                            icon: <Button color="primary" variant="contained" className={classes.button} >
                                {this.props.language.buttons.add}
                            </Button>
                        }}
                        search={{
                            onSearch: this.onSearch
                        }}
                        actions={[
                            {
                                tooltip: this.props.language.buttons.deleteUser,
                                onClick: (_, user) => {
                                    let confirmed = window.confirm(this.props.language.buttons.confirmDeleteUser)

                                    if (!confirmed) return

                                    return this.props.deleteUserFromCompany(this.props.company._id, this.props.building._id, user._id)
                                        .then(() => {
                                            this.props.getBuilding()
                                            return NOTIFICATIONS.success(this.props.language.toastr.user.list.actions.deleteSuccess)
                                        })
                                        .catch(() => NOTIFICATIONS.error(this.props.language.toastr.user.list.actions.deleteError))
                                },
                                icon: <DeleteIcon />
                            }
                        ]}
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        hasNoMargin={true}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    createComment: (ticketId, comment) => dispatch(TICKET_REQUESTS.createComment(ticketId, comment)),
    deleteUserFromCompany: (companyId, buildingId, userId) => dispatch(COMPANY_REQUESTS.deleteUserFromCompany(companyId, buildingId, userId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyUsers))