import React, { Component } from "react";
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

import { GetApp as DownloadIcon } from '@material-ui/icons'
import Table from '../../common/SimpleTable'

import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import { DEFAULT_DATE_TIME_FORMAT } from '../../../utils/constants'

const styles = theme => ({
  filesContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '1px'
  }
})

class List extends Component {

  render() {
    return (
      <div className={this.props.classes.filesContainer}>
        <Table
          header={this.props.language.tableHeaders.parentCompanyInvoices}
          rows={this.props.invoices.map((file, index) => ({ index: index + 1, name: file.file.originalName, createdAt: moment(file.file.createdAt).format(DEFAULT_DATE_TIME_FORMAT), totalValue: file.value, description: file.description}))}
          inInvoice={true}
          hasNoMargin={true}
          actions={[
            {
              tooltip: this.props.language.buttons.download,          
              onClick: (_, file) => {
                this.props.download(this.props.loginReducer.parentCompany, this.props.invoices[file.index - 1]._id, file.name).then(() =>{
                  this.props.getInvoices(this.props.id)
                })
                
              },
              icon: <DownloadIcon />
            }
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = reducers => ({
  language: reducers.languageReducer.i18n,
  loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
  download: (companyId, invoiceId, filename) => dispatch(COMPANY_REQUESTS.downloadParentCompanyInvoice(companyId, invoiceId, filename))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(List));
