import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from "@material-ui/core"

import Users from './users/Users'
import Editable from './editable/Editable'

import * as TEAM_REQUESTS from '../../../redux/actions/teams'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    editableContainer: {
        height: '100%',
        flex: 1,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    usersContainer: {
        height: '100%',
        flex: 3
    }
})

class TeamDetails extends Component {

    state = {
        team: null
    }

    getTeam = () => {
        return this.props.getById(this.props.match.params.ID)
            .then(result => {
                this.setState({ team: result })
                return result
            })
    }

    componentDidMount = () => {
        this.getTeam()
    }

    render() {
        let { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.editableContainer}>
                    {this.state.team && <Editable
                        team={this.state.team}
                        getTeam={this.getTeam}
                    />}
                </div>
                <div className={classes.usersContainer}>
                    {this.state.team && <Users
                        team={this.state.team}
                        count={this.state.team.members.length}
                        getTeam={this.getTeam} />
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    getById: id => dispatch(TEAM_REQUESTS.getById(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeamDetails))