import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardActions } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        minWidth: 350,
        maxHeight: 500
    }
});

export default props => {
    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={props.onClick}>
            <CardActionArea>
                {props.children}
            </CardActionArea>
            {props.actions && <CardActions>
                {props.actions}
            </CardActions>}
        </Card>
    );
}