import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import FileSaver from 'file-saver'

import Header from '../common/Header'

import * as CONSTANTS from '../../utils/constants'

class Support extends Component {
    render() {
        let { classes } = this.props

        return (
            <div className={classes.headerContainer}>
                <div className={classes.header}>
                    <Header
                        simple
                        title={this.props.language.sidebar.support}
                        profileText={this.props.language.headerTooltip.profile}
                    />
                 </div>
                <div className={classes.container}>
                    <div className={classes.manualDownloadContainer}>
                        <h3>{this.props.language.manualTitle}</h3>
                        <div className={classes.divider}></div>
                        <p className={classes.bottomNone}>{this.props.language.manualSubtitle}</p>
                        <p className={classes.download} onClick={() => {
                            FileSaver.saveAs(`${CONSTANTS.BACKEND_URL}/manual/manual.pdf`, `manual_solvvo.pdf`)
                        }}>{this.props.language.manualDownload}</p>
                    </div>
                    <div className={classes.faqContainer}>
                        <h3>{this.props.language.faqTitle}</h3>
                        <div className={classes.divider}></div>
                        {this.props.language.faq.map((obj, index) => {
                            return (
                                <div className={classes.faqQuestion}>
                                    <p className={classes.bottomNone}>{index + 1}. {obj.question}</p>
                                    <span className={classes.answer}>{obj.answer}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        width: '100%',
        height: 'calc(100% - 70px)',
        overflow: 'auto',
        padding: '8px 30px',
        boxSizing: 'border-box'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    headerContainer: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    download: {
        cursor: 'pointer'
    },
    manualDownloadContainer: {
        width: '100%'
    },
    bottomNone: {
        paddingBottom: 0,
        marginBottom: 0,
        fontWeight: 500
    },
    divider: {
        width: '100%',
        border: '1px solid rgba(0,0,0,0.1)',
        margin: '20px 0px'
    },
    faqContainer: {
        width: 'calc(100% - 216px)'
    },
    faqQuestion: {
        paddingBottom: 12
    },
    answer: {
        fontSize: 13
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Support))