import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Paper } from '@material-ui/core'
import {
    Delete as DeleteIcon,
} from '@material-ui/icons'


const styles = theme => ({
    container: {
        width: 'calc(100% - 60px)',
        display: 'flex',
        flexDirection: 'column'
    },
    tableContainer: {
        margin: 30,
        boxSizing: 'border-box',
        border: '1px solid #e0e0e0'
    },
    actionsCell: {
        width: 100,
        textAlign: 'center'
    }
})

class CompanyList extends React.Component {

    render() {
        let { classes, language } = this.props

        return (
            <div className={classes.container}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{language.labels.company}</TableCell>
                                <TableCell>{language.labels.mainBusiness}</TableCell>
                                <TableCell>{language.labels.accountsLimit}</TableCell>
                                <TableCell>{language.labels.buildingsLimit}</TableCell>
                                <TableCell>{language.labels.companiesLimit}</TableCell>
                                <TableCell className={classes.actionsCell}>Actiuni</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.companies.map((row) => (
                                <TableRow key={row.name} >
                                    <TableCell component="th" scope="row" onClick={() => this.props.onClick(row._id)}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={() => this.props.onClick(row._id)}>
                                        {row.mainBusiness}
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={() => this.props.onClick(row._id)}>
                                        {row.accountsLimit}
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={() => this.props.onClick(row._id)}>
                                        {row.buildingsLimit}
                                    </TableCell>
                                    <TableCell component="th" scope="row" onClick={() => this.props.onClick(row._id)}>
                                        {row.companiesLimit}
                                    </TableCell>
                                    <TableCell className={classes.actionsCell} align="center"><DeleteIcon onClick={() => this.props.onDelete(row._id)} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyList)))