import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const PriorityLineChart = ({ data, yTitle, xTitle, language }) => {
    return (
        <ResponsiveBar
            data={data}
            keys={[
                language.priorityChart.ticketMinor,
                language.priorityChart.ticketNormal,
                language.priorityChart.ticketUrgent
            ]}
            indexBy="day"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            colors={['#6fbf73', '#64b5f6', '#ef5350']}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            fill={[
                {
                    match: {
                        id: 'Minor'
                    },
                    color: 'red'
                }
            ]}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: language.priorityChart.day,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: language.priorityChart.ticketsNr,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
    )
}

export default PriorityLineChart