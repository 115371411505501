import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, CircularProgress } from '@material-ui/core'

import CardContainer from '../../../common/CardContainer'

let useStyles = makeStyles({
    container: {
        width: 700,
        height: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginLeft: 30,
        marginRight: 30,
  
    },
    title: {
        color: '#4D8FDC',
        fontWeight: 800
    },
    textAlignContainer: {
        textAlign :'center'
    }
})

let DisplayMessage = props => {
    let classes = useStyles()
    let container = props.button ? `${classes.container} ${classes.textAlignContainer}` : classes.container 


    return (
        <CardContainer>
            <div className={container}>
                <Typography variant="h3" gutterBottom className={classes.title}>
                    {props.title}
                 </Typography>
                 <Typography variant="h5" gutterBottom>
                    {props.message}
                 </Typography>
                 {props.button ? <Button
                                className='loginButton'
                                variant='contained'
                                onClick={props.onClick} color='primary'>
                                login
                            </Button> : null}
            </div>
        </CardContainer>
    )
}

export default DisplayMessage