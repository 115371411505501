import React from 'react'
import { withStyles, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import TabBar from './tabBar/TabBar'
import Header from '../../components/common/Header'
import BuildingCard from './buildingCard/BuildingCard'
import Reports from './reports/Reports'
import LineChart from './lineChart/LineChart'

import * as CONSTANTS from '../../utils/constants'
import * as REPORTS_REQUESTS from '../../redux/actions/reports'
import * as TICKET_ACTIONS from '../../redux/actions/tickets'

const styles = theme => ({
    container: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    content: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        justifyContent: 'center',
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    chartsContent: {
        marginTop: 20,
        width: '97%',
        height: 'calc(100% - 40px)'
    },
    contentHeader: {
        marginBottom: 6
    },
    contentCharts: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        flexDirection: 'column'
    },
    buildings: {
        width: '100%',
        height: 145,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        overflow: 'auto',
        flexDirection: 'row'
    },
    tickets: {
        width: '100%',
        height: 80,
        marginBottom: 10,
        marginTop: 5,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        borderRadius: 4
    },
    lineChart: {
        width: '100%',
        height: 'calc(100% - 240px)'
    },
    progress: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    buildingCard: {
        marginRight: 10,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        borderRadius: 4
    }
})

let Charts = props => {
    let { classes, language } = props
    let [buildings, setBuildings] = React.useState([])
    let [ticketsCount, setTicketsCount] = React.useState({})
    let [reports, setReports] = React.useState()
    let [loading, setLoading] = React.useState()

    let getBuildings = () => props.getBuildings()
        .then(result => setBuildings(result.buildings.map(building => ({ ...building, checked: false }))))

    let getReports = buildings => {
        setLoading(true)
        props.getReports(buildings)
            .then(result => {
                let reportsData = {}
                let ticketsCount = result.ticketsLastDays

                setTicketsCount({
                    total: ticketsCount.all,
                    verify: ticketsCount.verify,
                    pending: ticketsCount.pending,
                    newlyOpened: ticketsCount.neww,
                    closed: ticketsCount.closed
                })

                reportsData.ticketsCount = {
                    total: ticketsCount.all,
                    verify: ticketsCount.verify,
                    pending: ticketsCount.pending,
                    newlyOpened: ticketsCount.neww,
                    closed: ticketsCount.closed
                }

                let buildingTicketsCount = result.buildingTicketsCount
                let buildingTickets = []

                Object.keys(buildingTicketsCount).forEach(key => {
                    buildingTickets.push({ id: key, label: key, value: buildingTicketsCount[key] })
                })

                reportsData.buildingTickets = buildingTickets

                let ticketDaysData = result.linearChart

                // For priorities
                let priorityTickets = []

                // For linechart
                let newTickets = {
                    id: props.language.chartsTicketStatuses.new,
                    data: []
                }
                let pendingTickets = {
                    id: props.language.chartsTicketStatuses.pending,
                    data: []
                }
                let verifyTickets = {
                    id: props.language.chartsTicketStatuses.verify,
                    data: []
                }
                let closedTickets = {
                    id: props.language.chartsTicketStatuses.closed,
                    data: []
                }

                let totalTickets = {
                    id: props.language.chartsTicketStatuses.total,
                    data: []
                }

                Object.keys(ticketDaysData).forEach((key, i) => {
                    let day = ticketDaysData[key].day
                    let pending = ticketDaysData[key].data.statuses.pending
                    let closed = ticketDaysData[key].data.statuses.closed
                    let opened = ticketDaysData[key].data.statuses.neww
                    let verify = ticketDaysData[key].data.statuses.verify

                    newTickets.data.push({
                        x: day,
                        y: opened
                    })

                    pendingTickets.data.push({
                        x: day,
                        y: pending
                    })

                    closedTickets.data.push({
                        x: day,
                        y: closed
                    })

                    verifyTickets.data.push({
                        x: day,
                        y: verify
                    })

                    totalTickets.data.push(
                        {
                            x: day,
                            y: opened + verify + pending
                        })


                    let minor = ticketDaysData[key].data.priorities.minor
                    let normal = ticketDaysData[key].data.priorities.normal
                    let urgent = ticketDaysData[key].data.priorities.urgent
                    let priorities = [{ minor }, { urgent }, { normal }].sort((a, b) => b[Object.keys(a)[0]] - a[Object.keys(a)[0]])

                    priorityTickets.push({
                        day,
                        Minor: minor,
                        Normal: normal,
                        Urgent: urgent,
                    })
                })


                newTickets.data.reverse()
                pendingTickets.data.reverse()
                closedTickets.data.reverse()
                verifyTickets.data.reverse()
                totalTickets.data.reverse()
                priorityTickets.reverse()

                reportsData.priorities = priorityTickets
                reportsData.ticketsStatusesPerDays = [{ ...closedTickets }, { ...verifyTickets }, { ...pendingTickets }, { ...newTickets }, { ...totalTickets }]

                let servicesTicketsCount = Object.keys(result.services).map(key => ({ id: key, label: key, value: result.services[key] }))

                reportsData.services = servicesTicketsCount

                setReports({ ...reportsData })
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }

    React.useEffect(() => {
        getBuildings()
        getReports(buildings)

        return () => { }
    }, [])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header
                    simple
                    title={props.language.titles.dashboard}
                    profileText={props.language.headerTooltip.profile}
                />
            </div>
            <div className={classes.content}>
                <div className={classes.chartsContent}>
                    <div className={classes.contentHeader}>
                        <TabBar
                            tabs={[{ name: props.language.tabs.buildings }]}
                        />
                    </div>
                    <div className={classes.contentCharts}>
                        <div className={classes.buildings}>
                            {buildings.map(building => <div className={classes.buildingCard}>
                                <BuildingCard
                                    onClick={() => {
                                        let buildingsCopy = [...buildings]

                                        let indexOfBuilding = buildingsCopy.findIndex(b => b._id === building._id)

                                        if (indexOfBuilding < 0) return

                                        buildingsCopy[indexOfBuilding].checked = !buildingsCopy[indexOfBuilding].checked

                                        getReports(buildingsCopy.filter(building => building.checked).map(building => building._id))
                                        setBuildings(buildingsCopy)
                                    }}
                                    image={building.image ? `${CONSTANTS.BACKEND_URL}/static/${building.image.path}` : null}
                                    name={building.name}
                                    checked={building.checked}
                                />
                            </div>)}
                        </div>
                        <div className={classes.tickets}>
                            <Reports
                                total={ticketsCount.total}
                                totalTitle={props.language.chartsTicketStatuses.total}
                                onTotalClicked={() => {
                                    props.setTicketType('all').then(() => props.history.push('/tickets'))
                                }}

                                verify={ticketsCount.verify}
                                verifyTitle={props.language.chartsTicketStatuses.verify}
                                onVerifyClicked={() => {
                                    props.setTicketType('ticketVerify').then(() => props.history.push('/tickets/verify'))
                                }}

                                pending={ticketsCount.pending}
                                pendingTitle={props.language.chartsTicketStatuses.pending}
                                onPendingClicked={() => {
                                    props.setTicketType('ticketPending').then(() => props.history.push('/tickets/pending'))
                                }}

                                newlyOpened={ticketsCount.newlyOpened}
                                onOpenedClicked={() => {
                                    props.setTicketType('ticketNew').then(() => props.history.push('/tickets/new'))
                                }}
                                newTitle={props.language.chartsTicketStatuses.new}

                                closed={ticketsCount.closed}
                                onClosedClicked={() => {
                                    props.setTicketType('ticketClosed').then(() => props.history.push('/tickets/closed'))
                                }}
                                closedTitle={props.language.chartsTicketStatuses.closed}
                            />
                        </div>
                        {!loading ? <div className={classes.lineChart}>
                            <LineChart
                                data={reports}
                            />
                        </div> : <div className={classes.progress}><CircularProgress /></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getReports: buildings => dispatch(REPORTS_REQUESTS.get(buildings)),
    getBuildings: () => dispatch(REPORTS_REQUESTS.getBuildings()),
    setTicketType: ticketType => dispatch(TICKET_ACTIONS.setTicketType(ticketType))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Charts)))