import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import Header from '../common/Header'
import Cards from './cards/Cards'
import CreateTeamModal from './modals/CreateTeam'
import ConfirmationDialog from '../common/ConfirmationDialog'
import Pagination from '../common/Pagination'

import * as TEAM_REQUESTS from '../../redux/actions/teams'
import * as NOTIFICATION from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        height: 70
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 80px)',
        overflow: 'auto'
    },
    pagination: {
        justifyContent: 'flex-end'
    }
})

class Teams extends React.Component {

    state = {
        openModal: false,
        openDeleteModal: false,
        count: 0,
        from: 0,
        teams: [],
        teamToDelete: null,
        rowsPerPage: CONSTANTS.DEFAULT_CARD_ROWS_PER_PAGE_VALUE
    }

    componentDidMount() {
        this.getTeamsHandler()
    }

    getTeamsHandler = filter => {
        return this.props.get({
            from: this.state.from,
            search: filter,
            limit: this.state.rowsPerPage
        })
            .then(({ teams, count }) => {
                this.setState({
                    count,
                    teams
                })
            })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.getTeamsHandler)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.getTeamsHandler)
    }

    onSearchHandler = filter => {
        this.getTeamsHandler(filter)
    }

    onEditHandler = team => {
        this.props.history.push(`/teams/${team._id}`)
    }

    onDeleteHandler = () => {
        this.props.delete(this.state.teamToDelete._id).then(() => {
            this.setState({ openDeleteModal: false })
            this.getTeamsHandler()
            NOTIFICATION.success(this.props.language.toastr.team.successDelete)
        })
            .catch(() => NOTIFICATION.error(this.props.language.toastr.errorDelete))
    }

    onCloseHandler = () => {
        this.getTeamsHandler()
        this.setState({ openModal: false })
    }

    openDeleteModal = (team) => {
        this.setState({ openDeleteModal: true, teamToDelete: team })
    }

    closeDeleteModal = () => {
        this.setState({ openDeleteModal: false })
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Header
                        title={this.props.language.titles.teams}
                        buttonText={this.props.language.buttons.create}
                        onSearch={filter => this.onSearchHandler(filter)}
                        onClick={() => this.setState({ openModal: true })}
                    />
                </div>
                <div className={classes.cardsContainer}>
                    <ConfirmationDialog
                        open={this.state.openDeleteModal}
                        cancelButtonText={this.props.language.buttons.cancel}
                        acceptButtonText={this.props.language.buttons.delete}
                        text={`${this.props.language.utils.delete}`}
                        onCancel={() => this.setState({ openDeleteModal: false })}
                        onClose={() => this.setState({ openDeleteModal: false })}
                        onAccept={this.onDeleteHandler}
                    />
                    <CreateTeamModal
                        open={this.state.openModal}
                        onClose={() => this.onCloseHandler()}
                    />
                    <Cards
                        onDelete={team => {
                            this.openDeleteModal(team)
                        }}
                        onEdit={(team) => this.onEditHandler(team)}
                        teams={this.state.teams}
                    />

                    <Pagination
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />

                </div>

            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(TEAM_REQUESTS.get(options)),
    delete: teamId => dispatch(TEAM_REQUESTS.remove(teamId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Teams)))