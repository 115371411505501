import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const TAG_ROUTE = '/tags'

export const getAll = query => dispatch => axios.get(`${TAG_ROUTE}?type=${query ? query.type || '' : ''}`)
    .then(response => response.data)
    .catch(errorHandler)

export const create = data => dispatch => axios.post(TAG_ROUTE, { tag: { ...data } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${TAG_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)