import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, TextField, InputAdornment, Tooltip, Badge } from '@material-ui/core'
import { Search as SearchIcon, Add as AddIcon, ImportExport as ExportIcon, PowerSettingsNew as LogoutIcon, EventNote as CalendarIcon, RestoreOutlined } from '@material-ui/icons'

import EditUserModal from '../../components/navbar/EditUserModal/EditUserModal'
import Form from './FormGenerator'
import Calendar from './Calendar'
import moment from 'moment'

import * as CONSTANTS from '../../utils/constants'
import * as TICKET_REQUESTS from '../../redux/actions/tickets'
import * as BUILDING_REQUESTS from '../../redux/actions/buildings'
import * as COMPANY_REQUESTS from '../../redux/actions/companies'
import * as LOGIN_REQUESTS from '../../redux/actions/login'

import * as NOTIFICATATIONS from '../../utils/notification'

let Header = props => {
    let { classes } = props

    let INITIAL_EXPORT_FIELDS = [
        {
            value: null,
            type: 'date',
            label: props.language.labels.export.from,
            name: 'from',
            InputLabelProps: {
                shrink: true
            }
        },
        {
            value: null,
            type: 'date',
            label: props.language.labels.export.until,
            name: 'until',
            InputLabelProps: {
                shrink: true
            }
        },
        {
            value: null,
            type: 'dropdownSelector',
            utils: props.language.labels.export.buildings,
            name: 'building',
            options: [],
            InputLabelProps: {
                shrink: true
            }
        },
        {
            value: null,
            type: 'dropdownSelector',
            utils: props.language.labels.export.contractorCompanies,
            name: 'company',
            options: [],
            InputLabelProps: {
                shrink: true
            }
        }
    ]

    let [search, setSearch] = useState('')
    let [user, setUser] = useState()
    let [showEditUserModal, setShowEditUserModal] = useState(false)
    let [showExportModal, setShowExportModal] = useState(false)
    let [showCalendar, setShowCalendar] = useState(false)
    let [done, setDone] = useState(false)
    let [exportFields, setExportFields] = useState(INITIAL_EXPORT_FIELDS)
    let [notificationsNo, setNotificationsNo] = useState(0)

    let onInputSearch = text => {
        setSearch(text)
        props.onSearch(text)
    }

    let logout = () => {
        if (window.confirm(props.language.login.logout)) {
            props.logout()
        }
    }

    useEffect(() => {
        setUser(props.profile)
    }, [props.profile])

    useEffect(() => {
        if (props.profile.role !== 'superAdmin') {
            getNotifications()
            // Populate
            Promise.all([
                props.getBuildings(),
                props.getContractorCompanies(),
            ]).then(result => {
                let newExportFields = [...exportFields]

                //Buildings response
                let buildings = result[0].buildings

                let indexOfBuilding = newExportFields.findIndex(field => field.name === 'building')

                if (indexOfBuilding < 0) return

                newExportFields[indexOfBuilding].options = [{ value: '', name: '' }].concat(buildings.map(building => ({
                    value: building._id,
                    label: building.name,
                    name: building._id
                })))

                //Companies response
                let companies = result[1].companies

                let indexOfContractorCompany = newExportFields.findIndex(field => field.name === 'company')

                if (indexOfContractorCompany < 0) return

                newExportFields[indexOfContractorCompany].options = [{ value: '', name: '' }].concat(companies.map(company => ({
                    value: company._id,
                    label: company.name,
                    name: company._id
                })))

                setExportFields(newExportFields)
                setDone(true)
            })
        }

    }, [showExportModal])


    let getInitials = str => str.split(' ')
        .map(element => {
            if (element[0]) {
                return element[0].toUpperCase()
            }

            return ''
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue)

    let getNotifications = () => {
        if (props.profile.role === CONSTANTS.ROLES.PM || props.profile.role === CONSTANTS.ROLES.FM || props.profile.isOfficial) {
            let today = new Date()
            props.getNotifications(today).then(response => {
                setNotificationsNo(response.count)
            })
        }
    }


    return (
        <>
            {showEditUserModal && <EditUserModal
                open={showEditUserModal}
                onCancel={() => setShowEditUserModal(false)}
            />}
            {(showExportModal && done) && <Form
                open={showExportModal}
                title={props.language.titles.export}
                acceptButtonText={props.language.buttons.export}
                cancelButtonText={props.language.buttons.cancel}
                withCancelButton
                onAccept={data => {
                    props.exportTickets(data.from, data.until, data.building, data.company)
                        .then(() => {
                            NOTIFICATATIONS.success(props.language.toastr.export.success)
                            setShowExportModal(false)
                        })
                        .catch(() => {
                            NOTIFICATATIONS.error(props.language.toastr.export.error)
                            setShowExportModal(false)
                        })
                }}
                fields={exportFields}
                maxWidth='xs'
                onCancel={() => setShowExportModal(false)}
            />}
            {showCalendar && <Calendar
                open={showCalendar}
                onCancel={() => {
                    if(props.getTickets)
                        props.getTickets() 
                    setShowCalendar(false) }} 
                />}
            <div className={classes.container}>
                <div className={classes.title}>
                    {props.title}
                </div>
                <div className={classes.actions}>
                    <div className={classes.search}>
                        {!props.simple && <TextField
                            className={classes.searchInput}
                            placeholder={props.placeholderText}
                            value={search}
                            onChange={event => onInputSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon className={classes.searchIcon} color="secondary" />
                                    </InputAdornment>
                                ),
                                classes: {
                                    underline: classes.removeUnderline,
                                    input: classes.searchInputField
                                }
                            }}
                        />}
                    </div>
                    {((!props.simple || props.button) && !props.withoutAdd) && <div className={classes.addTicket}>
                        <Tooltip title={props.addText || ''} placement='bottom'>
                            <Button className={classes.button} onClick={props.onClick}>
                                <AddIcon className={classes.addIcon} />
                            </Button>
                        </Tooltip>
                    </div>}
                    {props.profile.isOfficial || props.profile.role === CONSTANTS.ROLES.FM || props.profile.role === CONSTANTS.ROLES.PM ?
                        <div>
                            <Tooltip title={props.language.tooltip.calendar} placement='bottom' className={classes.addTicket}>
                                <Button className={classes.button} onClick={() => setShowCalendar(true)} >
                                    <Badge badgeContent={props.notificationsNo ? props.notificationsNo : notificationsNo} color="primary">
                                        <CalendarIcon color="primary" className={classes.addIcon} />
                                    </Badge>
                                </Button>
                            </Tooltip>
                        </div> : null}

                    {props.export && props.profile.role !== 'user' && <div className={classes.addTicket}>
                        <Tooltip title={props.language.tooltip.exportTickets || ''} placement='bottom'>
                            <Button className={classes.button} onClick={() => setShowExportModal(true)}>
                                <ExportIcon className={classes.addIcon} />
                            </Button>
                        </Tooltip>
                    </div>}

                    <div>
                        <Tooltip title={props.profileText || ''} placement='bottom'>
                            <Button className={`${classes.button} ${classes.addTicket}`} onClick={() => setShowEditUserModal(true)}>
                                <div className={classes.profileImage} style={props.profile.profileImagePath ? { backgroundImage: `url(${CONSTANTS.BACKEND_URL}/static/${props.profile.profileImagePath})` } : {}}></div>
                                {user ? <div className={classes.profileText}>{getInitials(user.username)}</div> : <div className={classes.profileText}>-</div>}
                            </Button>
                        </Tooltip>
                    </div>


                    <div>
                        <Tooltip title={'Delogare'} placement='bottom'>
                            <Button className={classes.button} onClick={() => logout()}>
                                <LogoutIcon className={classes.addIcon} />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    )
}


let styles = theme => ({
    profileText: {
        fontSize: 15
    },
    addTicket: {
        marginRight: 30
    },
    profileImage: {
        height: 25,
        width: 25,
        marginRight: 10,
        backgroundColor: '#e0e0e0',
        borderRadius: 5,
        backgroundSize: 'cover'
    },
    removeUnderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        "&&&&:hover:before": {
            borderBottom: "none"
        }
    },
    searchInput: {
        width: '250px',
        marginRight: '27px',
        border: '1px solid rgba(0,0,0,0.15)',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        padding: '0px 6px',
        borderRadius: 4,
        backgroundColor: 'white'
    },
    searchInputField: {
        height: 25,
        paddingLeft: 16,
        fontWeight: 500
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    searchIcon: {
        fontSize: '21px'
    },
    container: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        height: '70px'
    },
    title: {
        fontSize: 24,
        fontWeight: 400,
        width: 800
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%'
    },
    button: {
        backgroundColor: '#ffffff',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        color: '#4D8FDC',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            opacity: 0.75
        }
    },
    search: {
        paddingRight: 15
    },
    addIcon: {
        fontSize: 26,
        color: '#4D8FDC'
    }
})

const mapStateToProps = reducers => ({
    profile: reducers.loginReducer,
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(LOGIN_REQUESTS.logout()),
    exportTickets: (from, until, building, company) => dispatch(TICKET_REQUESTS.generateExport(from, until, building, company)),
    getBuildings: () => dispatch(BUILDING_REQUESTS.get({ all: true })),
    getContractorCompanies: () => dispatch(COMPANY_REQUESTS.get({ contractor: true, all: true })),
    getNotifications: today => dispatch(TICKET_REQUESTS.getNotifications(today))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Header))