import React, { useEffect } from 'react'
import { makeStyles, List, ListItem, Divider, ListItemText, Typography } from '@material-ui/core'
import moment from 'moment'

import * as CONSTANTS from '../../../../utils/constants'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
    },
    inline: {
        display: 'inline',
    },
    valueText: {
        fontWeight: 'bold',
    }
}))

let Element = props => <ListItem alignItems="flex-start">
    <ListItemText
        primary={`Modificare ${props.language.historyComments.labels[props.modifiedField]}`}
        secondary={
            <React.Fragment>
                <Typography
                    component="span"
                    variant="body2"
                    className={props.classes.inline}
                    color="textPrimary"
                >
                    {props.modifiedBy + '\n'}
                </Typography>
                    a modificat la data de {props.modificationDate} cu <span className={props.classes.valueText}>{props.language.labels.status[props.modifiedFieldValue] ? props.language.labels.status[props.modifiedFieldValue] : props.modifiedFieldValue}</span>
            </React.Fragment>
        }
    />
</ListItem>

export default props => {
    const classes = useStyles()

    useEffect(() => {
    }, [props.items])

    return (
        <List className={classes.root}>
            {props.items.map(item => <>
                <Element
                    language={props.language}
                    classes={classes}
                    modifiedField={item.modifiedFields[0].name}
                    modifiedFieldValue={item.modifiedFields[0].value}
                    modifiedBy={item.updatedBy}
                    modificationDate={moment(item.updatedAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}
                />
                <Divider component="li" />
            </>)}
        </List>
    )
}