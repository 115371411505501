import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom'
import { withStyles, CardContent, Typography, CardMedia, IconButton, Menu, MenuItem } from '@material-ui/core'
import { People as PeopleIcon, MoreVert as MoreVertIcon } from '@material-ui/icons'

import CardWrapper from '../../common/CardWrapper'

import { BACKEND_URL } from '../../../utils/constants'

const styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    cardContainer: {
        padding: '18px',
        maxWidth: '350px',
        maxHeigth: '410px',
        minHeight: '390px'
    },
    disableBorder: {
        borderBottom: 'none'
    },
    title: {
        paddingBottom: 0,
        margin: 0,
        fontWeight: 'bold',
        color: '#3778C2',
        flex: 1
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10

    },
    userImagePicture: {
        borderRadius: '50%',
        width: '35px',
        height: '35px',
        backgroundSize: 'cover',
        marginTop: 5,
        marginRight: '8px'
    },
    userImageContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    surfaceTitle: {
        paddingTop: 20,
        fontWeight: 'bold',
        color: '#606771'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '6px'
    },
    userText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 400
    },
    marginUsers: {
        marginLeft: '5px'
    },
    cardHeader: {
        display: 'flex'
    },
    spanIcon: {
        justifySelf: 'end'
    },
    cardMinHeight: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '350px',
        maxHeigth: '410px',
        minHeight: '390px'
    },
    alignMembersBotton: {
        display: 'flex',
        flexDirection: 'column', flex: 1,
        justifyContent: 'flex-end'
    }
})

const MAX_HEIGHT = 48

class TeamCards extends React.Component {
    state = {
        isBlocking: false,
        anchorEl: null,
        selectedItem: null
    }

    componentDidMount() {
        this.setState({ isBlocking: false })
    }

    onSpanClick = () => {
        this.setState({ isBlocking: true })
    }

    handleClick = (event, team) => {
        this.setState({ anchorEl: event.currentTarget, selectedItem: team })
    }

    handleClose = () => {
        this.setState({ anchorEl: null, isBlocking: false })
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.cardsContainer}>
                    {this.props.teams.map(team => {
                        let teamLeaderImage = team.teamLeader && team.teamLeader.profileImage ? `${BACKEND_URL}/static/${team.teamLeader.profileImage.path}` : "https://vectorified.com/images/profile-icon-png-27.png"
                        return (
                            <div className={classes.cardContainer}>
                                <CardWrapper onClick={() => this.props.history.push(`/teams/${team._id}`)}>
                                    <CardContent className={`${classes.disableBorder} ${classes.cardMinHeight}`}>
                                        <div className={classes.cardHeader}>
                                            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                                                {team.name}
                                            </Typography>

                                            <div>
                                                <Prompt
                                                    when={this.state.isBlocking}
                                                />
                                                <IconButton
                                                    aria-controls='simple-menu'
                                                    aria-haspopup='true'

                                                    onMouseDown={this.onSpanClick}
                                                    onClick={(event) => this.handleClick(event, team)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id='options-menu'
                                                    anchorEl={this.state.anchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={this.handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: MAX_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem key={"delete"} onClick={() => {
                                                        this.handleClose()
                                                        this.props.onDelete(this.state.selectedItem)
                                                    }}>
                                                        {this.props.language.buttons.delete}
                                                    </MenuItem>

                                                </Menu>
                                            </div>
                                        </div>
                                        <Typography gutterBottom color="textPrimary" className={classes.surfaceTitle || '-'}>
                                            {this.props.language.labels.teamLeader}
                                        </Typography>

                                        <div className={classes.details}>
                                            <div className={classes.userImageContainer}>
                                                <div className={classes.flexRow}>
                                                    {team.teamLeader ? <CardMedia
                                                        component='img'
                                                        alt={this.props.language.utils.userImage}
                                                        className={classes.userImagePicture}
                                                        image={teamLeaderImage}
                                                        title={this.props.language.utils.userImage}
                                                    /> : <p></p>}
                                                    <div>
                                                        <Typography className={classes.userText}>{`${team.teamLeader ? team.teamLeader.firstName + " " + team.teamLeader.lastName : '-'}`}</Typography>
                                                        <Typography className={classes.userText}>{`${team.teamLeader ? team.teamLeader.phoneNumber : ''}`} </Typography>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.alignMembersBotton}>
                                            <div >
                                                <Typography gutterBottom color="textPrimary" className={classes.surfaceTitle || '-'}>
                                                    {this.props.language.labels.teamMembers}
                                                </Typography>
                                                <div className={classes.details}>
                                                    <div className={classes.userImageContainer}>
                                                        {team.members.slice(0, 3).map(member => {
                                                            const userImage = member.profileImage ? `${BACKEND_URL}/static/${member.profileImage.path}` : "https://vectorified.com/images/profile-icon-png-27.png"
                                                            return (
                                                                <div className={classes.flexRow}>
                                                                    <CardMedia
                                                                        component='img'
                                                                        alt={this.props.language.utils.userImage}
                                                                        className={classes.userImagePicture}
                                                                        image={userImage}
                                                                        title={this.props.language.utils.userImage}
                                                                    />
                                                                    <Typography className={classes.userText}>{`${member.firstName} ${member.lastName}`}</Typography>
                                                                </div>

                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                <Typography>
                                                    <div className={this.props.classes.smallDetailsContainer}>
                                                        <span className={classes.smallDetailsContent}><PeopleIcon /></span>
                                                        <span className={`${classes.smallDetailsContent} ${classes.smallDetailsText}`}>{team.members ? team.members.length : '-'} </span>
                                                    </div>
                                                </Typography>
                                            </div>
                                        </div>

                                    </CardContent>
                                </CardWrapper>
                            </div>
                        )
                    })}
                </div>
            </div >
        )
    }
}
const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeamCards)))