import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Tooltip, InputAdornment, CircularProgress } from '@material-ui/core'
import { Delete as DeleteIcon, Description as UploadIcon } from '@material-ui/icons'
import moment from 'moment'

import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'

import Table from '../../common/SimpleTable'
import Modal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    uploadInvoiceButton: {
        marginTop: 10,
        height: 46
    },
    photoIcon: {
        paddingRight: 8
    },
    fileInput: {
        display: 'none'
    },
    fieldsColumn: {
        paddingRight: '45px',
        '&:last-child': {
            paddingRight: 0
        }
    },
    inputField: {
        flex: 1
    },
    paddingTopButton: {
        padding: '8px 0px',
        paddingRight: 25
    },
    addInvoiceSpinner: {
        width: '15px !important',
        height: '15px !important'
    },
    spinnerColorWhite: {
        color: 'white !important'
    },
    spinnerPadding: {
        paddingLeft: '10px'
    }
})


class Files extends Component {

    initialFields = [
        { value: '', type: 'text', label: this.props.language.labels.description, name: 'description' },
        { value: 0, type: 'number', label: this.props.language.labels.value, name: 'value' },
    ]

    state = {
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        rows: [],
        allRows: [],
        openUploadModal: false,
        file: null,
        uploadRef: React.createRef(),
        invoiceFields: this.initialFields,
        isUploading: false
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.files.length !== nextProps.files.length) {
            this.setFiles(nextProps.files)
        }
    }
    componentDidMount() {
        this.setFiles(this.props.files)
    }

    setFiles = files => {
        this.setState({
            rows: files.map((file, index) => ({ index: index + 1, name: file.file.originalName, createdAt: moment(file.file.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT), totalValue: `${file.value} ${this.props.language.utils.ron}`, description: file.description })),
            allRows: files.map((file, index) => ({ index: index + 1, name: file.file.originalName, createdAt: moment(file.file.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT), totalValue: `${file.value} ${this.props.language.utils.ron}`, description: file.description })),
            count: files.length
        }, this.setFilesChangePage)
    }

    setFilesChangePage = () => {
        let filesCopy = this.state.allRows.map(field => ({ ...field }))
        if (this.state.search) {
            filesCopy = filesCopy.filter(file => file.name.includes(this.state.search))
        }
        filesCopy = filesCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: filesCopy })
    }

    triggerInput = () => {
        this.state.uploadRef.current.click();
    }

    setSearch = search => {
        this.setState({ search }, () => {
            this.onSearch()
        })
    }

    onSearch = () => {
        let filesCopy = [...this.state.allRows].map(field => ({ ...field }))
        filesCopy = filesCopy.filter(file => file.name.includes(this.state.search))
        let searchCount = filesCopy.length
        filesCopy = filesCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: filesCopy, count: searchCount })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.setFilesChangePage)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.setFilesChangePage)
    }

    onAcceptHandler = () => {
        let jsonMap = {}
        this.state.invoiceFields.forEach(field => {
            jsonMap[field.name] = field
        })

        if (!this.state.file)
            return NOTIFICATIONS.error(this.props.language.toastr.file.selectFile)

        const fileFormData = new FormData();

        fileFormData.append('description', jsonMap['description'].value)
        fileFormData.append('value', jsonMap['value'].value)
        fileFormData.append('file', this.state.file)

        this.props.uploadFileTo(this.props.companyId, fileFormData)
            .then(() => {
                this.clearFields()
                this.onCancel()
                this.setState({ isUploading: false })
                NOTIFICATIONS.success(this.props.language.toastr.company.invoiceSuccesUpload)
            })
            .catch(() => {
                this.setState({ isUploading: false })
                NOTIFICATIONS.error(this.props.language.toastr.company.invoiceErrorUpload)
            })
    }

    imageUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        this.setState({ file: filesArray[0] })
    }

    onChangeHandler = event => {
        const currentIndex = this.state.invoiceFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            let newFields = [...this.state.invoiceFields].map(obj => ({ ...obj }))

            newFields[currentIndex].value = event.target.value
            this.setState({ invoiceFields: newFields })

        }
    }

    onCancel = () => {
        this.setState({ openUploadModal: false })
        this.props.getCompanyDetails()
        this.setFiles(this.props.files)
    }

    clearFields = () => {
        this.setState({ invoiceFields: this.initialFields, file: null })
    }

    InputWrapper = ({ disabled, input, key, shrink, onChange, label, endAdornment }) => <div>
        <InputGenerator
            key={key}
            disabled={disabled}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            label={label ? label : null}
            {...input}
            endAdornment={endAdornment}
            onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
        />
    </div>

    renderFields = () => {
        let jsonMap = {}
        this.state.invoiceFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div>
                <div className={`${this.props.classes.fieldsColumn}`}>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <this.InputWrapper shrink={true} input={jsonMap['value']} key={'value'} endAdornment={<InputAdornment position='end'>{this.props.language.utils.ron}</InputAdornment>} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <this.InputWrapper shrink={true} input={jsonMap['description']} key={'description'} />
                    </div>
                </div>
                <div>
                    <label htmlFor='docUpload' >
                        <Tooltip placement={'bottom'} title={this.props.language.titles.uploadInvoice}>
                            <> <Button className={this.props.classes.uploadInvoiceButton} color='primary' variant="outlined" onClick={this.triggerInput} >
                                <UploadIcon className={this.props.classes.photoIcon} /> {this.props.language.titles.uploadInvoice}
                            </Button> {this.state.file ? this.state.file.name : ''} </>
                        </Tooltip>
                    </label>
                    <input id='docUpload' ref={this.state.uploadRef} type={'file'} onChange={this.imageUploadHandler} className={this.props.classes.fileInput}></input>
                </div>
            </div>
        )
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <Modal
                    open={this.state.openUploadModal}
                    maxWidth={'sm'}
                    title={this.props.language.titles.uploadInvoice}
                    acceptButtonText={this.state.isUploading ? <><CircularProgress className={`${classes.addInvoiceSpinner} ${classes.spinnerColorWhite}`} /> <span className={classes.spinnerPadding}>{this.props.language.toastr.company.invoiceIsUploading}</span> </> : this.props.language.buttons.add}
                    cancelButtonText={this.props.language.cancel}
                    onCancel={() => {
                        this.onCancel()
                    }}
                    onAccept={() => this.setState({ isUploading: true }, () => this.onAcceptHandler())}
                >
                    {this.renderFields()}
                </Modal>
                <Table
                    title={this.props.language.titles.uploadedInvoices}
                    header={this.props.language.tableHeaders.parentCompanyInvoices}
                    rows={this.state.rows}
                    onClickRow={item => {
                        this.props.download(this.props.companyId, this.props.files[item.index - 1]._id, item.name)
                    }}
                    headerButton={{
                        upload: false,
                        tooltip: this.props.language.buttons.add,
                        onClick: () => {
                            this.setState({ openUploadModal: true })
                            // this.fileUploadHandler(event)
                        },
                        icon: <Button color="primary" variant="contained" className={classes.button} >
                            {this.props.language.buttons.add}
                        </Button>
                    }}
                    search={{
                        onSearch: this.setSearch
                    }}
                    actions={[
                        {
                            tooltip: this.props.language.buttons.deleteFile,
                            onClick: (_, file) => {
                                let confirmed = window.confirm(this.props.language.toastr.file.confirmDelete)

                                if (!confirmed) return

                                this.props.deleteFile(this.props.companyId, this.props.files[file.index - 1]).then(() => {
                                    this.props.getCompanyDetails()
                                    this.setFiles(this.props.files)
                                    NOTIFICATIONS.success(this.props.language.toastr.file.successDelete)
                                })
                                    .catch(() => {
                                        NOTIFICATIONS.error(this.props.language.toastr.file.errorDelete)
                                    })
                            },
                            icon: <DeleteIcon />
                        }
                    ]}
                    count={this.state.count}
                    hasNoMargin={true}
                    rowsPerPage={this.state.rowsPerPage}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    uploadFileTo: (id, files) => dispatch(COMPANY_REQUESTS.uploadParentCompanyFile(id, files)),
    deleteFile: (id, invoice) => dispatch(COMPANY_REQUESTS.deleteParentCompanyFile(id, invoice)),
    download: (id, invoiceId, fileName) => dispatch(COMPANY_REQUESTS.downloadParentCompanyInvoice(id, invoiceId, fileName))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Files))