import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Tooltip } from '@material-ui/core'
import {
    Delete as DeleteIcon,
    Business as BuildingIcon
} from '@material-ui/icons'

import CardWrapper from '../../common/CardWrapper'

import { BACKEND_URL } from '../../../utils/constants'

const styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardsContainer: {
        padding: '20px 30px'
    },
    cardContainer: {
        cursor: 'pointer',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        width: '100%',
        minHeight: '125px',
        margin: '20px 0px',
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
            backgroundColor: '#F2F2F2'
        }
    },
    companyImageContainer: {
        width: 200,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    companyData: {
        flex: 1,
        padding: '10px 0px 0px 10px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    firstRowContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    titleContainer: {
        width: '300px',
        display: 'flex',
        flexDirection: 'column'
    },
    titleText: {
        color: '#4D8FDC',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 22
    },
    secondRowContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    imageStyle: {
        height: 'auto',
        maxHeight: 125,
        width: '100%',
        objectFit: 'fill'
    },
    deleteContainer: {
        display: 'flex',
        flex: 1,
        paddingRight: '10px',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        fontSize: 16,
        cursor: 'pointer'
    },
    typeContainer: {
        backgroundColor: '#4D8FDC',
        borderRadius: 5,
        color: '#ffffff',
        width: 'max-content',
        padding: '0px 6px 2px 6px',
        marginTop: 4
    },
    typeText: {
        fontWeight: 500,
        fontSize: 12
    },
    servicesContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        alignItems: 'center'
    },
    servicesTitle: {
        fontSize: 14
    },
    serviceContainer: {
        width: 'max-content',
        borderRadius: 5,
        marginRight: 4,
        padding: '0px 6px 2px 6px',
        border: '1px solid rgba(0,0,0,0.4)'
    },
    serviceText: {
        fontSize: 13
    },
    servicesListContainer: {
        marginTop: 6,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    mainBusinessValue: {
        fontWeight: 'bold',
        fontSize: 14
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    infoText: {
        fontSize: 12
    },
    buildingsContainer: {
        marginLeft: 'auto',
        paddingTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '500px',
    },
    buildingContainer: {
        width: 'max-content',
        border: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: '#f6f6f6',
        padding: '5px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    buildingText: {
        position: 'relative',
        top: 4,
        paddingLeft: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    mainBusinessContainer: {
        paddingBottom: 12,
        justifyContent: 'flex-end'
    }
})

class CompanyCards extends React.Component {

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.cardsContainer}>
                    {this.props.companies.map(company => {
                        let imagePath = company.image ? `${BACKEND_URL}/static/${company.image.path}` : 'https://via.placeholder.com/150'
                        return (
                            <div onClick={() => this.props.history.push(`/companies/${company._id}`)} className={classes.cardContainer}>
                                <div className={classes.companyImageContainer} style={{backgroundImage: `url(${imagePath})`}}>
                                    {/* <img alt='' className={classes.imageStyle} src={imagePath}></img> */}
                                </div>
                                <div className={classes.companyData}>
                                    <div className={classes.firstRowContainer}>
                                        <div className={classes.titleContainer}>
                                            <Tooltip placement={"top-start"} title={company.name}>
                                                <span className={classes.titleText}>{company.name}</span>
                                            </Tooltip>
                                            {company.type ? <div className={classes.typeContainer} style={company.type.toLowerCase() === 'contractor' ? { backgroundColor: '#ff5722' } : null}><span className={classes.typeText}>{company.type.toUpperCase()}</span></div> : null }
                                        </div>
                                        <div className={classes.servicesContainer}>
                                            {company.type && company.type.toLowerCase() === 'contractor' && <> <span classsName={classes.servicesTitle}>{this.props.language.utils.activities}</span>
                                                <div className={classes.servicesListContainer}>
                                                    {company.services ? company.services.map(service => {
                                                        return (
                                                            <div className={classes.serviceContainer}><span className={classes.serviceText}>{service}</span></div>
                                                        )
                                                    }) : '-'}
                                                </div></>}
                                        </div>
                                        {/*<div className={classes.deleteContainer}><DeleteIcon onClick={() => {

                                        }} /></div>*/}
                                    </div>
                                    <div className={classes.secondRowContainer}>
                                        <div className={`${classes.mainBusinessContainer} ${classes.flexColumn}`}>
                                            <span>{this.props.language.utils.mainBusiness} <span className={classes.mainBusinessValue}>{company.mainBusiness.toUpperCase()}</span></span>
                                            <span className={classes.infoText}>{this.props.language.utils.belongsTo} {company.buildings && company.buildings.length ? company.buildings.length : '-'} {company.buildings.length <= 1 ? this.props.language.utils.building : this.props.language.utils.buildings}</span>
                                        </div>
                                        <div className={classes.buildingsContainer}>
                                            {
                                                company.buildings && company.buildings.length ? company.buildings.map(building => {
                                                    return (
                                                        <Tooltip placement={"top"} title={building.building.name}>
                                                            <div className={classes.buildingContainer}><BuildingIcon /><span className={classes.buildingText}>{building.building.name}</span></div>
                                                        </Tooltip>
                                                    )
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyCards)))