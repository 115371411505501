import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, Card, Typography, Divider } from '@material-ui/core'

import LineCountChart from './charts/LineCountChart'
import ServicesPieChart from './charts/ServicesPieChart'
import BuildingsPieChart from './charts/BuildingsPieChart'
import PriorityLineChart from './charts/PriorityLineChart'

const useDetailsContainerStyles = makeStyles({
    container: {
        width: 'calc(100% - 25px)',
        height: '100%',
        display: 'flex',
        marginLeft: 25,
        flexDirection: 'column'
    },
    chart: {
        width: '100%',
        height: '80%'
    },
    title: {
        marginTop: 10,
        width: '100%',
        height: '20%',
        display: 'flex',
        flexDirection: 'column'
    }
})

let DetailsContainer = props => {
    let classes = useDetailsContainerStyles()

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <Typography variant="h5" component="h6" gutterBottom>
                    {props.title}
                </Typography>
                <Typography variant="subtitle1" component="span" color={'textSecondary'}>
                    {props.subtitle}
                </Typography>
            </div>
            <div className={classes.chart}>
                {props.chart}
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)'
    },
    detailedCharts: {
        width: '100%',
        height: 400,
        display: 'flex',
        flexDirection: 'row'
    }
})

let LineChart =  props => {
    let classes = useStyles()
    let { language } = props

    return (
        <Card className={classes.container}>
            {props.data ? <>
                <div className={classes.detailedCharts}>
                    <DetailsContainer
                        title={language.servicePie.title}
                        subtitle={language.servicePie.subtitle}
                        chart={<ServicesPieChart
                            data={props.data.services || []}
                            fill={props.data.services.map((service, index) => ({
                                match: {
                                    id: service.label
                                },
                                id: (index % 2 === 0) ? 'lines' : 'dots'
                            }))}
                        />}
                    />
                    <DetailsContainer
                        title={language.buildingsPie.title}
                        subtitle={language.buildingsPie.subtitle}
                        chart={<BuildingsPieChart
                            data={props.data.buildingTickets}
                            fill={props.data.buildingTickets.map((building, index) => ({
                                match: {
                                    id: building.label
                                },
                                id: (index % 2 === 0) ? 'dots' : 'lines'
                            }))}
                        />}
                    />
                </div>

                <Divider />
                <DetailsContainer
                    title={language.tichetsLineChart.title}
                    subtitle={language.tichetsLineChart.subtitle}
                    chart={<LineCountChart
                        yTitle={language.lineChart.yTitle}
                        xTitle={language.lineChart.xTitle}
                        data={props.data.ticketsStatusesPerDays || []} />}
                />
                <Divider />
                <div className={classes.detailedCharts}>
                    <DetailsContainer
                        title={language.priorityChart.title}
                        subtitle={language.priorityChart.subtitle}
                        chart={<PriorityLineChart
                            data={props.data.priorities}
                            yTitle={language.priorityChart.yTitle}
                            xTitle={language.priorityChart.xTitle}
                            language={props.language}
                        />}
                    />
                </div>
            </> : null}
        </Card>
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.charts
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LineChart)