import React from 'react'
import { TextField, MenuItem, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core'
import { Close, Add } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import NumberFormat from 'react-number-format'

import DropdownSelector from './DropdownSelector'
import MultipleSelector from './MultipleSelector'
import MultipleCheckbox from './MultipleCheckbox'
import PhoneSelector from './PhoneSelector'
import SimpleSelect from './SimpleSelector'
import DateTimeSelector from './DateTimeSelector'
import * as CONSTANTS from '../../utils/constants'


class InputGenerator extends React.Component {

    shouldComponentUpdate(prevProps) {
        if (this.props.value !== prevProps.value
            || this.props.defaultValue !== prevProps.defaultValue
            || this.props.name !== prevProps.name
            || this.props.disabled !== prevProps.disabled
            || this.props.error !== prevProps.error
            || this.props.options !== prevProps.options
        ) {
            return true
        }
        return false
    }

    // NumberFormatWrapper({ inputRef, value, name, onChange, ...other }) {
    //     return <NumberFormat
    //         {...other}
    //         getInputRef={inputRef}
    //         value={value}
    //         thousandSeparator={"."}
    //         decimalSeparator={","}
    //         decimalScale={CONSTANTS.DEFAULT_DECIMAL_SIZE}
    //         onValueChange={values => {
    //             const { floatValue } = values
    //             onChange({
    //                 target: {
    //                     name: name,
    //                     value: floatValue
    //                 }
    //             })
    //         }} />
    // }

    render() {
        let { startAdornment, endAdornment, noOptions, readOnly, ...props } = this.props
        const inputProps = {
            InputProps: {
                startAdornment: startAdornment ? (
                    <InputAdornment>
                        {startAdornment}
                    </InputAdornment>
                ) : null,
                endAdornment: endAdornment ? (
                    <InputAdornment>
                        {endAdornment}
                    </InputAdornment>
                ) : null,
                readOnly
            }
        }

        switch (props.type) {
            case 'select':
                return <TextField
                    label={props.label}
                    onClick={props.onClick ? props.onClick : () => { }}
                    {...props}
                    select
                    variant="outlined">
                    {props.options.map((option, index) => {
                        return <MenuItem
                            key={index}
                            value={option.value}>
                            {option.name}
                        </MenuItem>
                    })
                    }
                </TextField>
            // case 'numberFormat':
            //     return <TextField
            //         {...props}
            //         margin="dense"
            //         variant="outlined"
            //         InputProps={{
            //             inputComponent: this.NumberFormatWrapper
            //         }}
            //     />
            case 'simpleSelector':
                return <SimpleSelect {...props} />
            case 'multiselector':
                return <MultipleSelector
                    {...props}
                />
            case 'multicheckbox':
                return <MultipleCheckbox
                    {...props}
                />
            case 'dropdownSelector':
                return <DropdownSelector
                    {...props}
                />
            case 'fixedMultiSelect':
                return <Autocomplete
                    multiple
                    disabled={props.disabled}
                    options={props.options}
                    disableCloseOnSelect
                    getOptionLabel={option => option.label || option.name}
                    defaultValue={props.defaultValue ? props.defaultValue : []}
                    onChange={(_, values) => props.onChange({
                        target: {
                            name: props.name,
                            value: values
                        }
                    })}
                    closeIcon={<Close style={{ color: '#f50057' }} />}
                    popupIcon={<Add />}
                    renderInput={params => (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            label={this.props.utils}
                        />
                    )}
                />
            case 'checkbox':
                return <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.value}
                            onChange={() => props.onChange({
                                target: {
                                    name: props.name,
                                    value: !props.value
                                }
                            })}
                        />
                    }
                    label={props.label}
                />
            case 'phone':
                return <PhoneSelector
                    {...props}
                />
            case 'dateTime':
                return <DateTimeSelector
                    {...props}
                />
            default:
                return <TextField
                    variant="outlined"
                    fullWidth={true}
                    {...props}
                    {...inputProps}
                />
        }
    }
}


export default InputGenerator