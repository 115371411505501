import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import {
    Check as AgreeIcon,
    Clear as DeclineIcon
} from '@material-ui/icons'

import InputGenerator from '../../../common/InputGenerator'
import ConfirmationDialog from '../../../common/ConfirmationDialog'

import * as TEAM_REQUESTS from '../../../../redux/actions/teams'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as NOTIFICATIONS from '../../../../utils/notification'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    titleText: {
        color: '#606771',
        fontSize: 24,
        fontWeight: 300,
        flex: 1
    },
    equalsFlex: {
        flex: 1
    },
    extraPadding: {
        padding: '4px 12px 14px 12px'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    iconContainer: {
        alignItems: 'center',
        padding: '14px 0px 0px 24px'
    },
    smallButton: {
        width: 30,
        height: 30
    },
    agreeIcon: {
        color: '#34aa44'
    },
    declineIcon: {
        color: '#E74C3C'
    },
    content: {
        padding: '8px 28px',
        minHeight: 100,
    },
    contentWhiteBg: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    button: {
        backgroundColor: '#d32f2f',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
        color: 'white',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: '#d32f2f',
            opacity: 0.75
        }
    }
})

class TeamEdit extends Component {

    initialCompanyFields = [
        { value: '', type: 'text', editing: false, label: this.props.language.labels.name, name: 'name' },
        { value: '', type: 'dropdownSelector', editing: false, utils: this.props.language.labels.teamLeader, name: 'teamLeader', options: [] }
    ]

    state = {
        team: null,
        editableFields: this.initialCompanyFields,
        done: false,
        openDeleteModal: false
    }

    componentDidMount() {
        this.getTeam(this.props.team)
    }

    getTeam = team => {
        if (team) {
            this.props.getUsers({ all: true }).then(possTeamLeaders => {
                this.mapFields(team, possTeamLeaders.users)
            })
        }
    }


    mapFields = (result, possTeamLeaders) => {
        let fieldsCopy = this.state.editableFields.map(field => ({ ...field }))
        let teamLeaders = [{ name: '', value: false, label: '' }]
            .concat(possTeamLeaders.map(leader => ({
                name: leader._id,
                value: false,
                label: `${leader.firstName} ${leader.lastName}`
            })))

        Object.keys(result).forEach((key, index) => {
            const currentIndex = fieldsCopy.findIndex(index => index.name === key)
            if (currentIndex > -1) {
                fieldsCopy[currentIndex].editing = false
                fieldsCopy[currentIndex].value = result[key]
                if (fieldsCopy[currentIndex].type === 'dropdownSelector') {
                    fieldsCopy[currentIndex].options = teamLeaders
                    fieldsCopy[currentIndex].value = {
                        label: `${result.teamLeader.firstName} ${result.teamLeader.lastName}`,
                        value: true,
                        name: result.teamLeader._id
                    } || {
                        name: '', value: false, label: ''
                    }
                }
                this.setState({ editableFields: fieldsCopy })
            }
        })
        this.setState({ done: true })
    }

    InputWrapper = ({ input, key, shrink }) => {
        return <div>
            <InputGenerator
                key={key}
                fullWidth={true}
                InputLabelProps={shrink ? { shrink: true } : {}}
                margin="dense"
                {...input}
                onChange={event => this.onChangeHandler(event)}
            />
        </div>
    }

    checkForEdit = json => {
        if (json.editing) return (
            <div className={`${this.props.classes.flexRow} ${this.props.classes.iconContainer}`}>
                <Tooltip placement={'top'} title={this.props.language.tooltip.acceptChanges}>
                    <Button className={this.props.classes.smallButton}>
                        <AgreeIcon
                            className={this.props.classes.agreeIcon}
                            onClick={() => this.onChangeHandler({ target: json }, true)}
                        />
                    </Button>
                </Tooltip>
                <Tooltip placement={'top'} title={this.props.language.tooltip.declineChanges}>
                    <Button className={this.props.classes.smallButton}>
                        <DeclineIcon
                            className={this.props.classes.declineIcon}
                            onClick={() => this.onChangeHandler({ target: json }, false)}
                        />
                    </Button>
                </Tooltip>
            </div>
        )
        else return null
    }

    onChangeHandler = (event, accepted) => {
        const fieldToEdit = event.target.name
        const valueToAdd = event.target.value
        if (accepted || event.target.name === 'teamLeader') {
            this.props.update(this.props.team._id, { team: { [fieldToEdit]: valueToAdd } })
                .then(() => {
                    this.props.getTeam()
                    const currentIndex = this.state.editableFields.findIndex(index => index.name === fieldToEdit)
                    let newFields = [...this.state.editableFields]
                    if (currentIndex > -1) {
                        newFields[currentIndex].editing = false
                        this.setState({ editableFields: newFields })
                    }
                    NOTIFICATIONS.success(this.props.language.toastr.team.successEdit)
                })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.toastr.team.errorEdit)
                })
        }
        else {
            const currentIndex = this.state.editableFields.findIndex(index => index.name === event.target.name)
            if (currentIndex > -1) {
                let fieldsCopy = this.state.editableFields.map(field => ({ ...field }))
                if (accepted === false) {
                    fieldsCopy[currentIndex].editing = false
                    fieldsCopy[currentIndex].value = this.props.team[event.target.name]
                }
                else {
                    fieldsCopy[currentIndex].value = event.target.value
                    fieldsCopy[currentIndex].editing = true
                }
                this.setState({ editableFields: fieldsCopy })
            }
        }
    }

    onCloseModal = () => {
        this.setState({ openDeleteModal: false })
    }

    onOpenModal = () => {
        this.setState({ openDeleteModal: true })
    }

    onDeleteHandler = () => {
        this.props.deleteTeam(this.props.team._id)
            .then(() => {
                this.onCloseModal()
                this.props.getTeam()
                this.props.history.push('/teams')
                NOTIFICATIONS.success(this.props.language.toastr.team.successDelete)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.team.errorDelete)
            })
    }

    renderEditableFields = () => {
        const InputWrapper = this.InputWrapper
        const jsonMap = {}
        this.state.editableFields.forEach(field => {
            jsonMap[field.name] = field
        })

        if (this.state.done) {
            return (
                <>
                    <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding} ${this.props.classes.flexRow}`}>
                        <div className={this.props.classes.equalsFlex}>
                            <InputWrapper shrink={true} input={jsonMap['name']} key={'name'} />
                        </div>
                        {this.checkForEdit(jsonMap['name'])}
                    </div>
                    <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding} ${this.props.classes.flexRow}`}>
                        <div className={this.props.classes.equalsFlex}>
                            <InputWrapper shrink={true} input={jsonMap['teamLeader']} key={'teamLeader'} onChange={event => this.onChangeHandler(event)} />
                        </div>
                    </div>
                </>
            )
        }
        else
            return null

    }


    render() {
        let { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <span className={classes.titleText}>{this.props.team.name}</span>
                    <Button className={classes.button} onClick={this.onOpenModal}>{this.props.language.buttons.delete}</Button>
                    <ConfirmationDialog
                        open={this.state.openDeleteModal}
                        cancelButtonText={this.props.language.buttons.cancel}
                        acceptButtonText={this.props.language.buttons.delete}
                        text={this.props.language.utils.delete}
                        onClose={this.onCloseModal}
                        onCancel={this.onCloseModal}
                        onAccept={this.onDeleteHandler}
                    />
                </div>
                <div className={classes.content}>
                    <div className={classes.contentWhiteBg}>
                        {this.renderEditableFields()}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getUsers: options => dispatch(USER_REQUESTS.get(options)),
    update: (id, fields) => dispatch(TEAM_REQUESTS.edit(id, fields)),
    deleteTeam: id => dispatch(TEAM_REQUESTS.remove(id))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeamEdit)))