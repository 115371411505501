import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core'
import {
    Face as OwnerIcon,
    Build as FacilityManagerIcon,
    BusinessCenter as PropertyManagerIcon,
    PermPhoneMsg,
    PhoneRounded,
    MailOutline,
    Language as WebsiteIcon
} from '@material-ui/icons'

import { BACKEND_URL, DEFAULT_BUILDING_IMAGE } from '../../../utils/constants'

let Card = props => {
    let classes = useStyles()

    const buildingImage = props.image ? `${BACKEND_URL}/static/${props.image.path}` : DEFAULT_BUILDING_IMAGE

    return (
        <div onClick={props.onClick} className={`${classes.actionsArea} ${classes.cardContainer}`}>
            <div className={classes.cardContentContainer}>
                <img
                    alt={props.language.labels.buildingImage}
                    src={buildingImage}
                    title={props.language.labels.buildingImage}
                    className={classes.buildingImageStyle}
                />
                <div className={classes.disableBorder}>
                    <div className={classes.titleContainer}>
                        <Tooltip title={props.name || '-'} placement='top-end'>
                            <Typography className={`${classes.title}`}>
                                {props.name || '-'}
                            </Typography>
                        </Tooltip>
                        {/*<Delete className={classes.deleteBuildingIcon} />*/}
                    </div>

                    <div className={classes.detailsContainer}>
                        <div className={classes.firstColumn}>
                            <div className={classes.officialTextDisplay}>
                                <OwnerIcon className={classes.officialIcon} />
                                <div className={classes.officialsAlign}>
                                    <Typography className={`${classes.officialName}`}>{props.owner || '-'}</Typography>
                                    <div className={classes.officialTagContainer}>
                                        <Typography className={`${classes.officialTagText}`} >{props.language.labels.propertyOwner}</Typography>
                                    </div>
                                </div>

                            </div>

                            <div className={classes.officialTextDisplay}>
                                <PropertyManagerIcon className={classes.officialIcon} />
                                <div className={classes.officialsAlign}>
                                    <Typography className={`${classes.officialName}`}>{props.propertyManager || '-'}</Typography>
                                    <div className={classes.officialPM}>
                                        <div className={`${classes.officialTagContainer}`}>
                                            <Typography className={`${classes.officialTagText}`} >{props.language.labels.propertyManager} </Typography>
                                        </div>
                                        <PermPhoneMsg className={classes.officialContactPersonIcon} />
                                    </div>

                                </div>

                            </div>

                            <div className={classes.officialTextDisplay}>
                                <FacilityManagerIcon className={classes.officialIcon} />
                                <div className={classes.officialsAlign}>
                                    <Typography className={`${classes.officialName}`} >{props.facilityManager || '-'}</Typography>
                                    <div className={classes.officialTagContainer}>
                                        <Typography className={`${classes.officialTagText}`} >{props.language.labels.facilityManager}</Typography>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={classes.secondColumn}>
                            <div className={classes.surfaceMeasurementsTitle}>
                                <Typography className={`${classes.surfaceTitle}`}>
                                    {props.language.labels.surfaceDetails}
                                </Typography>
                            </div>
                            <div className={classes.surfaceMeasurementsText}>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.details} ${classes.normalFont} `}>
                                        {props.language.labels.total}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.surfaceMeasurements}>
                                        {props.language.utils.buildingSquareMeters} &nbsp;
                                    </Typography>
                                </div>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.surfaceMeasurements}`}>
                                        {props.area.total || '-'} mp
                                    </Typography>
                                </div>

                            </div>
                            <div className={classes.surfaceMeasurementsText}>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.details} ${classes.normalFont} `}>
                                        {props.language.labels.util}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.surfaceMeasurements} >
                                        {props.language.utils.buildingSquareMeters} &nbsp;
                                    </Typography>
                                </div>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.surfaceMeasurements} `}>
                                        {props.area.usable || '-'} mp
                                </Typography>
                                </div>
                            </div>
                            <div className={classes.surfaceMeasurementsText}>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.details} ${classes.normalFont}`}>
                                        {props.language.labels.office}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.surfaceMeasurements} >
                                        {props.language.utils.buildingSquareMeters} &nbsp;
                                    </Typography>
                                </div>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={classes.surfaceMeasurements}>
                                        {props.area.office || '-'} mp
                                </Typography>
                                </div>
                            </div>
                            <div className={classes.surfaceMeasurementsText}>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={`${classes.details} ${classes.normalFont}`}>
                                        {props.language.labels.commercial}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.surfaceMeasurements} >
                                        {props.language.utils.buildingSquareMeters} &nbsp;
                                    </Typography>
                                </div>
                                <div className={classes.flexSurfaceMeasurements}>
                                    <Typography className={classes.surfaceMeasurements}>
                                        {props.area.commercial || '-'} mp
                                </Typography>
                                </div>
                            </div>
                        </div>

                        <div className={classes.thirdColumn}>
                            <div className={classes.contactDetails}>
                                <div className={`${classes.surfaceMeasurementsTitle} ${classes.contactTitle}`}>
                                    <Typography className={`${classes.surfaceTitle}`}>
                                        {props.language.labels.contact}
                                    </Typography>
                                </div>
                                <div className={classes.contactInfo}>
                                    <PhoneRounded className={classes.contactIcons} />
                                    <Typography className={`${classes.details} ${classes.normalFont}`} >{props.contact.phoneNumber || '-'}</Typography>
                                </div>
                                <div className={classes.contactInfo}>
                                    <MailOutline className={classes.contactIcons} />
                                    <Typography className={`${classes.details} ${classes.normalFont}`} >{props.contact.email || '-'}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.websiteContainer}>
                    <WebsiteIcon className={classes.websiteIcon} />
                    <Typography className={`${classes.details} ${classes.normalFont}`}>{props.contact.website ? <a href={props.contact.website} target="blank" className={classes.websiteLink}>{props.language.buildingCards.singleCardDetails.visitWebsite}</a> : '-'}</Typography>
                </div>
            </div>
        </div >

    )
}

let useStyles = makeStyles({
    disableBorder: {
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '4px',
        width: '100%'
    },
    surfaceTitle: {
        fontSize: '14px',
        lineHeight: '15px',
        color: '#000000',
        height: '16px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600
    },
    title: {
        color: '#4D8FDC',
        flex: 1,
        fontSize: 22,
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    titleContainer: {
        width: '530px',
        height: '30px',
        display: 'flex'
    },
    details: {
        fontSize: '10px',
        lineHeight: '12px',
    },
    surfaceMeasurements: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '12px',
    },
    user: {
        paddingLeft: 10,
        fontSize: 15,
        color: 'primary'
    },
    buildingImageStyle: {
        maxHeight: '180px',
        maxWidth: '220px',
        minWidth: '200px',
        objectFit: 'cover',
        borderRadius: '4px',
        marginRight: '25px',
        padding: '2px'
    },
    cardContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '200px',
        flex: 1
    },
    cardContainer: {
        overflow: 'auto',
        width: '100%',
        cursor: 'pointer',
        position: 'relative'
    },
    firstColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginTop: 5
    },
    thirdColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginTop: 5
    },
    secondColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: 5
    },
    actionsArea: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '165px'
    },
    detailsContainer: {
        display: 'flex'
    },
    officialsAlign: {
        display: 'flex',
        flexDirection: 'column'
    },
    officialTextDisplay: {
        display: 'flex',
        marginTop: '5px',
        alignItems: 'center'
    },
    officialTagContainer: {
        border: '0.5px solid rgba(0, 0, 0, 0.5)',
        boxSizing: 'border-box',
        borderRadius: '3px',
        textAlign: 'center',
        width: 'max-content',
        padding: '2px 8px 2px 8px',
    },
    officialTagText: {
        fontSize: '12px',
        lineHeight: '10px',
        color: 'rgba(0, 0, 0, 0.70)'
    },
    officialName: {
        fontSize: 13,
        fontWeight: 500,
        color: '#000000'
    },
    officialIcon: {
        fontSize: 30,
        marginRight: '5px',
        color: '#9f9f9f'
    },
    officialContactPersonIcon: {
        width: '13px',
        height: '15px',
        marginLeft: '2px'
    },
    officialPM: {
        display: 'flex',
        alignItems: 'center'
    },
    officialPMTag: {
        flex: '1'
    },
    surfaceMeasurementsText: {
        display: 'flex',
        marginTop: '3px',
        marginBottom: '3px',
        height: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    surfaceMeasurementsTitle: {
        marginTop: '10px',
        marginBottom: '8px',
    },
    weightFont: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 800
    },
    normalFont: {
        fontWeight: 400,
        fontSize: 14
    },
    contactIcons: {
        fontSize: 20,
        marginRight: '5px'
    },
    websiteContainer: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        background: '#F6F6F6',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '2px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center'
    },
    contactInfo: {
        display: 'flex',
        margin: '4px 0px',
        alignItems: 'center'
    },
    websiteIcon: {
        height: '12px',
        width: '12px',
        marginRight: '3px'
    },
    websiteLink: {
        textDecoration: 'none'
    },
    deleteBuildingIcon: {
        width: '26px',
        height: '27px',
        paddingRight: '17px'
    },
    flexSurfaceMeasurements: {
        flex: 1
    },
    contactDetails: {
        marginLeft: '25%'
    }
})

export default Card