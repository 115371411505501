import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Header from '../../components/common/Header'
import BuildingCard from './cards/Cards'
import CreateModal from './create/Create'
import Pagination from '../common/Pagination'

import * as BUILDING_REQUESTS from '../../redux/actions/buildings'
import * as CONSTANTS from '../../utils/constants'

const Buildings = props => {
    let { classes } = props
    let [showCreateModal, setShowCreateModal] = useState(false)
    let [buildings, setBuildings] = useState([])
    let [selectedBuilding, setSelectedBuilding] = useState(null)
    let [count, setCount] = useState(0)
    let [from, setFrom] = useState(0)
    let [search, setSearch] = useState('')
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE)

    let getBuildings = () => props.getBuildings({ search: search, from: from, limit: rowsPerPage })
        .then(result => {
            setBuildings(result.buildings)
            setCount(result.count)
        })

    useEffect(() => {
        getBuildings()

        return () => { }
    }, [selectedBuilding, rowsPerPage, from, search])

    const changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
    }

    const changePageHandler = currentFrom => {
        setFrom(currentFrom)
    }

    const onSearchHandler = value => {
        setFrom(0)
        setSearch(value)
    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <Header
                        placeholderText={props.language.utils.searchBuildingText}
                        title={props.language.buildings}
                        buttonText={props.language.create}
                        onSearch={value => onSearchHandler(value)}
                        onClick={() => setShowCreateModal(true)}
                        addText={props.language.headerTooltip.building}
                        profileText={props.language.headerTooltip.profile}
                    />
                </div>
                <div className={classes.container}>
                    <div className={classes.cardsDisplay}>
                        {count > 0 ? buildings.map(building => (
                            <div className={classes.spacing} key={building._id}>
                                <BuildingCard
                                    onClick={() => props.history.push('/buildings/' + building._id)}
                                    image={building.image}
                                    contact={building.contact}
                                    area={building.area}
                                    name={building.name}
                                    propertyManager={building.propertyManager && building.propertyManager.lastName && building.propertyManager.firstName ? `${building.propertyManager.lastName} ${building.propertyManager.firstName.toUpperCase()}` : ''}
                                    owner={building.owner && building.owner.lastName && building.owner.firstName ? `${building.owner.lastName} ${building.owner.firstName.toUpperCase()}` : '-'}
                                    facilityManager={building.facilityManager && building.facilityManager.lastName && building.facilityManager.firstName ? `${building.facilityManager.lastName} ${building.facilityManager.firstName.toUpperCase()}` : ''}
                                    language={props.language}
                                />
                            </div>
                        )) : <div className={classes.noResultsText} > <span>{props.language.utils.noResults}</span></div>}
                        <Pagination
                            from={from}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPageHandler}
                            changePageHandler={changePageHandler}
                        />
                    </div>

                    {/* <div>
                        <Pagination
                            count={count}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPageHandler}
                            changePageHandler={changePageHandler}
                        />
                    </div> */}
                </div >


            </div>
            {showCreateModal && <CreateModal
                open={showCreateModal}
                building={selectedBuilding}
                superAdmin={props.superAdmin}
                onAccept={() => {
                    setShowCreateModal(false)
                    setSelectedBuilding(null)
                    getBuildings()
                }}
                onCancel={() => {
                    setShowCreateModal(false)
                    setSelectedBuilding(null)
                }}
            />}
        </>
    )
}
const styles = theme => ({
    header: {
        height: 70
    },
    noResultsText: {
        paddingLeft: 0,
        paddingTop: 20
    },
    wrapper: {
        height: '100%',
        width: '100%',
        backgroundColor: '#F9FBFF',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '0px 25px 0px 30px'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    line: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    spacing: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        height: '165px',
        width: '100%',
        margin: '10px 0px',
        border: '1px solid rgba(0,0,0,0.1)',
        '&:hover': {
            backgroundColor: '#F2F2F2'
        },
        '&:nth-of-type(1)': {
            marginTop: 30
        }
    },
    cardsDisplay: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getBuildings: options => dispatch(BUILDING_REQUESTS.get(options)),
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Buildings)))