import * as TYPES from '../actionTypes'

const initialState = {
    create: false,
    ticketType: ''
}

function changeLanguage(state = initialState, action) {
    switch (action.type) {
        case TYPES.SET_TICKET_TYPE:
            return { ...state, ticketType: action.payload.ticketType }
        default:
            return state
    }
}

export default changeLanguage