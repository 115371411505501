import React from 'react'
import { withStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons';

let List = props => {
    let { classes } = props

    return (
        <div className={classes.container}>
            <TableContainer component={Paper} className={classes.table}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{props.language.labels.identifier}</TableCell>
                            <TableCell>{props.language.labels.name}</TableCell>
                            <TableCell>{props.language.labels.createdBy}</TableCell>
                            <TableCell>{props.language.labels.createdAt}</TableCell>
                            <TableCell>{props.language.labels.actions}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items.map(item => (
                            <TableRow key={item.name}>
                                <TableCell component="th" scope="row">
                                    {item._id}
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.createdBy}</TableCell>
                                <TableCell>{item.createdAt}</TableCell>
                                <TableCell>
                                    <Button onClick={item.onDeleteClicked}>
                                        <DeleteIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

let styles = () => ({
    container: {
        width: '100%',
        height: '100%'
    },
    table: {
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
    }
})

export default withStyles(styles)(List)