import React from 'react'

export const ClickWrapper = ({ children, onClick }) => (
    <React.Fragment>
        {React.Children.map(children, child => (
            React.cloneElement(child, {
                onClick,
            })
        ))}
    </React.Fragment>
)

export const checkForRole = (arr, role) => arr.includes(role)

export const setImagesClickListeners = callback => {
    let commentsImages = document.querySelectorAll('img.commentImg')
    commentsImages.forEach(function (button) {
        button.addEventListener('click', function (event) {
            callback(event.target.src)
        }.bind(this))
    }.bind(this))
}