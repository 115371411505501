import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, CircularProgress } from '@material-ui/core'

import Editable from './editable/Editable'
import Users from './users/Users'
import BuildingsList from './buildingsList/BuildingsList'
import DisplayMessage from './displayMessage/DisplayMessage'
import Files from './files/Files'
import Header from '../../common/Header'

import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as CONSTANTS from '../../../utils/constants'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    contentContainer: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#F9FBFF'
    },
    headerContainer: {
        display: 'flex',
        backgroundColor: '#F9FBFF',
        flexDirection: 'column'
    },
    equalFlex: {
        flex: 1
    },
    editableContainer: {
        height: 'calc(100% - 40px)',
        maxWidth: 400,
        flex: 1,
        margin: '20px 0px 20px 10px',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    usersContainer: {
        height: 'calc(100% - 40px)',
        maxHeight: 'calc(100% - 60px)',
        overflow: 'auto',
        flex: 2,
        margin: '20px 0px 20px 10px'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    buildingsContainer: {
        height: '100%',
        width: '150px'
    },
    displayMessageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    displayMessageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100% - 150px)'
    },
    filesContainer: {
        margin: '0px 20px'
    }
})

class CompanyDetails extends Component {

    state = {
        company: null,
        building: null,
        renderPage: false,
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        noBuildings: false
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany = () => {
        return this.props.getById(this.props.match.params.ID).then(result => {
            this.setState({ company: result })
            return true
        })
    }

    buildingChangedHandler = (buildingId) => {
        this.setState({ renderPage: true }, () => {
            if (buildingId === null) {
                return this.setState({ noBuildings: true })
            }
            else {
                return this.props.getByIdWithBuilding(this.props.match.params.ID, buildingId)
                    .then(result => {
                        this.setState({
                            company: result.company,
                            building: result.building,
                            renderPage: true
                        })
                    })
            }
        })
    }
    render() {
        let { classes, language } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.headerContainer} >
                    <div className={classes.header}>
                        <Header
                            simple
                            title={this.props.language.titles.companies}
                            profileText={this.props.language.headerTooltip.profile}
                        />
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.buildingsContainer}>
                        <BuildingsList
                            companyId={this.props.match.params.ID}
                            buildingChanged={this.buildingChangedHandler}
                            currentBuilding={this.state.building ? this.state.building._id : null}
                            company={this.state.company}
                        />
                    </div>
                    {this.state.building && this.state.renderPage ?
                        <div className={classes.rightContainer}>
                            <div className={classes.editableContainer}>
                                <Editable
                                    companyId={this.props.match.params.ID}
                                    buildingId={this.state.building._id}
                                    company={this.state.company}
                                    building={this.state.building}
                                    users={this.state.building ? this.state.building.users : []}
                                    getBuilding={() => this.buildingChangedHandler(this.state.building._id)}
                                    getCompany={() => this.getCompany()} />
                            </div>
                            <div className={classes.usersContainer}>
                                <div className={classes.equalFlex}>
                                    <Users
                                        companyId={this.props.match.params.ID}
                                        company={this.state.company}
                                        building={this.state.building}
                                        users={this.state.building ? this.state.building.users : []}
                                        getBuilding={() => this.buildingChangedHandler(this.state.building._id)}
                                        getCompany={() => this.getCompany()} />
                                </div>
                                <div className={`${classes.equalFlex} ${classes.filesContainer}`}>
                                    <Files
                                        companyId={this.props.match.params.ID}
                                        buildingId={this.state.building._id}
                                        files={this.state.building.files && this.state.building.files.length ? this.state.building.files : []}
                                        getCompanyDetails={() => this.buildingChangedHandler(this.state.building._id)}
                                    />
                                </div>
                            </div>
                        </div> :
                        !this.state.renderPage && !this.state.building && this.state.noBuildings ?
                            <div className={classes.displayMessageContainer}>
                                <div className={classes.displayMessage}>
                                    <DisplayMessage
                                        title={language.noBuilding.title}
                                        message={language.noBuilding.message}
                                    />
                                </div>
                            </div> : <div className={classes.loadingContainer}>
                                <CircularProgress />
                            </div>
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    getById: id => dispatch(COMPANY_REQUESTS.getById(id)),
    getByIdWithBuilding: (companyId, buildingId) => dispatch(COMPANY_REQUESTS.getByIdWithBuilding(companyId, buildingId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyDetails))