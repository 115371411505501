import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'
import FileSaver from 'file-saver'

const COMPANY_ROUTE = '/companies'

export const get = query => dispatch => axios.get(`${COMPANY_ROUTE}?from=${query.from || ''}&limit=${query.limit || ''}&search=${query.search ? query.search : ''}&building=${query.building ? query.building : ''}&contractor=${query.contractor ? query.contractor : ''}&isRoot=${query.isRoot ? true : false}`)
    .then(response => {
        return { ...response.data }
    })
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${COMPANY_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getAnafData = cui => dispatch => axios.get(`${COMPANY_ROUTE}/anaf/${cui}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getBuildings = companyId => dispatch => axios.get(`${COMPANY_ROUTE}/${companyId}/buildings`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const addBuilding = (id, building) => dispatch => axios.put(`${COMPANY_ROUTE}/${id}/buildings`, { building })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(COMPANY_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const updateCompanyBuilding = (companyId, buildingId, building) => dispatch => axios.put(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`, { building })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const deleteCompanyFromBuilding = (companyId, buildingId) => dispatch => axios.delete(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getByIdWithBuilding = (companyId, buildingId) => dispatch => axios.get(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${COMPANY_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const edit = (id, newCompany) => dispatch => axios.put(`${COMPANY_ROUTE}/${id}`, { company: { ...newCompany } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadImage = (id, images) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/image`, images)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadFile = (id, buildingId, files) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/file/building/${buildingId}`, files)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const deleteFile = (id, buildingId, fileId) => dispatch => axios.delete(`${COMPANY_ROUTE}/${id}/file/building/${buildingId}/${fileId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadParentCompanyFile = (id, files) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/file`, files)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const deleteParentCompanyFile = (id, invoice) => dispatch => axios.delete(`${COMPANY_ROUTE}/${id}/invoice/${invoice._id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadOffer = (id, offer) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/offers`, { offer: offer })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const deleteOffer = (id, offerId) => dispatch => axios.delete(`${COMPANY_ROUTE}/${id}/offers/${offerId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const generatePDf = (id, companyName, offer) => dispatch => axios(`${COMPANY_ROUTE}/${id}/generate/pdf`, {
    method: 'POST',
    responseType: 'blob',
    data: {
        offer: offer
    }
})
    .then(response => {
        let blob = new Blob([response.data], { type: "application/pdf" })
        FileSaver.saveAs(blob, `Oferta-${companyName}.pdf`)
    })
    .catch(errorHandler => console.log(errorHandler))

export const deleteUserFromCompany = (companyId, buildingId, userId) => dispatch => axios.delete(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}/users/${userId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const download = (companyId, buildingId, fileId, filename) => dispatch => axios(`${COMPANY_ROUTE}/${companyId}/file/building/${buildingId}/${fileId}`, {
    method: 'GET',
    responseType: 'blob'
})
    .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
    })
    .catch(errorHandler)

export const downloadParentCompanyFile = (companyId, fileId, filename) => dispatch => axios(`${COMPANY_ROUTE}/${companyId}/file/${fileId}`, {
    method: 'GET',
    responseType: 'blob'
})
    .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
    })
    .catch(errorHandler)

export const setMailSettings = settings => dispatch => axios.post(`${COMPANY_ROUTE}/settings/mail`, settings)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getMailSettings = () => dispatch => axios.get(`${COMPANY_ROUTE}/settings/mail`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const setNotificationsSettings = settings => dispatch => axios.post(`${COMPANY_ROUTE}/settings/notifications`, settings)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getNotificationsSettings = () => dispatch => axios.get(`${COMPANY_ROUTE}/settings/notifications`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const downloadParentCompanyInvoice = (companyId, invoiceId, filename) => dispatch => axios(`${COMPANY_ROUTE}/${companyId}/invoice/${invoiceId}`, {
        method: 'GET',
        responseType: 'blob'
    })
        .then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
        .catch(errorHandler)