import React, { useState, useEffect } from 'react'
import { withStyles, Paper, Button, Typography } from '@material-ui/core'
import { Announcement as AnnouncementIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import InputGenerator from '../../common/InputGenerator'

import Header from '../../common/Header'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as NOTIFICATION from '../../../utils/notification'


let NotificationsSettings = props => {
    let { classes, language } = props
    let [settings, setSettings] = useState({})


    let onChangeHandler = event => {
        setSettings(prevState => ({ ...prevState, [event.target.name]: !prevState[event.target.name] }))
    }

    useEffect(() => {
        props.getNotificationsSettings()
            .then(data => {
                setSettings({ ...data })
            })
    }, [])

    let sendSettings = () => {
        props.setNotificationsSettings(settings)
            .then(() => NOTIFICATION.success(language.toastr.notifications.success))
            .catch(() => NOTIFICATION.error(language.toastr.notifications.error))
    }


    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header
                    title={props.language.header.notifications}
                    simple
                    profileText={props.language.headerTooltip.profile}
                />
            </div>
            <div className={classes.contentContainer}>
                <Paper className={classes.announcement}>
                    <AnnouncementIcon className={classes.announcementIcon} />
                    <div>
                        <Typography>
                            {props.language.info.notifications.active}<br />
                            {props.language.info.notifications.howToUse}
                        </Typography>
                    </div>
                </Paper>
                <Paper className={classes.form}>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveNewInfoFormNotification}
                            className={classes.checkbox}
                            name='receiveNewInfoForm'
                            onChange={onChangeHandler}
                            value={settings.receiveNewInfoForm || false}
                        />
                    </div>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveNewTicketNotification}
                            className={classes.checkbox}
                            name='receiveNewTicket'
                            onChange={onChangeHandler}
                            value={settings.receiveNewTicket || false}
                        />
                    </div>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveTicketModificationsNotification}
                            className={classes.checkbox}
                            name='receiveTicketModification'
                            onChange={onChangeHandler}
                            value={settings.receiveTicketModification || false}
                        />
                    </div>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveNewTicketFromInfoNotification}
                            className={classes.checkbox}
                            name='receiveNewTicketFromInformation'
                            onChange={onChangeHandler}
                            value={settings.receiveNewTicketFromInformation || false}
                        />
                    </div>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveDailyPmTicketsNotification}
                            className={classes.checkbox}
                            name='receiveDailyPmTicketStatistics'
                            onChange={onChangeHandler}
                            value={settings.receiveDailyPmTicketStatistics || false}
                        />
                    </div>
                    <div className={`${classes.checkbox} ${classes.importantCheckbox}`}>
                        <InputGenerator
                            type='checkbox'
                            label={props.language.labels.wantToReceiveAssignedTicketsNotification}
                            className={classes.checkbox}
                            name='receiveDailyOfficialTicketStatistics'
                            onChange={onChangeHandler}
                            value={settings.receiveDailyOfficialTicketStatistics || false}
                        />
                    </div>
                    <Button onClick={sendSettings} className={props.classes.button} variant='contained' color='primary'>{props.language.buttons.notifications}</Button>
                </Paper>
            </div>
        </div>
    )
}

let styles = () => ({
    container: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#f7f8fa',
        padding: '0px 25px 0px 30px',
        boxSizing: 'border-box'
    },
    header: {
        width: '100%',
        height: 70
    },
    form: {
        padding: 20
    },
    announcement: {
        padding: 20,
        display: 'flex',
        '& div': {
            marginLeft: 20
        },
        marginBottom: 30,
        marginTop: 30
    },
    checkbox: {
        position: 'relative',
        left: '-10px'
    },
    importantCheckbox: {
        paddingBottom: '10px',
        '& span': {
            fontWeight: '500 !important',
            fontSize: '13px'
        }
    },
    button: {
        marginTop: '20px'
    },
    announcementIcon: {
        fontSize: 30,
        color: 'rgba(0, 0, 0, 0.87)',
        marginTop: 2
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    setNotificationsSettings: settings => dispatch(COMPANY_REQUESTS.setNotificationsSettings(settings)),
    getNotificationsSettings: () => dispatch(COMPANY_REQUESTS.getNotificationsSettings())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsSettings))