import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Avatar } from '@material-ui/core'
import {
    Person as PeopleIcon,
    Add as CreateTicketIcon,
    Remove as SeparatorIcon
} from '@material-ui/icons'

import CreateTicketModal from '../tickets/modals/CreateTicketModal'
import { BACKEND_URL } from '../../utils/constants'
import EditUserModal from './EditUserModal/EditUserModal'

import '../../styles/Common.css'

const styles = theme => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    logoContainer: {
        flex: 1,
        marginLeft: '-50px'
    },
    contentContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: '#f6f7fa'
    },
    loggedUserText: {
        paddingRight: 12,
        cursor: 'pointer'
    },
    iconStyle: {
        fontSize: 38,
        cursor: 'pointer'
    },
    user: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

class Navbar extends Component {

    state = {
        openCreateTicket: false,
        openEditUser: false
    }

    creatTicketModalHandler = value => this.setState({ openCreateTicket: value })

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.logoContainer}>
                    <img width={200} src="/assets/logo.png" />
                </div>
                <div className={classes.contentContainer}>
                    <EditUserModal
                        open={this.state.openEditUser}
                        onCancel={() => this.setState({ openEditUser: false })}
                    />
                    <CreateTicketModal
                        creatTicketModalHandler={value => this.creatTicketModalHandler(value)}
                        open={this.state.openCreateTicket}
                    />
                    <CreateTicketIcon
                        className={classes.iconStyle}
                        onClick={() => this.creatTicketModalHandler(true)}
                    />
                    {/* <div className="verticalLine" /> */}
                    <SeparatorIcon className={`${classes.iconStyle} verticalLine`} />
                    <div className={classes.user} onClick={() => this.setState({ openEditUser: true })}>
                        <span className={classes.loggedUserText}>{`${this.props.loginReducer.username}`}</span>
                        {this.props.loginReducer.profileImagePath ?
                            <Avatar aria-label="recipe" src={`${BACKEND_URL}/static/${this.props.loginReducer.profileImagePath}`}>
                            </Avatar> : <PeopleIcon className={classes.iconStyle} />}
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = reducers => ({
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({

})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Navbar))