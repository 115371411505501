import React from 'react'
import { makeStyles } from '@material-ui/core'
import { AttachFile as FileIcon } from '@material-ui/icons'

const useStyles = makeStyles({
    attachIcon: {
        marginTop: '6px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    filesContainer: {
        listStyle: 'none',
        backgroundColor: '#fefefe',
        border: '1px solid #e0e0e0',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 0,
        '& li': {
            display: 'flex',
            lineHeight: '32px',
            '& button': {
                marginLeft: 'auto'
            },
            borderBottom: '1px solid #e0e0e0',
            '&:hover': {
                cursor: 'pointer',
                color: '#00458B !important'
            }
        }
    },
})

let Attachments = ({ files, id, onDownload }) => {
    let classes = useStyles()
    return files.length ? <ul className={classes.filesContainer}>
        {files.map((file, index) =>
            <li
                key={index}
                onClick={() => onDownload(id, file._id, file.originalName)}
            ><FileIcon className={classes.attachIcon} /> {file.originalName}</li>
        )}
    </ul> : null
}


export default Attachments