import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import { classes } from 'http-status'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)'
    }
})


export default props => {
    let classes = useStyles()

    return (
        <Card className={classes.root}>
            {props.children}
        </Card>
    )
}