import React, { Component } from 'react'
import { TextField, Button, CircularProgress, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'
import { COMPANY_DELETED_CODE } from '../../utils/constants'
import Form from '../common/FormGenerator'

import '../../styles/Login.css'
import HelpdeskLogo from '../../assets/mainLogo.png'

class Login extends Component {
    state = {
        isLoading: false,
        email: '',
        password: '',
        usernameTouched: false,
        passwordTouched: false,
        canSubmit: false,
        showResetModal: false,
        loading: false
    }

    login = () => this.props.login(this.state.email, this.state.password)
        .catch(err => {
            if (!err.response) {
                NOTIFICATION.error(this.props.languageReducer.serverDown)
            }
            else {
                if(err.message.includes(COMPANY_DELETED_CODE)) {
                    NOTIFICATION.error(this.props.languageReducer.companyDeleted)
                    this.setState({ isLoading: false })
                }
                else {
                    NOTIFICATION.error(this.props.languageReducer.invalidCredentials)
                    this.setState({ isLoading: false })
                }
            }
        })

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    onResetPasswordHandler = ({ email }) => {
        this.setState({ loading: true }, () => {
            this.props.recover(email)
                .then(() => {
                    this.setState({ showResetModal: false, loading: false })
                    NOTIFICATION.success(this.props.language.utils.passwordReseted)
                })
                .catch(() => {
                    this.setState({ loading: false })
                    NOTIFICATION.error(this.props.language.utils.failResetPassword)
                })
        })
    }

    render() {
        let { loginForm } = this.props.languageReducer
        let { classes } = this.props

        return (
            <div className='loginContainer'>
                <div className={classes.titleContainer}>
                    {/* <span className={classes.firstTitle}>{this.props.language.title}</span> */}
                    <img src={HelpdeskLogo} alt="Logo" style={{ width: 310 }} />
                    <span className={classes.secondTitle}>{this.props.language.secondTitle}</span>
                </div>
                <div className={classes.formContainer}>
                    <Form
                        open={this.state.showResetModal}
                        title={this.props.language.titles.resetPassword}
                        acceptButtonText={!this.state.loading ? this.props.language.buttons.reset : <div><CircularProgress className={this.props.classes.circularProgress} /><span className={this.props.classes.processText}>{this.props.language.utils.processing}</span></div>}
                        cancelButtonText={this.props.language.buttons.cancel}
                        withCancelButton
                        onAccept={this.onResetPasswordHandler}
                        fields={[
                            { value: '', type: 'text', label: this.props.language.labels.email, name: 'email' }
                        ]}
                        maxWidth='xs'
                        onCancel={() => this.setState({ showResetModal: false })}
                    />
                    <div className={classes.inputs}>
                        <TextField
                            variant="outlined"
                            className='loginInput'
                            margin="dense"
                            label={loginForm.email}
                            onChange={event => this.onChange(event)}
                            name='email'
                        />
                        <TextField
                            className='loginInput'
                            label={loginForm.password}
                            variant="outlined"
                            margin="dense"
                            type='password'
                            onChange={event => this.onChange(event)}
                            onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                            name='password'
                        />
                        <Button
                            className='loginButton'
                            variant='contained'
                            onClick={() => this.login()} color='primary'>
                            {this.state.isLoading ? <CircularProgress className={classes.whiteColor} size={24} /> : loginForm.login}
                        </Button>
                        <div
                            className={classes.forgottPassword}
                            onClick={() => this.setState({ showResetModal: true })}
                        >
                            <span>{loginForm.reset}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.createdByContainer}>
                    <p className={classes.cincizerodoititle}><a className={classes.aHref} href="http://502.ro">Un produs <span>502</span>.</a></p>
                </div>
            </div>
        )
    }
}

const styles = {
    whiteColor: {
        color: 'white'
    },
    createdByContainer: {
        position: 'fixed',
        top: '95%'
    },
    aHref: {
        textDecoration: 'none',
        color: 'inherit'
    },
    fontBold: {
        fontWeight: 'bold'
    },
    cincizerodoititle: {
        fontStyle: 'italic',
        fontSize: 12,
        color: 'rgba(0,0,0,0.5)',
        cursor: 'pointer'
    },
    forgottPassword: {
        width: '100%',
        fontSize: 12,
        textAlign: 'center',
        marginTop: 20,
        opacity: 0.7,
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        marginBottom: 20,
    },
    formContainer: {
        width: 500,
        height: 300,
        backgroundColor: '#ffffff',
        borderRadius: 8,
        marginTop: 20,
        marginBottom: 50,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid rgba(0,0,0,0.2)'
    },
    inputs: {
        width: '75%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    firstTitle: {
        fontSize: 48,
        letterSpacing: 5,
        fontWeight: 500,
        opacity: 0.7,
        paddingBottom: 12
    },
    secondTitle: {
        fontSize: 16,
        letterSpacing: 4
    },
    circularProgress: {
        height: '20px !important',
        width: '20px !important',
        color: 'white',
        marginRight: 5
    },
    processText: {
        position: 'relative',
        top: '-4px'
    }
}

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(LOGIN.login(username, password)),
    recover: email => dispatch(LOGIN.recover(email))
})

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.login,
    language: reducers.languageReducer.i18n
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))