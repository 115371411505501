import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import SimpleModal from '../../../common/SimpleModal'
import MultipleSelector from '../../../common/MultipleSelector'

import * as TEAM_REQUESTS from '../../../../redux/actions/teams'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as NOTIFICATION from '../../../../utils/notification'

const styles = theme => ({
    inputContainer: {
        paddingTop: '10px'
    }
})

class AddUsers extends Component {

    initialFields = [{ name: '', value: '', label: '', color: '' }]

    state = {
        openConfirmationModal: false,
        checkBoxes: this.initialFields,
        checkedUsers: []
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open)
            this.setState({ openConfirmationModal: nextProps.open })
        if (nextProps.open) {
            this.populateFields()
        }
    }

    componentDidMount() {
        this.populateFields()
        this.props.getTeam()
    }

    populateFields = async () => {
        let users
        if (this.props.team.members && this.props.team.members.length)
            users = await this.props.getUsers(this.props.team._id)
        else
            users = await this.props.getAllUsers()

        const usersToAdd = [].concat(users.users.map(user => ({
            name: user._id,
            value: false,
            label: `${user.firstName} ${user.lastName}`
        })
        ))
        this.setState({ checkBoxes: usersToAdd })
    }

    onCancelHandler = () => {
        this.props.getTeam()
        this.props.close()
    }

    onAcceptAddedUsers = () => {
        const membersCopy = [...this.props.team.members].map(team => ({ ...team }))
        if (this.state.checkedUsers && this.state.checkedUsers.length) this.state.checkedUsers.forEach(user => membersCopy.push(user.name))
        let newTeam = { team: { members: membersCopy } }
        if (this.state.checkedUsers && this.state.checkedUsers.length) {
            this.props.edit(this.props.team._id, newTeam)
                .then(() => {
                    this.props.getTeam()
                    this.props.close()
                    this.setState({ checkedUsers: [] })
                    NOTIFICATION.success(this.props.language.toastr.user.successCreate)
                })
                .catch(() => NOTIFICATION.error(this.props.language.toastr.user.errorCreate))
        }
        else NOTIFICATION.error(this.props.language.toastr.user.nothingSelected)

    }

    onChange = event => {
        let checkedUsersCopy = event.target.value.filter(checked => checked.value === true)
        this.setState({ checkBoxes: event.target.value, checkedUsers: checkedUsersCopy })
    }

    renderFields = () => {
        return (
            <div className={this.props.classes.inputContainer}>
                <MultipleSelector
                    utils={this.state.checkBoxes && this.state.checkBoxes.length ? this.props.language.utils.availableMembers : this.props.language.utils.noResults}
                    value={this.state.checkBoxes}
                    onChange={event => this.onChange(event)}
                />
            </div>
        )
    }

    render() {
        return (
            <div>
                <SimpleModal
                    open={this.state.openConfirmationModal}
                    acceptButtonText={this.props.language.buttons.add}
                    cancelButtonText={this.props.language.buttons.cancel}
                    title={this.props.language.titles.addMembersToTeam}
                    maxWidth={'sm'}
                    onCancel={() => this.onCancelHandler()}
                    onAccept={() => this.onAcceptAddedUsers()}
                >
                    {this.renderFields()}
                </SimpleModal>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    edit: (teamId, newTeam) => dispatch(TEAM_REQUESTS.edit(teamId, newTeam)),
    getUsers: teamId => dispatch(USER_REQUESTS.get({ all: true, teamId: teamId })),
    getAllUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUsers))