import React from 'react'
import { makeStyles, Tooltip, Button } from '@material-ui/core'
import { PhotoCamera as PhotoIcon } from '@material-ui/icons'

import Modal from './SimpleModal'
import InputGenerator from './InputGenerator'

let FormGenerator = props => {
    let classes = useStyles()
    let [fields, setFields] = React.useState(props.fields || [])

    let uploadRef = React.createRef();

    let triggerInput = () => {
        uploadRef.current.click();
    }

    React.useEffect(() => {
        setFields(props.fields)
        return () => { }
    }, [props.fields, props.open])

    let onFieldChange = event => {
        let newFields = [...fields]
        let fieldIndex = fields.findIndex(field => field.name == event.target.name)

        if (fieldIndex < 0) return

        if (event.target.name === 'phoneNumber')
            event.target.value = `${event.target.default}${event.target.value}`

        if (event.target.name === 'services') {
            let stringifiedTarget = JSON.stringify(event.target.value)
            let stringifiedOptions = JSON.stringify(fields[fieldIndex].options)

            fields[fieldIndex].defaultValue.forEach(field => {
                if (!stringifiedTarget.includes(JSON.stringify(field)))
                    if (!stringifiedOptions.includes(JSON.stringify(field)))
                        newFields[fieldIndex].options.push(field)
            })
        }

        newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let onAcceptHandler = () => {
        let data = {}

        fields.forEach(field => {
            if (field.type == 'fixedMultiSelect') {
                data[field.name] = field.value.map(f => f.label)
            } else data[field.name] = field.value
        })

        return data
    }

    return (
        <Modal
            open={props.open}
            title={props.title}
            acceptButtonText={props.acceptButtonText}
            cancelButtonText={props.cancelButtonText}
            deleteButtonText={props.deleteButtonText}
            withDeleteButton={props.withDeleteButton}
            withPhoto={props.withPhoto}
            imageTooltip={props.imageTooltip}
            onCancel={props.onCancel}
            onDelete={props.onDelete}
            maxWidth={props.maxWidth}
            onAccept={() => props.onAccept(onAcceptHandler())}
        >
            <div className={classes.container}>
                {fields.map((field, index) => (
                    <div className={classes.extraPadding} key={index}>
                        <InputGenerator
                            key={index}
                            margin="dense"
                            onChange={onFieldChange}
                            {...field}
                        />
                    </div>))
                }
                {props.withPhoto && <div>
                    <label htmlFor='imageUpload' >
                        <Tooltip placement={'top'} title={props.imageTooltip}>
                            <Button className={classes.photoButton} color='primary' variant="contained" onClick={triggerInput} ><PhotoIcon className={classes.photoIcon} /> {props.imageTooltip}</Button>
                        </Tooltip>
                    </label>
                    <input id='imageUpload' ref={uploadRef} type={'file'} onChange={props.imageUploadHandler} className={classes.imageInput}></input>
                </div>}
            </div>
        </Modal>
    )
}

let useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    extraPadding: {
        padding: '6px 0px 6px 0px',
        flex: 1
    },
    photoButton: {
        backgroundColor: '#F2F5FD',
        color: '#05092C',
        border: '1px solid rgba(0,0,0,0.2)'
    },
    photoIcon: {
        paddingRight: 8
    },
    imageInput: {
        display: 'none'
    }
})

export default FormGenerator