import React, { Component } from 'react';
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'

import Header from '../common/Header'
import InvoiceList from './list/List'

import * as CONSTANTS from '../../utils/constants'
import * as COMPANY_REQUESTS from '../../redux/actions/companies'

const styles = theme => ({
    container: {
        height: '100%',
        width: 'calc(100% - 216px)',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9FBFF',
        padding: '0px 25px 0px 30px',
        boxSizing: 'border-box'
    },
    noResultsText: {
        paddingLeft: 30,
        paddingTop: 20
    },
    header: {
        height: 70
    },
})

class Invoices extends Component {
    state = {
        invoices: [],
        initialInvoices: [],
        search: '',
        render: false
    }

    getInvoicesHandler = companyId => {
        this.props.getCompany(companyId).then(response => {
            this.setState({ invoices: response.invoices, initialInvoices: response.invoices, render: true })
        })
    }

    componentDidMount() {
        this.getInvoicesHandler(this.props.loginReducer.parentCompany)
    }

    setSearch = search => {
        this.setState({ search }, () => {
            this.onSearch()
        })
    }

    onSearch = () => {
        let filesCopy = [...this.state.initialInvoices].map(field => ({ ...field }))
        filesCopy = filesCopy.filter(file => (file.file.originalName.toLowerCase().search(this.state.search.toLowerCase()) > -1) || (file.description.toLowerCase().search(this.state.search.toLowerCase()) > - 1))
        filesCopy.length ? this.setState({ invoices: filesCopy }) : this.setState({ invoices: '' })
    }

    render() {
        let { classes } = this.props
        return this.state.render ? (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Header
                        withoutAdd
                        placeholderText={this.props.language.utils.searchInvoiceText}
                        title={this.props.language.titles.invoices}
                        profileText={this.props.language.headerTooltip.profile}
                        addText={this.props.language.headerTooltip.invoices}
                        onSearch={filter => this.setSearch(filter)}
                    />
                </div>
                <div>
                    {this.state.invoices.length ?
                    <InvoiceList
                        invoices={this.state.invoices}
                        getInvoices={id => this.getInvoicesHandler(id)}
                        id={this.props.loginReducer.parentCompany}
                    /> : <div className={classes.noResultsText} > <span>{this.props.language.utils.noResults}</span></div>}
                </div>
            </div>
        ) : null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getCompany: id => dispatch(COMPANY_REQUESTS.getById(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Invoices));