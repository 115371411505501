import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import {
    Delete as DeleteIcon,
    Business as BuildingIcon
} from '@material-ui/icons'

import * as CONSTANTS from '../../../utils/constants'
import * as TICKET_REQUESTS from '../../../redux/actions/tickets'

const styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardsContainer: {
        padding: '20px 30px'
    },
    cardContainer: {
        cursor: 'pointer',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        width: '100%',
        margin: '20px 0px',
        border: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
            backgroundColor: '#F2F2F2'
        }
    },
    infoFormData: {
        flex: 1,
        padding: '10px 0px 0px 10px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
    },
    firstRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 5
    },
    titleContainer: {
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    titleText: {
        color: '#4D8FDC',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 22,
        '& span': {
            color: '#000000',
            fontSize: '17px',
            marginLeft: '20px'
        }
    },
    secondRowContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    deleteContainer: {
        display: 'flex',
        paddingRight: '10px',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        fontSize: 16,
        cursor: 'pointer',
        marginRight: 10
    },
    typeText: {
        fontWeight: 500,
        fontSize: 12
    },
    displayText: {
        color: '#555555'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    buildingsContainer: {
        marginLeft: 'auto',
        paddingTop: '15px',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '500px',
    },
    buildingContainer: {
        width: 'max-content',
        border: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: '#f6f6f6',
        padding: '5px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: 'auto'
    },
    buildingText: {
        position: 'relative',
        top: 4,
        paddingLeft: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    infoFormShortDescription: {
        paddingBottom: 8,
        justifyContent: 'flex-end'
    },
    infoFormInformation: {
        fontWeight: 'normal',
        marginTop: 2,
        display: 'block'
    },
    authorAdditionalPadding: {
        paddingTop: 15
    },
    avatar: {
        width: 50,
        backgroundColor: '#e0e0e0',
        height: 50,
        borderRadius: '50%',
        marginTop: 10,
        marginRight: 10,
        backgroundSize: 'cover'
    },
    authorText: {
        fontSize: 16,
        fontWeight: 500
    },
    date: {
        display: 'block',
        marginTop: 20,
        fontSize: '12px',
        '& span': {
            fontWeight: 600
        },
        marginBottom: 10
    },
    ticketIcon: {
        width: 18,
        position: 'relative',
        bottom: '-2px'
    }
})

class InfoFormCards extends React.Component {

    onClickItem = (event, infoForm) => {
        if (infoForm.ticket)
            this.props.getTicketById(infoForm.ticket).then(result => {
                if (!result.deleted) {
                    this.props.history.push(`/tickets/${infoForm.ticket}`)
                }
                else {
                    this.props.onClick(infoForm)
                }
            })
        else {
            this.props.onClick(infoForm)
        }
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.cardsContainer}>
                    {this.props.infoForms.map(infoForm => {
                        return (
                            <div className={`${classes.cardContainer}`}>
                                <div className={classes.infoFormData}>
                                    <div className={classes.firstRowContainer}>
                                        <div onClick={(event) => this.onClickItem(event, infoForm)} className={classes.titleContainer}>
                                            <span className={classes.titleText}>{infoForm.subject} {infoForm.ticket ? <span><img src="assets/ticketsicon.png" className={classes.ticketIcon} /> #{infoForm.ticket.substr(CONSTANTS.TICKETS_ID_LENGTH)} </span> : ''}</span>
                                        </div>
                                        <Tooltip placement={"bottom"} title={this.props.language.tooltip.deleteInfoForm}>
                                            <Button className={classes.deleteContainer}><DeleteIcon id="deleteIcon" onClick={() => this.props.onDelete(infoForm._id)} /></Button>
                                        </Tooltip>
                                    </div>

                                    <div onClick={(event) => this.onClickItem(event, infoForm)} className={classes.secondRowContainer}>
                                        <div className={`${classes.flexRow}`}>
                                            <div className={`${classes.avatar}`} style={infoForm.author.profileImage ? { backgroundImage: `url(${CONSTANTS.BACKEND_URL}/static/${infoForm.author.profileImage.path})` } : {}}></div>
                                            <div className={`${classes.flexColumn}`}>
                                                <span className={`${classes.displayText} ${classes.authorAdditionalPadding}`}><span className={classes.authorText}>{infoForm.author.firstName} {infoForm.author.lastName.toUpperCase()}</span> {infoForm.author.company && infoForm.author.company.name ? `| ${infoForm.author.company.name}` : ''} </span>
                                                <span className={classes.displayText}><span className={classes.infoFormInformation}>{ReactHtmlParser(infoForm.information)}</span></span>
                                                <span className={classes.date}>{this.props.language.labels.createdAt} <span>{moment(infoForm.createdAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}</span></span>
                                            </div>
                                        </div>

                                        <div className={classes.buildingsContainer}>
                                            {
                                                infoForm.building ?
                                                    <div className={classes.buildingContainer}><BuildingIcon /><span className={classes.buildingText}>{infoForm.building.name}</span></div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getTicketById: id => dispatch(TICKET_REQUESTS.getById(id))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoFormCards)))