import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const INFO_FORM_ROUTE = '/infoForms'

export const create = data => dispatch => axios.post(INFO_FORM_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const get = query => dispatch => axios.get(`${INFO_FORM_ROUTE}?from=${query.from}&limit=${query.limit}&search=${query.search ? query.search : ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${INFO_FORM_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const update = (id, infoForm) => dispatch => axios.put(`${INFO_FORM_ROUTE}/${id}`, { infoForm })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${INFO_FORM_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadFiles = (id, form) => dispatch => axios.post(`${INFO_FORM_ROUTE}/${id}/files`, form)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)


export const createTicket = (id, ticket) => dispatch => axios.post(`${INFO_FORM_ROUTE}/${id}/ticket`, ticket)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const downloadFile = (infoId, fileId, filename) => dispatch => axios(`${INFO_FORM_ROUTE}/${infoId}/files/${fileId}`, {
    method: 'GET',
    responseType: 'blob'
})
    .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
    })
    .catch(errorHandler)