import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Tab } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)'
  },
});

let TabBar = ({ tabs }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs && tabs.map((tab, index) => <Tab label={tab.name} />)}
      </Tabs>
    </Paper>
  )
}

export default TabBar