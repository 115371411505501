import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import Cards from './cards/Cards'
import CreateCompanyModal from './modals/CreateCompany'
import ConfirmationDialog from '../common/ConfirmationDialog'
import Header from '../common/Header'
import Pagination from '../common/Pagination'
import List from './list/List'

import * as NOTIFICATIONS from '../../utils/notification'
import * as COMPANY_REQUESTS from '../../redux/actions/companies'
import * as CONSTANTS from '../../utils/constants'

const styles = theme => ({
    container: {
        width: 'calc(100% - 216px)',
        flex: 1,
        display: 'flex',
        backgroundColor: '#F9FBFF',
        flexDirection: 'column'
    },
    noResultsText: {
        paddingLeft: 30,
        paddingTop: 20
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 80px)',
        overflow: 'auto'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    titleText: {
        color: '#606771',
        fontSize: 30,
        fontWeight: 300
    },
    rightHeader: {
        marginLeft: 'auto'
    },
    searchInput: {
        width: '180px',
        marginRight: '27px',
        border: '1px solid rgba(0,0,0,0.15)',
        padding: '0px 6px'
    },
    removeUnderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        "&&&&:hover:before": {
            borderBottom: "none"
        }
    },
    addButton: {
        margin: '0px 10px'
    }
})

class Companies extends React.Component {

    companyToDelete = {}
    companyToEdit = {}

    state = {
        searchInput: '',
        openConfirmationModal: false,
        openModal: false,
        companies: [],
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE,
        edit: false
    }

    componentDidMount() {
        this.getCompaniesHandler()
    }

    getCompaniesHandler = filter => {
        return this.props.get({
            from: this.state.from,
            search: this.state.search,
            limit: this.state.rowsPerPage,
            isRoot: this.props.superAdmin ? true : false
        }).then(({ companies, count }) => {
            this.setState({
                count,
                companies
            })
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.getCompaniesHandler)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.getCompaniesHandler)
    }

    onCloseHandler = () => {
        this.getCompaniesHandler()
        this.setState({
            openModal: false,
            edit: false
        })
    }

    onSearchHandler = filter => {
        this.setState({ search: filter, from: 0 }, this.getCompaniesHandler)
    }

    onDeleteHandler = () => {
        this.props.delete(this.companyToDelete).then(() => {
            this.setState({ openConfirmationModal: false })
            this.getCompaniesHandler()
            NOTIFICATIONS.success(this.props.language.toastr.company.successDelete)
        })
            .catch(() => NOTIFICATIONS.error(this.props.language.toastr.company.errorDelete))
    }

    onEditHandler = company => {
        this.props.history.push(`/companies/${company._id}`)
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Header
                        placeholderText={this.props.language.utils.searchCompanyText}
                        title={this.props.language.titles.companies}
                        buttonText={this.props.language.buttons.create}
                        onSearch={filter => this.onSearchHandler(filter)}
                        onClick={() => this.setState({ openModal: true })}
                        addText={this.props.language.headerTooltip.company}
                        profileText={this.props.language.headerTooltip.profile}
                    />
                </div>
                <div className={classes.cardsContainer}>
                    <ConfirmationDialog
                        open={this.state.openConfirmationModal}
                        cancelButtonText={this.props.language.buttons.cancel}
                        acceptButtonText={this.props.language.buttons.delete}
                        text={`${this.props.language.utils.delete}`}
                        onCancel={() => this.setState({ openConfirmationModal: false })}
                        onClose={() => this.setState({ openConfirmationModal: false })}
                        onAccept={this.onDeleteHandler}
                    />
                    <CreateCompanyModal
                        open={this.state.openModal}
                        onClose={() => this.onCloseHandler()}
                        superAdmin={this.props.superAdmin ? true : false}
                        edit={this.state.edit}
                        companyId={this.companyToEdit}
                    />
                    {this.state.count > 0 ? this.props.superAdmin ? <List
                        onDelete={(companyId) => {
                            this.companyToDelete = companyId
                            this.setState({ openConfirmationModal: true })
                        }}
                        companies={this.state.companies}
                        onClick={(companyId) => {
                            this.companyToEdit = companyId
                            this.props.history.push(`/companies/${companyId}`)
                        }}
                    /> :
                        <Cards
                            onDelete={(companyId) => {
                                this.companyToDelete = companyId
                                this.setState({ openConfirmationModal: true })
                            }}
                            onEdit={(company) => this.onEditHandler(company)}
                            companies={this.state.companies} /> : <div className={classes.noResultsText} > <span>{this.props.language.utils.noResults}</span></div>}
                    <Pagination
                        from={this.state.from}
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    get: options => dispatch(COMPANY_REQUESTS.get(options)),
    delete: companyId => dispatch(COMPANY_REQUESTS.remove(companyId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Companies)))