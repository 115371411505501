import React from 'react'
import { withStyles } from '@material-ui/core'

const details = props => {
    let { classes } = props

    return (
        <div className={classes.container}>
            <h1>User details</h1>
        </div>
    )
}
const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    }
})

export default withStyles(styles)(details)