import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, CircularProgress } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import validator from 'validator'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'
import Attachments from '../../common/Attachments'
import AddInformation from '../../tickets/details/comments/AddComment'

import * as INFO_FORM_REQUESTS from '../../../redux/actions/infoForms'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'
import * as TAGS_REQUESTS from '../../../redux/actions/tags'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'

import { setImagesClickListeners } from '../../../utils/helpers'

const styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: ' 10px'
    },
    inputField: {
        flex: 1
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    descriptionText: {
        fontWeight: 500
    },
    descriptionInfo: {
        paddingTop: '18px'
    },
    imageStyle: {
        objectFit: 'contain',
        width: '100%'
    },
    circularColor: {
        color: "#ffffff !important"
    }
})

class InfoFormModal extends Component {

    priorityWithLabels = CONSTANTS.PRIORITY.map(priority => ({ ...priority, label: this.props.language.labels.priority[priority.name] }))

    initialInfoFormFields = [
        { value: '', type: 'text', label: this.props.language.labels.subject, name: 'subject' },
        { type: 'dropdownSelector', name: 'building', utils: this.props.language.labels.building, value: '', options: [] }
    ]


    initialTicketFields = [
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        {
            type: 'dropdownSelector',
            name: 'services',
            utils: this.props.language.labels.service,
            options: [],
            value: ''

        },
        { type: 'multicheckbox', name: 'priority', utils: this.props.language.labels.priority.priority, value: 'normal', options: this.priorityWithLabels },
        //{ value: '', type: 'text', label: this.props.language.labels.description, name: 'description' },
    ]

    state = {
        infoFormFields: this.initialInfoFormFields,
        ticketFields: this.initialTicketFields,
        building: {},
        done: false,
        reset: false,
        information: '',
        informationToSee: {},
        filesToUpload: [],
        openImageModal: false,
        imageSrc: '',
        isSending: false,
        openCreateTicket: false,
        creatingTicket: false
    }

    alreadyUploading = false

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && nextProps.edit && nextProps.infoForm) {
            this.getInformation(nextProps.infoForm._id)
        }

        if (nextProps.open && !nextProps.edit && nextProps.loginReducer.role === CONSTANTS.USER)
            this.getUserOfficialBuildings()

        if (nextProps.open && !nextProps.edit && nextProps.loginReducer.role === CONSTANTS.FM) {
            this.getFmBuildings()
        }
    }

    getInformation = informationId => {
        this.props.getInformation(informationId).then(info => {
            this.setState({ informationToSee: info, done: true }, () => {
                setTimeout(() => {
                    setImagesClickListeners(src => {
                        this.openImageModal(src)
                    })
                }, 0)
            })
        })
    }

    setInformation = newInformation => {
        this.setState({ information: newInformation, reset: false })
    }

    modifyOptions = (fields, toEdit, newValues) => {
        const infoFormCopy = [...this.state.infoFormFields].map(obj => ({ ...obj }))

        fields.forEach((field, position) => {
            const fieldIndex = infoFormCopy.findIndex(index => index.name === field)
            if (fieldIndex > -1) {
                infoFormCopy[fieldIndex][toEdit[position]] = newValues[position]
            }
        })

        this.setState({ infoFormFields: infoFormCopy })
    }

    getFmBuildings = async () => {
        await this.props.getBuilding()
            .then(buildings => {
                let mappedBuildings = [{ name: '', label: '', value: null }]
                    .concat(buildings.buildings
                        .filter(building => building.facilityManager ? building.facilityManager._id : null === this.props.loginReducer._id)
                        .map(singleBuilding => (
                            {
                                name: singleBuilding._id,
                                label: singleBuilding.name,
                                value: singleBuilding._id
                            })
                        ))

                this.modifyOptions(['building'], ['options'], [mappedBuildings])
                this.setState({ done: true })
            })
    }

    getUserOfficialBuildings = async () => {
        await this.props.getUserById(this.props.loginReducer._id)
            .then(user => {
                let mappedBuildings = [{ name: '', label: '', value: null }]
                    .concat(user.company.buildings
                        .filter(building => user.company.official === this.props.loginReducer._id ? true : building.official === this.props.loginReducer._id)
                        .map(singleBuilding => (
                            {
                                name: singleBuilding.building._id,
                                label: singleBuilding.building.name,
                                value: singleBuilding.building._id
                            })
                        ))

                this.modifyOptions(['building'], ['options'], [mappedBuildings])
                this.setState({ done: true })
            })
    }

    InputWrapper = ({ disabled, input, key, shrink, onChange, label }) => <div>
            <InputGenerator
                key={key}
                disabled={disabled}
                fullWidth={true}
                InputLabelProps={shrink ? { shrink: true } : {}}
                margin="dense"
                label={label ? label : null}
                {...input}
                onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
            />
        </div>

    onChangeHandler = async event => {
        const currentIndex = this.state.infoFormFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            let newFields = [...this.state.infoFormFields].map(obj => ({ ...obj }))

            newFields[currentIndex].value = event.target.value
            this.setState({ infoFormFields: newFields })
        }
    }

    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ infoFormFields: this.initialInfoFormFields })
    }

    onAcceptHandler = () => {
        if (this.alreadyUploading) return
        this.alreadyUploading = true
        let isOk = true
        let jsonMap = {}
        this.state.infoFormFields.forEach(field => {
            jsonMap[field.name] = field
        })

        let fieldsCopy = [...this.state.infoFormFields].map(field => ({ ...field, error: false }))
        fieldsCopy.forEach(field => {
            switch (field.name) {
                case 'subject':
                    if (validator.isEmpty(jsonMap['subject'].value)) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'building':
                    if (validator.isEmpty(jsonMap['building'].value)) {
                        field.error = true
                        isOk = false
                    }
                    break
                default:
                    break
            }
        })
        this.setState({ infoFormFields: fieldsCopy })

        if (!isOk) {
            this.alreadyUploading = false
            return NOTIFICATIONS.error(this.props.language.toastr.infoForm.errors)
        }

        this.setState({ isSending: true })
        const infoFormToAdd = {
            subject: jsonMap['subject'].value,
            information: this.state.information,
            author: this.props.loginReducer._id,
            building: jsonMap['building'].value || null
        }

        this.props.create({ infoForm: infoFormToAdd }).then(information => {
            if (this.state.filesToUpload.length) {
                let form = new FormData()
                this.state.filesToUpload.forEach((file, index) => {
                    form.append(`file-${index}`, file)
                })

                this.props.uploadFiles(information._id, form).then(() => {
                    this.alreadyUploading = false
                    NOTIFICATIONS.success(this.props.language.toastr.infoForm.successCreate)
                    this.setState({ isSending: false })
                    this.props.getForms()
                })
            } else {
                this.alreadyUploading = false
                NOTIFICATIONS.success(this.props.language.toastr.infoForm.successCreate)
                this.setState({ isSending: false })
                this.props.getForms()
            }
            this.setState({ infoFormFields: this.initialInfoFormFields, reset: true, information: '', informationToSee: {}, reset: true, filesToUpload: [] })
            this.onCloseHandler()
        })
            .catch(() => {
                this.setState({ done: true, filesToUpload: [] })
                if (validator.isEmpty(this.state.information)) {
                    this.alreadyUploading = false
                    NOTIFICATIONS.error(this.props.language.toastr.infoForm.errorDescription)
                }
                else
                    NOTIFICATIONS.error(this.props.language.toastr.infoForm.errorCreate)
                this.setState({ isSending: false })
            })
    }

    onDownloadImage = () => {
        let a = document.createElement("a")
        a.href = this.state.imageSrc
        let extension = this.state.imageSrc.split(';')[0].split('/').pop()
        a.download = `Image.${extension}`
        a.click()
        this.setState({ openImageModal: false })
    }

    setFiles = files => {
        this.setState({ filesToUpload: files })
    }

    createTicket = () => {
        this.setState({ creatingTicket: true })
        let ticketJsonMap = {}
        let allGood = true
        this.state.ticketFields.forEach(field => {
            ticketJsonMap[field.name] = field.value
            if (!field.value) allGood = false
        })

        if (!allGood) {
            return NOTIFICATIONS.error(this.props.language.utils.completeAllFroms)
        }

        this.props.createTicket(this.state.informationToSee._id, ticketJsonMap).then(response => {
            this.setState({
                informationToSee: {},
                openCreateTicket: false
            })
            this.props.onClose()
            this.props.getForms()
            this.setState({ creatingTicket: false })
            NOTIFICATIONS.success(this.props.language.toastr.infoForm.successCreateTicket)
        }).catch(err => {
            this.setState({ creatingTicket: false })
            NOTIFICATIONS.error(this.props.language.toastr.infoForm.errorCreateTicket)
        })
    }

    getTicketTags = () => this.props.getTags('ticket')

    getBuildingServices = buildingId => this.props.getBuildingServices(buildingId).then(data => data.services)

    openCreateTicketModal = () => {
        if (!this.state.informationToSee.building) return

        Promise.all([
            this.getTicketTags(),
            this.getBuildingServices(this.state.informationToSee.building._id)
        ]).then(([tags, services]) => {

            let ticketFieldsCopy = [...this.state.ticketFields].map(obj => ({ ...obj }))

            let tagsIndex = this.state.ticketFields.findIndex(field => field.name === 'type')
            let servicesIndex = this.state.ticketFields.findIndex(field => field.name === 'services')

            const tagsTicket = [{ name: '', value: false, label: '' }].concat(tags.map(tag => ({
                _id: tag._id,
                name: tag.name,
                value: tag._id,
                label: tag.name
            })))

            const tagsService = [{ name: '', value: false, label: '' }].concat([...services.map(tag => ({
                _id: '',
                name: tag,
                value: false,
                label: tag
            }))])

            ticketFieldsCopy[servicesIndex].options = tagsService
            ticketFieldsCopy[tagsIndex].options = tagsTicket

            this.setState({ openCreateTicket: true, ticketFields: ticketFieldsCopy })
        })
    }

    onChangeTicket = event => {
        const currentIndex = this.state.ticketFields.findIndex(index => index.name === event.target.name)
        const ticketFieldsCopy = this.state.ticketFields.map(field => ({ ...field }))
        ticketFieldsCopy[currentIndex].value = event.target.value
        this.setState({ ticketFields: ticketFieldsCopy })
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        let ticketJsonMap = {}

        this.state.infoFormFields.forEach(field => {
            jsonMap[field.name] = field
        })

        this.state.ticketFields.forEach(field => {
            ticketJsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.flexColumn}>
                <SimpleModal
                    maxWidth={'sm'}
                    title={this.props.language.titles.createTicket}
                    open={this.state.openCreateTicket}
                    onCancel={() => this.setState({ openCreateTicket: false })}
                    onClose={() => this.setState({ openCreateTicket: false })}
                    cancelButtonText={this.props.language.buttons.close}
                    acceptButtonText={this.state.creatingTicket ? <CircularProgress color="primary" classes={{ colorPrimary: this.props.classes.circularColor }} size={22} /> : this.props.language.buttons.create}
                    onAccept={!this.state.creatingTicket ? this.createTicket : () => { }}
                >
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={ticketJsonMap['priority']} key={'priority'} onChange={value => this.onChangeTicket({ target: { name: 'priority', value } })} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`} >
                        <InputWrapper shrink={true} input={ticketJsonMap['type']} key={'type'} onChange={this.onChangeTicket} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={ticketJsonMap['services']} key={'services'} onChange={this.onChangeTicket} />
                    </div>
                </SimpleModal>
                <SimpleModal
                    size="lg"
                    title={this.props.language.titles.ticketImage}
                    withCancelButton={false}
                    open={this.state.openImageModal}
                    onCancel={() => this.setState({ openImageModal: false })}
                    onClose={() => this.setState({ openImageModal: false })}
                    cancelButtonText={this.props.language.buttons.close}
                    acceptButtonText={this.props.language.buttons.download}
                    onAccept={this.onDownloadImage}>
                    <img className={this.props.classes.imageStyle} src={this.state.imageSrc} />
                </SimpleModal>
                {!this.props.edit ? <>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['subject']} key={'subject'} disabled={this.props.edit} />
                    </div>
                    <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                        <InputWrapper shrink={true} input={jsonMap['building']} key={'building'} disabled={this.props.edit} />
                    </div> </> : null}
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <span className={this.props.classes.descriptionText}>{this.props.language.labels.description.toUpperCase()}</span>
                    <div className={this.props.classes.descriptionInfo}>
                        {this.props.edit ?
                            <>
                                {ReactHtmlParser(this.state.informationToSee.information)}
                                <Attachments
                                    files={this.state.informationToSee.files || []}
                                    id={this.state.informationToSee.id}
                                    onDownload={this.props.downloadInformationFiles}
                                />
                            </> :
                            <AddInformation
                                setFiles={files => this.setFiles(files)}
                                showFilesUpload={true}
                                setComment={info => this.setInformation(info)}
                                reset={this.state.reset} />}
                    </div>
                </div>
            </div>
        )
    }

    openImageModal = src => {
        this.setState({
            openImageModal: true,
            imageSrc: src
        })
    }

    render() {

        if (this.state.done) {
            let acceptButtonText = null
            let isPM = this.props.loginReducer.role === CONSTANTS.PM || this.props.loginReducer.role === CONSTANTS.ADMIN

            if (this.state.isSending) {
                acceptButtonText = <CircularProgress size={22} />
            } else {
                if (this.props.edit) {
                    if (isPM) {
                        acceptButtonText = this.props.language.buttons.transformToTicket
                    } else {
                        acceptButtonText = null
                    }
                } else {
                    acceptButtonText = this.props.language.buttons.create
                }
            }

            return <>
                <SimpleModal
                    open={this.props.open}
                    onCancel={() => this.onCloseHandler()}
                    onAccept={() => this.props.edit ? this.openCreateTicketModal() : this.onAcceptHandler()}
                    acceptButtonText={acceptButtonText}
                    disabledAcceptButton={this.state.isSending}
                    disable
                    cancelButtonText={this.props.edit ? null : this.props.language.buttons.cancel}
                    canSubmitForm={true}
                    maxWidth={'sm'}
                    title={this.props.edit ? this.props.infoForm.subject : this.props.language.titles.createInfoForm}
                >
                    <>
                        {this.renderFields()}
                    </>
                </SimpleModal>

            </>
        } else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    create: infoForm => dispatch(INFO_FORM_REQUESTS.create(infoForm)),
    getInformation: infoId => dispatch(INFO_FORM_REQUESTS.getById(infoId)),
    getUserById: userId => dispatch(USER_REQUESTS.getMe(userId)),
    getBuilding: () => dispatch(BUILDING_REQUESTS.get({ all: true })),
    uploadFiles: (id, form) => dispatch(INFO_FORM_REQUESTS.uploadFiles(id, form)),
    createTicket: (infoId, ticket) => dispatch(INFO_FORM_REQUESTS.createTicket(infoId, ticket)),
    downloadInformationFiles: (id, fileId, originalName) => dispatch(INFO_FORM_REQUESTS.downloadFile(id, fileId, originalName)),
    getBuildingServices: id => dispatch(BUILDING_REQUESTS.getBuildingServices(id)),
    getTags: type => dispatch(TAGS_REQUESTS.getAll({ type }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoFormModal))