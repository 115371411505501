import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText, ListItemIcon, Collapse, withStyles, Button } from '@material-ui/core'
import { FolderOutlined as FolderOutlinedIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Menu } from '@material-ui/icons'

import HelpdeskLogo from '../../assets/weSolvvoLogoMotto.jpeg'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        flexDirection: 'column'
    },
    header: {
        height: 70,
        display: 'flex',
        justifyContent: 'center',
    },
    headerContent: {
        borderBottom: '0.8px solid #D5D5D5',
        width: '90%',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    bottomButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2
    },
    sidebarHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sidebarHeaderContent: {
        width: '90%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee'
    },
    sidebarList: {
        paddingTop: 29,
        width: '100%',
        height: '70%',
        display: 'flex',
        justifyContent: 'center'
    },
    listItemText: {
        fontWeight: 350,
        fontStyle: 'normal',
        fontStretch: 'normal',
        padding: 0,
        fontSize: 16
    },
    isSelected: {
        color: '#ffffff',
        fontWeight: 500
    },
    sidebarIcon: {
    },
    logoutButton: {
        color: '#3e3f42',
        backgroundColor: theme.palette.mainColor,
        position: ' absolute',
        bottom: '10px'
    },
    userAccount: {
        display: 'flex',
        borderBottom: '1px solid white'
    },
    username: {
        fontSize: '18px',
        lineHeight: '47px',
        textTransform: 'capitalize'
    },
    displayNone: {
        display: 'none'
    },
    languageSelector: {
        marginLeft: 'auto'
    },
    listWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    listElements: {
        width: '100%',
        fontFamily: 'Montserrat',
    },
    headerText: {
        padding: 34,
        fontWeight: 500,
        color: 'white'
    },
    logo: {
        '& img.image': {
            height: '60px'
        },
        '& img.text': {
            height: '30px',
            marginBottom: '15px'
        },
        marginLeft: '15px'
    },
    closeSidebarButton: {
        color: 'white',
        cursor: 'pointer'
    },
    noSidebarContainer: {
        backgroundColor: '#FBFBFA',
        width: 40
    },
    noSidebar: {
        height: 46,
        paddingTop: 24,
        paddingLeft: 14,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: 'white'
    },
    openSidebarButton: {
        cursor: 'pointer'
    },
    logo: {
        padding: '10px'
    },
    logoText: {
        width: '50%',
        height: '100%',
        display: 'flex',
        fontStyle: 'Montserrat',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        lineHeight: 10,
        textalign: 'right',
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
    },
    logoStyle: {
        width: '100%'
    }
})

class Sidebar extends Component {
    state = {
        selected: {},
        expanded: {},
        displayNone: false
    }

    componentDidMount() {
        this.handlePathExpansion()
        this.expandOnlyOneOption(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.expandOnlyOneOption(nextProps)
        if (this.props.ticketReducer.ticketType !== nextProps.ticketReducer.ticketType) {
            this.handleExpandClick('tickets')
            this.handleSelect(nextProps.ticketReducer.ticketType)
        }
    }

    expandOnlyOneOption = data => {
        let hasAccessItems = data.listItems.filter(item => item.hasAccess)
        if (hasAccessItems && hasAccessItems.length === 1) {
            this.handleExpandClick(hasAccessItems[0].label)
        }
    }

    handlePathExpansion = (openFromDashboard) => {
        const currentPathname = this.props.location.pathname

        if (currentPathname.length === 1) {
            this.handleSelect('dashboard')
        }
        else {
            const labels = currentPathname.split('/')
            var currentLabel = labels.pop()
            if(labels.length > 1) {
                currentLabel = labels.pop()
            }
            this.handleSelect(currentLabel)
            labels.forEach(label => {
                this.handleExpand(label)
            })
        }
    }

    handleSelect = (value) => {
        let selectedCopy = { ...this.state.selected }
        Object.keys(selectedCopy).forEach(element => {
            selectedCopy[element] = false
        })
        selectedCopy[value] = !selectedCopy[value]
        this.setState({ selected: selectedCopy }, () => {
            const expandedKeys = Object.keys(this.state.expanded)
            expandedKeys.forEach(key => {
                if (key === 'tickets' && this.state.expanded[key] && value === 'dashboard') {
                    this.handleExpand('tickets')
                }
            })
        })
    }


    handleExpand = (value) => {
        let expandCopy = { ...this.state.expanded }
        let expandCopyKeys = Object.keys(expandCopy)
        if (expandCopyKeys && expandCopyKeys.length) {
            expandCopyKeys.forEach(key => {
                if (key !== value) expandCopy[key] = false
            })
        }
        expandCopy[value] = !expandCopy[value]
        this.setState({ expanded: expandCopy })
    }

    handleExpandClick = value => {
        this.handleExpand(value)
        this.handleSelect(value)
    }

    render() {
        const { classes } = this.props

        const ListItemComponent = props => <ListItem
            component={props.to ? Link : 'div'}
            to={props.to}
            button
            selected={this.state.selected[props.label]}
            onClick={() => !props.nested ? this.handleSelect(props.label) : this.handleExpandClick(props.label)}>
            <ListItemIcon
                className={`${this.state.selected[props.label] ? classes.isSelected : ''}`}>
                {props.icon ? props.icon : <FolderOutlinedIcon />}
            </ListItemIcon>
            <ListItemText
                classes={{ primary: `${this.state.selected[props.label] ? classes.isSelected : ''} ${classes.listItemText}` }}
                primary={props.text} />
            {props.nested && !this.props.superAdmin ? props.open ? <ExpandLessIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : <ExpandMoreIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : null}
        </ListItem>

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerContent}>
                        <div className={classes.logo}>
                            <img src={HelpdeskLogo} alt="Helpdesk" className={classes.logoStyle} />
                        </div>
                    </div>
                </div>
                <div className={classes.sidebarList}>
                    <List component='nav' className={classes.listWrapper}>
                        <div className={classes.listElements}>
                            {this.props.listItems.map(item => {
                                if (item.hasAccess) {
                                    if (!item.nested) {
                                        return (<div key={item.label}><ListItemComponent
                                            {...item}
                                            text={this.props.language[item.label]}
                                            open={this.state.expanded[item.expandedText]} /></div>)
                                    }
                                    if (item.nested) {
                                        return (<div key={item.label}>
                                            <ListItemComponent {...item} text={this.props.language[item.label][item.label]} nested open={this.state.expanded[item.expandedText]} />
                                            {!this.props.superAdmin ? <Collapse
                                                in={this.state.expanded[item.expandedText]}
                                                timeout='auto'
                                                unmountOnExit>
                                                <List
                                                    className={classes.nested}
                                                    component='div'
                                                    disablePadding>
                                                    {item.nestedComponents.map(component => <ListItemComponent key={component.label} {...component} text={this.props.language[item.label][component.label]} />)}
                                                </List>
                                            </Collapse> : null }
                                        </div>)
                                    }
                                }
                                return null
                            })}
                        </div>
                    </List>
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.sidebar,
    ticketReducer: reducers.ticketsReducer
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Sidebar)))