import React, { Component } from 'react';
import { connect } from 'react-redux'

import moment from 'moment'

import * as CONSTANTS from '../../utils/constants'
import * as TICKET_REQUESTS from '../../redux/actions/tickets'
import * as NOTIFICATIONS from '../../utils/notification'

import { Close as CloseIcon } from '@material-ui/icons'

import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  DayView,
  AllDayPanel,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AppointmentForm,
  AppointmentTooltip,
  TodayButton,
  MonthView,
  Resources,
  DragDropProvider
} from '@devexpress/dx-react-scheduler-material-ui';
import { Dialog, DialogContent, DialogTitle, Button } from '@material-ui/core';


const styles = theme => ({
  flexContainer: {
    padding: '1px 24px',
  },
  divContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    paddingRight: '10px'
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  }

})


class Calendar extends Component {
  state = {
    data: [],
    currentViewName: this.props.language.calendar.day,
    currentDate: null,
    editedData: []

  }
  resources = [
    {
      fieldName: "type",
      title: "Type",
      instances: [
        { id: CONSTANTS.TICKET_STATUSES.NEW, text: this.props.language.labels.status.new, color: CONSTANTS.STATUS_COLORS.NEW },
        { id: CONSTANTS.TICKET_STATUSES.PENDING, text: this.props.language.labels.status.pending, color: CONSTANTS.STATUS_COLORS.PENDING },
        { id: CONSTANTS.TICKET_STATUSES.VERIFY, text: this.props.language.labels.status.verify, color: CONSTANTS.STATUS_COLORS.VERIFY },
        { id: CONSTANTS.TICKET_STATUSES.CLOSED, text: this.props.language.labels.status.new, color: CONSTANTS.STATUS_COLORS.CLOSED }
      ]
    }
  ];
  currentViewNameChange = (viewName) => {
    this.setState({ currentViewName: viewName });
  };

  handleTickets = () => {
    this.props.getTickets().then(response => {
      let dataCopy = Object.values(response).filter(ticket => {
        return ticket.startTime ? ticket.endTime ? true : false : false
      }).map(entry => ({
        id: entry._id,
        title: `${entry.subject}/${entry.building.name}`,
        type: entry.status,
        startDate: entry.startTime,
        endDate: entry.endTime,
        edited: entry.edited
      }))
      this.setState({ data: dataCopy })
    })
  }

  componentDidMount() {
    if (this.props.user.role !== CONSTANTS.SUPER_ADMIN)
      this.handleTickets()
  }

  setDayView = currentDate => {
    this.setState({ currentViewName: this.props.language.calendar.day, currentDate: currentDate })
  }

  TimeTableCell = props => {
    return <MonthView.TimeTableCell
      onDoubleClick={() => this.setDayView(props.startDate)}
      {...props}
    />
  }

  TimeScaleLayout = props => {
    return <DayView.TimeScaleLayout
      height={0}
      {...props}
    />
  }

  dateFormatter = (day, hour) => {
    return `${day}T${hour}`
  }

  currentDateChange = currentDate => {
    this.setState({ currentDate: currentDate })
  }

  commitChanges = ({ added, changed, deleted }) => {
    let data = this.state.data
    let edited = true

    if (changed) {
      data = data.map(appointment => (
        changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment)
      )
      data.forEach(appointment => {
        if (changed[appointment.id]) {
          if(this.props.user.role === CONSTANTS.PM){
            if(appointment.edited === false)
              edited = false
          }
          let day = moment(appointment.startDate).format(CONSTANTS.INPUT_TYPE_DATE_FORMAT)

          if (moment(changed[appointment.id].startDate).format(CONSTANTS.HOUR_TIME_FORMAT) === '00:00') {
            let newDate = this.state.data.filter(entry => entry.id === appointment.id)

            let startTime = moment(newDate[0].startDate).format(CONSTANTS.HOUR_TIME_FORMAT)
            let endTime = moment(newDate[0].endDate).format(CONSTANTS.HOUR_TIME_FORMAT)
            let startDate = new Date(this.dateFormatter(day, startTime))
            let endDate = new Date(this.dateFormatter(day, endTime))

            this.props.scheduleTicket(appointment.id, startDate, endDate, edited).then(() => {
              NOTIFICATIONS.success(this.props.language.toastr.ticket.successSchedule)
              this.setState({editedData : [...this.state.editedData, ...[ appointment.id] ] })
              this.handleTickets()
            })
          }
          else {

            this.props.scheduleTicket(appointment.id, appointment.startDate, appointment.endDate, edited).then(() => {
              NOTIFICATIONS.success(this.props.language.toastr.ticket.successSchedule)
              this.setState({editedData : [...this.state.editedData, ...[ appointment.id] ] })
              this.setState({ data: data })
            })
          }
        }

      })
    }
  }
  allowDrag = ({ id }) => {
    let secondCheck = this.state.editedData.includes(id)
    if (this.props.user.role === CONSTANTS.ROLES.PM)
      return true
    return !this.findData(id)[0].edited === true && !secondCheck ? true : false
  }   

  findData = id => this.state.data.filter(data => data.id === id)

  appointmentComponent = props => {
      if (this.allowDrag(props.data) ) {
        return <Appointments.Appointment {...props} />;
      } return <Appointments.Appointment {...props} style={{ ...props.style, cursor: 'not-allowed' }} />;
  }

  render() {
    let { classes } = this.props
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={this.props.maxWidth || 'md'}
        onCancel={this.props.onCancel}
      >
        <DialogContent >
          <DialogTitle className={classes.flexContainer}>
            <div className={classes.divContainer}>
              <span className={classes.titleContainer}>{this.props.language.titles.calendar}</span>
              <div className={classes.buttonContainer}> 
              <Button >
                <CloseIcon onClick={this.props.onCancel} />
              </Button>
              </div>
            </div>
          </DialogTitle>
          <Paper
            className={"calendarContainer"}  
          >
            <Scheduler
              locale={"ro-RO"}
              data={this.state.data}         
            >
              <ViewState
                currentDate={this.state.currentDate ? this.state.currentDate : new Date()}
                currentViewName={this.state.currentViewName}
                onCurrentDateChange={this.currentDateChange}
                onCurrentViewNameChange={this.currentViewNameChange}
              />
              <EditingState
                onCommitChanges={this.commitChanges}
              />
              <IntegratedEditing />
              <DayView
                name={this.props.language.calendar.day}
                startDayHour={7.5}
                endDayHour={22.5}
                timeScaleLayoutComponent={this.TimeScaleLayout}

              />
              <WeekView
                name={this.props.language.calendar.week}
                startDayHour={7.5}
                endDayHour={22.5}
              />
              <MonthView
                name={this.props.language.calendar.month}
                timeTableCellComponent={this.TimeTableCell}
              />
              <AllDayPanel 
                messages={{allDay: this.props.language.calendar.allDay}}
              />
              <Appointments 
                appointmentComponent={this.appointmentComponent}
              />
              <Resources data={this.resources} />
              <Toolbar />
              <DateNavigator />
              <TodayButton
                messages={{today: this.props.language.calendar.today}}
              />
              <ViewSwitcher
                currentView={this.state.currentViewName}
              />
              <AppointmentTooltip />
              {this.props.user.isOfficial || this.props.user.role === CONSTANTS.PM || this.props.user.role === CONSTANTS.FM ? 
                <DragDropProvider
                  allowDrag={this.allowDrag}
                  allowResize={() => false} 
                /> : null}
            </Scheduler>
          </Paper>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = reducers => ({
  language: reducers.languageReducer.i18n,
  user: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
  getTickets: () => dispatch(TICKET_REQUESTS.getTicketsForCalendar()),
  scheduleTicket: (id, day, startTime, endTime, edited) => dispatch(TICKET_REQUESTS.schedule(id, day, startTime, endTime, edited))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Calendar))