import React from 'react'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    inputContainer: {
        padding: '7px 14px 7px 58px !important',
        width: '100% !important',
        borderColor: '#e2e5ed !important'
    },
    error: {
        padding: '7px 14px 7px 58px !important',
        width: '100% !important',
        borderColor: '#f44336 !important',
        '&:focus': {
            boxShadow: '0 0 0 1px #f44336 !important'
        }
    },
    labelError: {
        '& .special-label':{
            color: '#f44336 !important'
        }
    }
})

class PhoneSelector extends React.Component {
    render() {
        return <div>
            <PhoneInput
                value={this.props.value}
                inputClass={this.props.error ? this.props.classes.error : this.props.classes.inputContainer}
                containerClass={this.props.error ? this.props.classes.labelError : ''}
                specialLabel={this.props.label}
                country={'ro'}
                countryCodeEditable={false}        
                onChange={values => this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: values,
                        default: this.props.default
                    }
                })}
            />
        </div>
    }   
}

export default withStyles(styles)(PhoneSelector);