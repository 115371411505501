import React, { useState, useEffect } from 'react'
import { withStyles, Button, Tooltip, CircularProgress } from '@material-ui/core'
import { PhotoCamera as PhotoIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import validator from 'validator'

import InputGenerator from '../../common/InputGenerator'
import SimpleModal from '../../common/SimpleModal'

import { ROLES } from '../../../utils/constants'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as NOTIFICATION from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'

let InputWrapper = props => {
    return <div>
        <InputGenerator
            key={props.key}
            fullWidth={true}
            InputLabelProps={props.shrink ? { shrink: true } : {}}
            margin="dense"
            {...props.input}
            value={props.value}
            onChange={props.onChange}
            {...props}
        />
    </div>
}

let modifiedEmail = null

let Create = props => {
    let initialFields = [
        { value: '', type: 'text', label: props.language.labels.name, name: 'firstName' },
        { value: '', type: 'text', label: props.language.labels.midName, name: 'lastName' },
        { value: '', type: 'text', label: props.language.labels.title, name: 'title' },
        { value: '', type: 'text', label: props.language.labels.email, name: 'email' },
        { value: '', type: 'phone', label: props.language.labels.phone, name: 'phoneNumber', default: '+' },
        {
            type: 'dropdownSelector',
            name: 'role',
            utils: props.language.labels.role,
            value: '',
            options: [{ name: '', value: true, label: '' }].concat(Object.values(ROLES).map(role => ({ name: role, value: false, label: role }))),
        },
        {
            type: 'dropdownSelector',
            name: 'company',
            utils: props.language.labels.company,
            value: null,
            options: [],
        },
        {
            type: 'dropdownSelector',
            name: 'building',
            utils: props.language.labels.building,
            value: null,
            options: [],
        }
    ]

    let { classes } = props
    let [fields, setFields] = useState(initialFields)
    let [formDataProfileImage, setFormDataProfileImage] = useState(null)
    let [loading, setLoading] = useState(false)

    let populateCompaniesField = (newFieldsProps) => {
        return props.getCompanies(props.superAdmin ? { isRoot: true } : {})
            .then(({ companies }) => {
                let newFields = newFieldsProps ? [...newFieldsProps] : [...fields]
                let companyFieldIndex = fields.findIndex(field => field.name === 'company')

                if (!(companyFieldIndex > -1)) return

                if (companies.length) {
                    newFields[companyFieldIndex].options = [{ name: null, label: '', value: false }].concat(companies.map(company => ({ name: company._id, label: company.name, _id: company._id })))
                }
                return newFields
            })
    }

    useEffect(() => {

        //allow to create only admins

        modifiedEmail = false
        let newFields = [...fields]
        if (!props.user) {
            if (props.superAdmin) {
                let roleIndex = newFields.findIndex(field => field.name === 'role')
                newFields[roleIndex].options = [{ name: 'admin', label: props.language.labels.userRole.admin, value: true }]
                newFields[roleIndex].value = 'admin'
            }

            populateCompaniesField(newFields).then(newFields => {
                populate(newFields)
            }).catch(err => {
                console.log(err)
                setFields(newFields)
            })

        } else {
            // Check if opened for edit

            if (props.user) {
                if (props.superAdmin) {
                    let roleIndex = newFields.findIndex(field => field.name === 'role')
                    if (props.user._id === props.loginReducer._id) {
                        newFields[roleIndex].options = [{ name: 'superAdmin', label: props.language.labels.userRole.superAdmin, value: true }]
                        newFields[roleIndex].value = 'superAdmin'
                    } else {
                        newFields[roleIndex].options = [{ name: 'admin', label: props.language.labels.userRole.admin, value: true }]
                        newFields[roleIndex].value = 'admin'
                    }
                } else {
                    populateRoleOptions(false)
                }
                populateCompaniesField(newFields).then(responseFields => {
                    if (props.user.role === CONSTANTS.ROLES.USER) {
                        populateBuildingField(props.user.company._id, newFields).then(responseFields => {
                            populate(responseFields)
                        })
                    } else {
                        populate(responseFields)
                    }
                }).catch(err => {
                    console.log(err)
                    populate(newFields)
                })
            }
        }

        if (!props.open) clearForm()

        setFormDataProfileImage(null)

        return () => { }
    }, [props.open, props.user])

    let populateRoleOptions = (all) => {
        let newFields = [...fields]
        let fieldIndex = fields.findIndex(field => field.name === 'role')

        if (fieldIndex > -1) {
            if (all)
                newFields[fieldIndex].options = [{ name: '', value: true, label: '' }].concat(Object.values(ROLES).map(role => ({ name: role, value: false, label: role })))
            newFields[fieldIndex].options = newFields[fieldIndex].options
        }

        setFields(newFields)
    }

    let populate = optionalFields => {
        let newFields = optionalFields ? [...optionalFields] : [...fields]
        Object.keys(props.user).forEach(key => {
            let fieldIndex = newFields.findIndex(f => f.name === key)

            if (fieldIndex <= -1) return
            if (key === 'password') return

            newFields[fieldIndex].value = props.user[key]

            if (key === 'role') {
                newFields[fieldIndex].defaultValue = props.user[key]
            }

            if (key === 'company') {
                newFields[fieldIndex].value = props.user[key] ? props.user[key]._id : ''
            }
            if (key === 'phoneNumber') {
                if (props.user[key] && props.user[key].length) {
                    let checkPhone = props.user[key].slice(0, 2)
                    if (checkPhone === CONSTANTS.MOBILE_PHONE_CHECK || checkPhone === CONSTANTS.SERVICE_PHONE_CHECK)
                        newFields[fieldIndex].value = `4${props.user[key]}`
                    else
                        newFields[fieldIndex].value = props.user[key]
                }
                else
                    newFields[fieldIndex].value = props.user[key]
            }
        })

        setFields(newFields)
    }

    let populateBuildingField = (companyId, paramFields) => props.getCompanyBuildings(companyId)
        .then(({ buildings }) => {
            let newFields = [...(paramFields || fields)]
            let indexOfBuildings = newFields.findIndex(field => field.name === 'building')

            if (indexOfBuildings < 0 && buildings.length) return

            newFields[indexOfBuildings].options = [{ name: null, value: true, label: '' }]
                .concat(buildings.map(building => ({ name: building._id, value: false, label: building.name })))

            return newFields
        })

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]

        if (event.target.name === 'role' && event.target.value === 'user') {
            populateCompaniesField(newFields).then(responseFields => {
                responseFields[fieldIndex].value = event.target.value
                setFields(responseFields)

            })

        } else if (event.target.name === 'company') {
            newFields[fieldIndex].value = event.target.value
            if ((event.target.value && event.target.value.length) && props.loginReducer.role !== CONSTANTS.SUPER_ADMIN)
                populateBuildingField(event.target.value, newFields).then(newFields => {
                    newFields[fieldIndex].value = event.target.value
                    setFields(newFields)
                })

        } else if (event.target.name === 'phoneNumber') {
            event.target.value = `${event.target.default}${event.target.value}`
            newFields[fieldIndex].value = event.target.value
            setFields(newFields)

        } else if (event.target.name === 'email') {
            modifiedEmail = true
            newFields[fieldIndex].value = event.target.value
            setFields(newFields)

        } else {
            newFields[fieldIndex].value = event.target.value
            setFields(newFields)
        }

    }

    let createUserHandler = () => {
        let data = {}
        let isOk = true
        let fieldsCopy = [...fields]
        fieldsCopy.forEach(field => {
            data[field.name] = field.value
        })

        fieldsCopy.forEach(field => {
            field.error = false
            switch (field.name) {
                case 'title':
                    if (!validator.isLength(data['title'], { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'firstName':
                    if (!validator.isLength(data['firstName'], { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'lastName':
                    if (!validator.isLength(data['lastName'], { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'email':
                    if (!validator.isEmail(data['email'])) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'phoneNumber':
                    if (!validator.isMobilePhone(data['phoneNumber'], "any", { strictMode: true })) {
                        if (!data['phoneNumber'].startsWith('+402')) {
                            field.error = true
                            isOk = false
                        }
                    }
                    break
                case 'role':
                    if (!data['role']) {
                        field.error = true
                        isOk = false
                    }

                    if (props.superAdmin) {
                        data.isRoot = true
                        if (!data['company']) {
                            isOk = false
                        }
                    }
                    break
                case 'company':
                    if ((data['role'] === CONSTANTS.ROLES.USER || props.superAdmin) && !data['company']) {
                        field.error = true
                    }
                    break
                case 'building':
                    if (!props.superAdmin && data['role'] === CONSTANTS.ROLES.USER && !data['building']) {
                        if (!props.user) {
                            field.error = true
                            isOk = false
                        }
                    }
                    break
                default:
                    isOk = true
                    break
            }

        })
        setFields(fieldsCopy)

        if (!isOk) {
            return NOTIFICATION.error(props.language.toastr.user.invalidForm.errors)
        }

        //filter null elements
        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key]
        })

        setLoading(true)
        if (!props.user) return props.createUser(data)
            .then((user) => {
                clearForm()
                if (!formDataProfileImage) {
                    props.onAccept()
                }
                NOTIFICATION.success(props.language.toastr.user.successCreate)

                if (formDataProfileImage) {
                    props.uploadImageTo(user._id, formDataProfileImage)
                        .then(() => {
                            props.onAccept()
                        })
                        .catch(err => {
                            props.onCancel()
                            NOTIFICATION.error(props.language.toastr.image.errorUpload)
                        })
                }
                setLoading(false)
            })
            .catch((err) => {
                err.message.includes(CONSTANTS.LIMIT_REACHED_CODE) ?
                    NOTIFICATION.error(props.language.toastr.user.accountsLimitReached) :
                    err.message.includes(CONSTANTS.EMAIL_EXISTS_CODE) ?
                        NOTIFICATION.error(props.language.toastr.user.emailExists) :
                        NOTIFICATION.error(props.language.toastr.user.errorCreate)
                setLoading(false)
            })

        if (isOk) return props.editUser(props.user._id, data, modifiedEmail)
            .then(() => {
                clearForm()
                if (!formDataProfileImage) {
                    props.onAccept()
                }
                NOTIFICATION.success(props.language.toastr.user.successEdit)

                if (formDataProfileImage) {
                    props.uploadImageTo(props.user._id, formDataProfileImage)
                        .then(() => {
                            props.onAccept()
                            NOTIFICATION.success(props.language.toastr.image.successUpload)
                        })
                        .catch(() => {
                            props.onCancel()
                            NOTIFICATION.error(props.language.toastr.image.errorUpload)
                        })
                }
                setLoading(false)
            })
            .catch((err) => {
                err.message.includes(CONSTANTS.EMAIL_EXISTS_CODE) ?
                    NOTIFICATION.error(props.language.toastr.user.emailExists) :
                    NOTIFICATION.error(props.language.toastr.user.userAlreadyInCompany)
                setLoading(false)
            })
        else setLoading(false)
    }

    let clearForm = () => {
        let newFields = [...initialFields.map(field => ({ ...field, value: null, defaultValue: null }))]
        setFields(newFields)
    }

    let imageUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        if (filesArray[0].size <= CONSTANTS.VALIDATION.MAX_IMAGE_SIZE) {
            fileFormData.append('file', filesArray[0])
            setFormDataProfileImage(fileFormData)
        }
        else return NOTIFICATION.error(props.language.toastr.image.tooLarge)
    }
    let uploadRef = React.createRef();

    let triggerInput = () => {
        uploadRef.current.click();
    }

    let renderFields = () => {

        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        let hasUserRole = jsonMap['role'].value === 'user'

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['firstName']}
                        shrink={true}
                        input={jsonMap['firstName']}
                        key={'firstName'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['lastName']}
                        input={jsonMap['lastName']}
                        shrink={true}
                        key={'lastName'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['title']}
                        input={jsonMap['title']}
                        shrink={true}
                        key={'title'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        shrink={true}
                        key={'phoneNumber'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        shrink={true}
                        input={jsonMap['email']}
                        key={'email'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['role']}
                        input={jsonMap['role']}
                        key={'role'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                {hasUserRole || props.superAdmin ?
                    <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                        <InputWrapper
                            {...jsonMap['company']}
                            input={jsonMap['company']}
                            key={'company'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div> : null}
                {!props.superAdmin ? hasUserRole ? <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['building']}
                        input={jsonMap['building']}
                        key={'building'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div> : null : null}


                <div>
                    <label htmlFor='imageUpload' >
                        <Tooltip placement={'top'} title={props.language.tooltip.uploadImage}>
                            <Button className={classes.photoButton} color='primary' variant="contained" onClick={triggerInput} ><PhotoIcon className={props.classes.photoIcon} /> {props.language.tooltip.uploadImage}</Button>
                        </Tooltip>
                    </label>
                    <input id='imageUpload' ref={uploadRef} type={'file'} onChange={imageUploadHandler} style={{ display: "none" }}></input>
                </div>
            </div>
        )
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.user ? props.language.titles.editUser : props.language.titles.createUser}
            acceptButtonText={loading ? <div><CircularProgress className={props.classes.circularProgress} /><span className={props.classes.processText}>{props.language.utils.processing}</span></div> : props.user ? props.language.edit : props.language.create}
            cancelButtonText={props.language.cancel}
            disabledAcceptButton={loading}
            onCancel={() => {
                props.onCancel()
                clearForm()
            }}
            onAccept={createUserHandler}
        >
            {renderFields()}
        </SimpleModal>
    )
}

const styles = theme => ({
    titleStyle: {
        height: '30%',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        padding: '16px 8px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    photoIcon: {
        paddingRight: 8
    },
    photoButton: {
        backgroundColor: '#F2F5FD',
        color: '#05092C',
        border: '1px solid rgba(0,0,0,0.2)'
    },
    circularProgress: {
        height: '20px !important',
        width: '20px !important',
        color: 'white',
        marginRight: 5
    },
    processText: {
        position: 'relative',
        top: '-4px'
    }
})


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getCompanyBuildings: companyId => dispatch(COMPANY_REQUESTS.getBuildings(companyId)),
    getCompanies: (options) => dispatch(COMPANY_REQUESTS.get(options)),
    createUser: user => dispatch(USER_REQUESTS.createAccountFromCompany({ user })),
    editUser: (id, user, modifiedEmail) => dispatch(USER_REQUESTS.edit(id, user, modifiedEmail)),
    uploadImageTo: (id, images) => dispatch(USER_REQUESTS.uploadImage(id, images))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Create))
