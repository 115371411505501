import React from 'react'
import { makeStyles } from '@material-ui/core'
import { withStyles, Card, Avatar, Typography, Button, Tooltip } from '@material-ui/core'
import { MailOutline as MailIcon, Phone as PhoneIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { BACKEND_URL } from '../../../utils/constants'

let useRoleStyles = makeStyles({
    container: {
        border: '0.5px solid rgba(0, 0, 0, 0.5)',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: 'max-content',
        padding: 5
    },
})

const Role = ({ role }) => {
    let classes = useRoleStyles()

    return (
        <div className={classes.container}>
            <Typography>
                {role}
            </Typography>
        </div>
    )
}

let useDetailStyle = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
        '&:nth-of-type(2)': {
            marginTop: 5
        }
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
})

const Detail = ({ icon, text }) => {
    let classes = useDetailStyle()

    return (
        <div className={classes.container}>
            <div className={classes.icon}>
                {icon}
            </div>

            <Tooltip title={text} placement='top-end'>
                <div className={classes.text}>
                    {text}
                </div>
            </Tooltip>
        </div>
    )
}

const UserCard = props => {
    let { classes, language } = props

    let generateRoleLanguage = role => language.labels.userRole[role]

    React.useEffect(() => { }, [props.item])

    const profileImage = props.item.profileImage ? `${BACKEND_URL}/static/${props.item.profileImage.path}` : props.avatarInitials

    const name = `${props.item.firstName} ${props.item.lastName}`

    const company = props.item.company ? props.item.company.name : ''

    return (
        <Card className={classes.root}>
            <div className={classes.icon} onClick={props.onSelect}>
                <Avatar aria-label="recipe" src={profileImage} className={classes.iconAvatar}></Avatar>
            </div>
            <div className={classes.details}>
                <div className={classes.nameAndDelete}>
                    <Tooltip title={name} placement='top-center'>
                        <Typography variant="h6" className={`${classes.detailsName} ${classes.nameContainer}`} onClick={props.onSelect}>
                            {name}
                        </Typography>
                    </Tooltip>
                    <Tooltip title={company} placement='top-center'>
                        <Typography variant="h6" className={classes.detailsName} onClick={props.onSelect}>
                            {company ? <>| <span className={classes.spanContainer}>{company}</span> </> : ''}
                        </Typography>
                    </Tooltip>
                    

                    <div className={classes.actionsSection}>
                        {props.hideDelete ? null : <Button className={classes.deleteUserIconButton} onClick={props.onDelete}>
                            <DeleteIcon className={classes.deleteUserIcon} />
                        </Button>}
                    </div>
                </div>
                <div className={classes.secondaryDetails} onClick={props.onSelect}>
                    <div style={{ width: 150 }}>
                        <Tooltip title={props.subtitle} placement='top-center'>
                            <Typography className={classes.detailsSubtitle} color='textSecondary' gutterBottom>
                                {props.subtitle}
                            </Typography>
                        </Tooltip>
                        <Role role={props.item ? generateRoleLanguage(props.item.role) : '-'} />
                    </div>
                    <div className={classes.phoneMailDetails}>
                        <Detail
                            text={props.item ? props.item.email : '-'}
                            icon={<MailIcon className={classes.phoneMailIcon} />} />
                        <Detail
                            text={props.item ? props.item.phoneNumber : '-'}
                            icon={<PhoneIcon className={classes.phoneMailIcon} />} />
                    </div>
                </div>

            </div>
        </Card >
    )
}

const styles = theme => ({
    nameAndDelete: {
        display: 'flex',
        flexDirection: 'row'
    },
    secondaryDetails: {
        display: 'flex',
        flexDirection: 'row'
    },
    icon: {
        display: 'flex',
        width: 40,
    },
    deleteUserIconButton: {
        width: 30,
        height: 30,
        padding: 2
    },
    deleteUserIcon: {
        width: '100%',
        height: '100%'
    },
    phoneMailIcon: {
        width: 20,
        margin: '0px 10px 0px 10px',
        position: 'relative',
        bottom: -2
    },
    phoneMailDetails: {
        marginTop: 10
    },
    detailsSubtitle: {
        marginTop: 10,
        display: 'flex',
        size: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 20
    },
    detailsName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        fontWeight: 450,
        color: 'rgba(0, 94, 205, 0.7)',
        display: 'flex',

    },
    spanContainer:{
        whiteSpace: 'nowrap',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        display: 'block',
        width: '300px',
        color: '#888888',
        paddingLeft: '8px'
    },
    actionsSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '50px',
        marginLeft: 'auto'
    },
    iconAvatar: {

    },
    icon: {
        width: '40px',
        cursor: 'pointer'
    },
    details: {
        width: 'calc(100% - 50px)',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        paddingLeft: 12
    },
    value: {
        fontSize: 15,
        color: 'black'
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    media: {
        height: 100
    },
    cardContent: {
        borderBottom: 'none',
        minHeight: 150,
        cursor: 'pointer'
    },
    container: {
        width: '100%',
        height: '100%'
    },
    root: {
        padding: 15,
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        display: 'flex',
        minWidth: '400px',
        boxSizing: 'border-box',
        flexDirection: 'row',
        flex: 1
    },
    titleText: {
        margin: 0,
        padding: 0,
        color: '#3778C2',
        fontSize: 16
    },
    subtitleText: {
        margin: 0,
        padding: 0
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red',
        avatar: {
            backgroundColor: 'yellow'
        }
    },
    separator: {
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        marginTop: 6,
        marginBottom: 6
    },
    data: {
        display: 'flex',
        color: 'rgba(0,0,0,0.40)',
        flexDirection: 'row',
    },
    dataRed: {
        display: 'flex',
        justifyContent: 'center',
        color: 'red',
        height: 50,
        flexDirection: 'column',
    },
    dataLabel: {
        color: 'rgba(0,0,0,0.40)',
        marginBottom: 10
    },
    detailsTitle: {
        margin: '4px 0px 18px 0px',
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    nameContainer:{
        paddingRight: '8px'
    }
})

export default withStyles(styles)(UserCard)