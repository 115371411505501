import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip } from '@material-ui/core'

import {
    PersonPin as PersonIcon
} from '@material-ui/icons'

import * as USERS_REQUEST from '../../../../redux/actions/users'
import * as TICKETS_REQUEST from '../../../../redux/actions/tickets'
import * as COMPANIES_REQUESTS from '../../../../redux/actions/companies'
import * as NOTIFICATIONS from '../../../../utils/notification'
import * as CONSTANTS from '../../../../utils/constants'

import SelectMenu from '../../../common/CheckboxMenu'

const styles = theme => ({
    details: {
        padding: '8px 28px',
        cursor: 'pointer'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147'
    },
    detailsIcon: {
        fontSize: 48,
        color: '#3d4147'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    extraPadding: {
        padding: '4px 12px 4px 12px'
    },
    listWrapper: {
        // '& span:not(:first-child)': {
        //     display: 'inline-block',
        //     marginLeft: '5px'
        // }
    }
})

class UsersList extends Component {

    state = {
        users: [],
        companies: [],
        openDropdown: false
    }

    componentDidMount() {
        this.getUsers()
        this.getCompanies()
    }

    getCompanies = () => {
        return this.props.getContractorCompanies()
            .then(result => {
                this.setState({ companies: result.companies })
            })
    }

    getUsers = () => {
        this.props.getUsers().then(result => {
            this.setState({
                users: result.users
            })
        })
    }

    generateCheckboxMenuItems = () => {
        return this.state.companies.map(company => {
            return ({
                name: company._id,
                label: company.name,
                value: false,
                email: company.email || '',
                company: company.company ? company.company.name : '',
                officialDetails: company.official ? (company.official.lastName).length >= CONSTANTS.OFFICAL_MAX_NAME_LENGTH ? `${company.official.firstName} ${(company.official.lastName).substr(0, 1)} - ${company.official.phoneNumber}` : `${company.official.firstName} ${company.official.lastName} - ${company.official.phoneNumber}` : '',
                fullOfficialDetails: company.official ? `${company.official.firstName} ${company.official.lastName} - ${company.official.phoneNumber}` : null,
                profileImage: company.official && company.official.profileImage ? `${CONSTANTS.BACKEND_URL}/static/${company.official.profileImage.path}` : ''
            })
        })
    }


    onChangeCheckboxMenu = companyId => {
        this.props.updateTicket(this.props.ticketId, { assignedCompany: companyId, seenBy: [this.props.loginReducer._id] })
            .then(() => {
                this.props.getTicket()
                NOTIFICATIONS.success(this.props.language.toastr.ticket.successAssignee)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.ticket.errorAssignee)
            })
    }

    renderDropdown = () => {
        const { language, classes } = this.props

        return (
            <SelectMenu
                initialText={
                    <div className={this.props.classes.listWrapper}>
                        <Tooltip title={language.toastr.selectUser} placement={"top"}>
                            <div className={classes.details}>
                                <h4 className={`${classes.detailsText}`}>{this.props.language.utils.assignee}</h4>
                                <div className={classes.detailsAssigned}>
                                    <PersonIcon className={classes.detailsIcon} />
                                    <div className={classes.flexColumn}>
                                        <span className={`${classes.detailsText} ${classes.extraPadding}`}>{this.props.language.utils.noAssignee}</span>
                                        <span className={`${classes.detailsText} ${classes.extraPadding}`}>{this.props.language.utils.chooseAssignee}</span>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                }
                items={this.generateCheckboxMenuItems()}
                onChange={this.onChangeCheckboxMenu}
            />
        )
    }


    render() {
        if (this.state.companies && this.state.companies.length) {
            return (
                <>
                    {this.renderDropdown()}
                </>
            )
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(USERS_REQUEST.get({ all: true })),
    getContractorCompanies: () => dispatch(COMPANIES_REQUESTS.get({ limit: 100000, contractor: true })),
    updateTicket: (ticketId, newTicket) => dispatch(TICKETS_REQUEST.update(ticketId, newTicket))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UsersList))