import React, { useState, useEffect } from 'react'
import { withStyles, Tooltip, Button, InputAdornment } from '@material-ui/core'
import { connect } from 'react-redux'
import { PhotoLibrary as UploadBuildingImage, Delete as DeleteIcon } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import validator from 'validator'

import InputGenerator from '../../../common/InputGenerator'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as BUILDING_REQUESTS from '../../../../redux/actions/buildings'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import * as NOTIFICATION from '../../../../utils/notification'
import * as CONSTANTS from '../../../../utils/constants'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
        endAdornment={props.endAdornment}
    />
</div>

let Editable = props => {
    let { classes } = props
    let [renderPage, setRenderPage] = useState(false)
    let [fields, setFields] = useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
        { value: 0, type: 'number', label: props.language.labels.totalSurface, name: 'totalArea' },
        { value: 0, type: 'number', label: props.language.labels.commercialSurface, name: 'commercialArea' },
        { value: 0, type: 'number', label: props.language.labels.officeSurface, name: 'officeArea' },
        { value: 0, type: 'number', label: props.language.labels.utilSurface, name: 'usableArea' },
        { value: '', type: 'phone', label: props.language.labels.contactPhone, name: 'phoneNumber', default: '+' },
        { value: '', type: 'text', label: props.language.labels.website, name: 'website' },
        { value: '', type: 'text', label: props.language.labels.mail, name: 'email' },
        { type: 'dropdownSelector', name: 'owner', utils: props.language.labels.propertyOwner, value: null, options: [], shrink: false },
        { type: 'dropdownSelector', name: 'facilityManager', utils: props.language.labels.facilityManager, value: null, options: [], shrink: false },
        { type: 'dropdownSelector', name: 'propertyManager', utils: props.language.labels.propertyManager, value: null, options: [], shrink: false },
        {
            type: 'fixedMultiSelect',
            name: 'services',
            utils: props.language.labels.services,
            options: [],
            value: [],
            defaultValue: []
        }
    ])

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]

        if (event.target.name === 'facilityManager' || event.target.name === 'propertyManager' || event.target.name === 'owner') {

            newFields[fieldIndex].value = event.target.value.length ? event.target.value : null

        } else {
            if (event.target.name === 'phoneNumber')
                event.target.value = `${event.target.default}${event.target.value}`
            newFields[fieldIndex].value = event.target.value
        }


        if (event.target.name === 'services') {
            newFields[fieldIndex].defaultValue.forEach(field => {
                if (!newFields[fieldIndex].options.includes(field))
                    newFields[fieldIndex].options.push(field)
            })
        }

        setFields(newFields)
    }

    let saveChangesHandler = () => {
        let building = {
            ...props.building
        }

        fields.forEach(field => {
            if (field.name === 'totalArea') {
                building.area.total = field.value
            } else if (field.name === 'commercialArea') {
                building.area.commercial = field.value
            } else if (field.name === 'officeArea') {
                building.area.office = field.value
            } else if (field.name === 'usableArea') {
                building.area.usable = field.value
            } else if (field.name === 'website') {
                building.contact.website = field.value
            } else if (field.name === 'phoneNumber') {
                building.contact.phoneNumber = field.value
            } else if (field.name === 'email') {
                building.contact.email = field.value
            } else if (field.name === 'services') {
                building.services = field.value.length ? field.value.map(field => field.name) : field.defaultValue.map(field => field.name)
            } else building[field.name] = field.value
        })

        let fieldsCopy = [...fields]
        let isOk = true

        fieldsCopy.forEach(field => {
            field.error = false
            switch (field.name) {
                case 'name':
                    if (validator.isEmpty(building['name'])) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'email':
                    if (building.contact.email)
                        if (!validator.isEmail(building.contact.email)) {
                            field.error = true
                            isOk = false
                        }
                    break
                case 'website':
                    if (building.contact.website)
                        if (!validator.isURL(building.contact.website)) {
                            field.error = true
                            isOk = false
                        }
                    break
                case 'phoneNumber':
                    if (building.contact.phoneNumber)
                        if (!validator.isMobilePhone(building.contact.phoneNumber, "any", { strictMode: true })) {
                            if (!building.contact.phoneNumber.startsWith('+402')) {
                                field.error = true
                                isOk = false
                            }
                        }
                    break
                default:
                    break
            }
        })
        setFields(fieldsCopy)

        if (!isOk) {
            return NOTIFICATION.error(props.language.toastr.building.errors)
        }

        editBuilding(props.building._id, building)

    }

    let imageUploadHandler = (event) => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        if (filesArray[0].size <= CONSTANTS.VALIDATION.MAX_IMAGE_SIZE)
            fileFormData.append('file', filesArray[0])
        else return NOTIFICATION.error(props.language.toastr.image.tooLarge)
        props.uploadImageTo(props.building._id, fileFormData)
            .then(() => {
                NOTIFICATION.success(props.language.toastr.image.successUpload)
            })
            .catch(() => {
                NOTIFICATION.error(props.language.toastr.image.errorUpload)
            })
    }


    let getServices = type => props.getServices(type)
        .then(data => data)


    useEffect(() => {
        if (props.building) {
            let getAllUsers = props.getUsers()
            let getAllServices = getServices('services')

            Promise.all([getAllUsers, getAllServices]).then(values => {
                let options = values[0].users.map(user => ({
                    name: user._id,
                    value: user._id,
                    label: `${user.firstName} ${user.lastName}`,
                    role: user.role
                }))

                let servicesOptions = [...values[1].map(type => ({
                    name: type.name,
                    value: true,
                    label: type.name
                }))]


                let newFields = [...fields]

                let ownerIndex = newFields.findIndex(field => field.name === 'owner')
                let facilityManagerIndex = newFields.findIndex(field => field.name === 'facilityManager')
                let propertyManagerIndex = newFields.findIndex(field => field.name === 'propertyManager')
                let servicesIndex = newFields.findIndex(field => field.name === 'services')

                if (ownerIndex < 0 || facilityManagerIndex < 0 || propertyManagerIndex < 0 || servicesIndex < 0) {
                    return NOTIFICATION(props.language.toastr.building.populateError)
                }

                newFields[ownerIndex].options = [{ value: '', name: '' }].concat(options)
                newFields[facilityManagerIndex].options = [{ value: '', name: '' }].concat(options.filter(person => person.role === props.language.comments.userRole.fm))
                newFields[propertyManagerIndex].options = [{ value: '', name: '' }].concat(options.filter(person => person.role === props.language.comments.userRole.pm))
                newFields[servicesIndex].options = servicesOptions
                newFields[servicesIndex].defaultValue = servicesOptions

                let building = {
                    name: props.building.name,
                    phoneNumber: props.building.contact.phoneNumber,
                    email: props.building.contact.email,
                    website: props.building.contact.website,
                    totalArea: props.building.area.total,
                    usableArea: props.building.area.usable,
                    officeArea: props.building.area.office,
                    commercialArea: props.building.area.commercial,
                    propertyManager: props.building.propertyManager,
                    owner: props.building.owner,
                    facilityManager: props.building.facilityManager,
                    services: props.building.services
                }

                Object.keys(building).forEach(key => {
                    let fieldIndex = fields.findIndex(f => f.name === key)

                    if (!(fieldIndex > -1)) return
                    if (key === 'services') {
                        newFields[fieldIndex].defaultValue = building[key].map(service => ({
                            name: service
                        }))
                        let defaultName = newFields[fieldIndex].defaultValue.map(value => value.name)
                        let newFieldsOptions = newFields[fieldIndex].options
                        let newFieldsOptionsCopy = []
                        if (newFields[fieldIndex].defaultValue && newFields[fieldIndex].defaultValue.length) {
                            defaultName.forEach(name => {
                                newFieldsOptionsCopy = newFieldsOptions.filter(option => option.name !== name)
                                newFieldsOptions = newFieldsOptionsCopy
                            })
                            newFields[fieldIndex].options = newFieldsOptionsCopy
                        }
                    }
                    else if (key === 'owner' || key === 'facilityManager' || key === 'propertyManager') {
                        newFields[fieldIndex].value = building[key] ? building[key]._id : null
                    }
                    else if (key === 'phoneNumber') {
                        if (building[key] && building[key].length){
                            let checkPhone = building[key].slice(0,2)
                            if(checkPhone === CONSTANTS.MOBILE_PHONE_CHECK || checkPhone === CONSTANTS.SERVICE_PHONE_CHECK)
                                newFields[fieldIndex].value = `4${building[key]}`
                            else 
                                newFields[fieldIndex].value = building[key]    
                        }
                        else 
                            newFields[fieldIndex].value = building[key]         
                    }
                    else newFields[fieldIndex].value = building[key] || null

                })
                setFields(newFields)
                setRenderPage(true)
            })
        }
    }, [props.building])



    let editBuilding = (id, building) => {
        return props.editBuilding(id, building)
            .then(() => NOTIFICATION.success(props.language.toastr.building.successEdit))
            .catch(() => NOTIFICATION.error(props.language.toastr.building.errorEdit))
    }

    let deleteBuilding = async () => {
        if (!props.building) return
        let confirmed = window.confirm(`${props.language.toastr.building.sureYouWantToDelete} ${props.building ? props.building.name : ''} ?`)

        if (!confirmed) return

        try {
            await props.deleteBuilding(props.building._id)
            props.history.push('/buildings')
            NOTIFICATION.success(`${props.language.toastr.building.building} ${props.building ? props.building.name : ''} ${props.language.toastr.building.wasSuccessfullyDeleted}`)
        } catch (e) {
            NOTIFICATION.success(`${props.language.toastr.building.errorDeleting}`)
        }

    }

    let renderFields = () => {
        let jsonMap = {}

        fields.forEach(field => {
            jsonMap[field.name] = field
        })
        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['name']}
                        input={jsonMap['name']}
                        key={'name'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['owner']}
                        input={jsonMap['owner']}
                        key={'owner'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['facilityManager']}
                        input={jsonMap['facilityManager']}
                        key={'facilityManager'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['propertyManager']}
                        input={jsonMap['propertyManager']}
                        key={'propertyManager'}
                        onChange={event => onInputChange(event)}
                    />
                </div>


                <div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['totalArea']}
                            input={jsonMap['totalArea']}
                            key={'totalArea'}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['usableArea']}
                            input={jsonMap['usableArea']}
                            key={'usableArea'}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>
                </div>
                <div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['commercialArea']}
                            input={jsonMap['commercialArea']}
                            key={'commercialArea'}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['officeArea']}
                            input={jsonMap['officeArea']}
                            key={'officeArea'}
                            onChange={event => onInputChange(event)}
                            endAdornment={<InputAdornment position='end'>{props.language.utils.squareMeters}</InputAdornment>}
                        />
                    </div>
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        key={'phoneNumber'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        input={jsonMap['email']}
                        key={'email'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['website']}
                        input={jsonMap['website']}
                        key={'website'}
                        onChange={event => onInputChange(event)}
                    />
                </div>
                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['services']}
                        input={jsonMap['services']}
                        key={'services'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>
            </div >
        )
    }
    if (renderPage) {
        return (
            <div className={classes.container}>
                <div className={classes.contentContainer}>
                    <div className={`${classes.content}`}>
                        <div className={classes.titleContainer}>
                            <span className={`${classes.titleText}`}>{props.building && props.building.name}</span>
                        </div>
                        <div className={classes.fieldsContainer}>
                            <div className={classes.fields}>
                                {renderFields()}
                            </div>
                            <div className={classes.saveButtonContainer}>
                                <Button onClick={() => saveChangesHandler()} className={classes.fullWidth} color="secondary" variant="contained">{props.language.buttons.saveChanges}</Button>
                            </div>
                            <div className={classes.buttonsContainer}>
                                <div className={classes.buttonAction}>
                                    <Tooltip placement={'top'} title={props.language.tooltip.deleteBuilding} onClick={deleteBuilding} >
                                        <div className={`${classes.button} ${classes.actionForWindow}`}>
                                            <DeleteIcon />
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className={classes.buttonAction}>
                                    <label htmlFor='imageUpload' className={classes.uploadLabel}>
                                        <Tooltip placement={'top'} title={props.language.tooltip.uploadImage}>
                                            <div className={`${classes.button} ${classes.actionForWindow}`}>
                                                <UploadBuildingImage />
                                            </div>
                                        </Tooltip>
                                    </label>
                                    <input id='imageUpload' type={'file'} className={classes.uploadBuildingImage} onChange={imageUploadHandler}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else return null
}

const styles = theme => ({
    deleteBuilding: {
        paddingLeft: 10,
        cursor: 'pointer',
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        alignSelf: 'center'
    },
    fullWidth: {
        width: '100%'
    },
    saveButtonContainer: {
        padding: '12px 20px 0px 12px'
    },
    buttonAction: {
        textAlign: 'center',
        margin: '0px 7px 0px 0px'
    },
    fieldsContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 0px 8px',
        maxHeight: 'calc(100% - 76px)'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 'auto',
        paddingTop: 12
    },
    fields: {
        padding: '8px 7px',
        maxHeight: '90%',
        overflow: 'auto'
    },
    inputField: {
        flex: 1,
        marginRight: 8
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    titleContainer: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        padding: '8px 28px',
        width: '430px'
    },
    titleText: {
        fontSize: '20px',
        color: '#333333',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    contentContainer: {
        height: 'calc(100% - 40px)',
        flex: 1,
        margin: '20px 0px 20px 10px',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        backgroundColor: '#ffffff',
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    status: {
        marginLeft: 'auto'
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    details: {
        padding: '8px 28px'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147'
    },
    agreeIcon: {
        color: '#34aa44'
    },
    declineIcon: {
        color: '#E74C3C'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    extraPadding: {
        padding: '4px 12px 14px 12px'
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalsFlex: {
        flex: 1
    },
    iconContainer: {
        alignItems: 'center',
        padding: '14px 0px 0px 24px'
    },
    smallButton: {
        width: 30,
        height: 30
    },
    uploadBuildingImage: {
        display: 'none'
    },
    uploadImageContainer: {
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        color: '#0F2557',
        marginTop: '6px'
    },
    uploadLabel: {
        cursor: 'pointer',
        marginRight: '7px'
    },
    weightFont: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600
    },
    button: {
        backgroundColor: '#ffffff',
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '1.5px 1.5px 5px 0px rgba(0,0,0,0.25)',
        color: '#4D8FDC',
        width: '100%',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#F2F2F2',
            opacity: 0.75
        }
    },
    actionForWindow: {
        width: 39,
        height: 32,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    editBuilding: (id, building) => dispatch(BUILDING_REQUESTS.edit(id, building)),
    getServices: type => dispatch(TAG_REQUESTS.getAll({ type })),
    uploadImageTo: (id, images) => dispatch(BUILDING_REQUESTS.uploadImage(id, images)),
    deleteBuilding: buildingId => dispatch(BUILDING_REQUESTS.remove(buildingId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Editable)))