export const TOPBAR_CHANGE_LANGUAGE = 'TOPBAR_CHANGE_LANGUAGE'

export const IS_LOGGED = 'IS_LOGGED'
export const IS_NOT_LOGGED = 'IS_NOT_LOGGED'
export const LOGIN = 'LOGIN'

export const SET_DISPLAY_SIDEBAR = 'SET_DISPLAY_SIDEBAR'

export const SET_TICKET_TYPE = 'SET_TICKET_TYPE'

export const CHANGE_PROFILE_IMAGE = 'CHANGE_PROFILE_IMAGE'
