import React, { useState, useEffect } from 'react'
import { withStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Build, Business, SpeakerNotes } from '@material-ui/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import Header from '../../common/Header'
import Table from './table/Table'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as CONSTANTS from '../../../utils/constants'
import Create from './create/Create'
import * as NOTIFICATIONS from '../../../utils/notification'

let Drawer = props => {
    let [items, setItems] = useState([
        { name: 'ticket', label: props.language.labels.ticketType, icon: <SpeakerNotes /> },
        { name: 'services', label: props.language.labels.serviceType, icon: <Build /> },
        { name: 'company', label: props.language.labels.companyType, icon: <Business /> }
    ])
    let [selectedIndex, setSelectedIndex] = useState(0)

    let onItemClickedHandler = (index, name) => {
        setSelectedIndex(index)
        props.onChange(name)
    }

    return (
        <div className={props.classes.listContainer}>
            <List>
                {items.map((item, index) => (
                    <ListItem
                        button
                        key={item.name}
                        selected={selectedIndex === index}
                        onClick={() => onItemClickedHandler(index, item.name)}
                        classes={{ selected: props.classes.selectedTagItemContainer }}
                        className={props.classes.tagItemContainer}
                    >

                        <ListItemIcon className={props.classes.listItemIcon}>{item.icon}</ListItemIcon>
                        <ListItemText primary={<p className={props.classes.listItemText}>{item.label}</p>} />

                    </ListItem>
                ))}
            </List>
        </div>
    )
}

let Tags = props => {
    let { classes, language } = props
    let [tags, setTags] = useState([])
    let [selectedTag, setSelectedTag] = useState('ticket')
    let [showCreateTag, setShowCreateTag] = useState(false)

    useEffect(() => {
        getTags()
    }, [selectedTag])

    let handleModalTitle = () => {
        switch (selectedTag) {
            case 'ticket': {
                return props.language.titles.ticketType
            }
            case 'company': {
                return props.language.titles.companyType
            }
            case 'services': {
                return props.language.titles.serviceType
            }
            default: {
                return props.language.titles.ticketType
            }
        }
    }

    let getTags = () => {
        let deleteTag = tagId => {
            let accepted = window.confirm(language.toastr.tags.wantToDeleteTag)

            if (!accepted) return Promise.reject()

            return props.deleteTag(tagId)
                .then(() => getTags())
                .then(() => NOTIFICATIONS.success(language.toastr.tags.tagSuccessfullyDeleted))
                .catch(err => NOTIFICATIONS.error(language.toastr.tags.errorDeleting))
        }

        return props.getTags(selectedTag)
            .then(tags => {
                setTags(tags.map(tag => ({
                    name: tag.name,
                    createdAt: moment(tag.createdAt).format(CONSTANTS.DEFAULT_DATE_FORMAT),
                    _id: tag._id ? tag._id.slice(CONSTANTS.TICKETS_ID_LENGTH) : '',
                    createdBy: tag.createdBy ? `${tag.createdBy.lastName} ${tag.createdBy.firstName}` : '',
                    onDeleteClicked: () => deleteTag(tag._id)
                })))
            })
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header
                    title={props.language.titles.tags}
                    simple
                    button
                    buttonText={props.language.buttons.add}
                    onClick={() => setShowCreateTag(true)}
                    addText={props.language.headerTooltip.tag}
                    profileText={props.language.headerTooltip.profile}
                />
            </div>
            <div className={classes.tagsContainer}>
                <div className={classes.selectTag}>
                    <Drawer
                        classes={classes}
                        language={language}
                        onChange={tag => setSelectedTag(tag)}
                    />
                </div>
                <div className={classes.tagListContainer}>
                    <div className={classes.tagList}>
                        <Table
                            language={language}
                            titlu='titlu'
                            items={tags}
                        />
                    </div>
                </div>
            </div>
            <Create
                open={showCreateTag}
                type={selectedTag}
                acceptButtonText={props.language.buttons.add}
                title={handleModalTitle()}
                onCancel={() => setShowCreateTag(false)}
                onCreate={() => getTags().then(() => setShowCreateTag(false))}
            />
        </div>
    )
}

let styles = () => ({
    listItemIcon: {
        display: 'flex',
        minWidth: '35px'
    },
    listItem: {
    },
    listItemText: {
        fontWeight: 500,
        margin: 0,
        padding: 0
    },
    container: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#f7f8fa',
        padding: '0px 25px 0px 30px',
        boxSizing: 'border-box'
    },
    header: {
        height: 70
    },
    tagsContainer: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectTag: {
        width: '200px',
        height: '100%',
        borderRight: '1px solid rgba(0,0,0,0.15)',
    },
    tagListContainer: {
        width: 'calc(100% - 200px)',
        height: '95%',
        maxHeight: '100%',
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tagList: {
        width: '100%',
        height: '90%',
        paddingLeft: 25
    },
    tagItemContainer: {
        background: '#FFFFFF',
        border: '2px solid rgba(0,0,0,0.1)',
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginBottom: '25px',
        '&:hover': {
            color: 'rgba(0, 94, 205, 0.7) ',
            backgroundColor: 'transparent',
            border: '2px solid rgba(0, 94, 205, 0.7)',
            WebkitTextFillColor: 'rgba(0, 94, 205, 0.7)',
        }
    },
    selectedTagItemContainer: {
        backgroundColor: 'transparent',
        border: '2px solid rgba(0, 94, 205, 0.7) !important',
        '& p, svg':{
            color: '#ffffff !important',
            fontWeight: 500
        }
    },
    listContainer: {
        display: 'flex',
        paddingTop: 65
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    deleteTag: tagId => dispatch(TAG_REQUESTS.remove(tagId))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tags))