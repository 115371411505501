import React from 'react'
import { connect } from 'react-redux'
import validator from 'validator'

import FormGenerator from '../../common/FormGenerator'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as CONSTANTS from '../../../utils/constants'

let EditUserModal = props => {
    let INITIAL_FIELDS = [
        { value: '', type: 'text', label: props.language.editProfileForm.firstName, name: 'firstName' },
        { value: '', type: 'text', label: props.language.editProfileForm.lastName, name: 'lastName' },
        { value: '', type: 'text', label: props.language.editProfileForm.email, name: 'email' },
        { value: '', type: 'text', label: props.language.editProfileForm.title, name: 'title' },
        { value: '', type: 'phone', label: props.language.editProfileForm.phoneNumber, name: 'phoneNumber', default: '+' },
        { value: '', type: 'password', label: props.language.editProfileForm.password, name: 'password' },
        { value: '', type: 'password', label: props.language.editProfileForm.confirmPassword, name: 'confirmPassword' }
    ]

    let { language } = props
    let [fields, setFields] = React.useState(INITIAL_FIELDS)
    let [formDataProfileImage, setFormDataProfileImage] = React.useState(null)

    React.useEffect(() => {
        populate()
        setFormDataProfileImage(null)
    }, [props.open])

    let populate = () => props.getMe()
        .then(user => {
            let newFields = [...fields]

            Object.keys(user).forEach(key => {
                let indexOfKey = newFields.findIndex(field => field.name == key)
                if (indexOfKey > -1 && key !== 'password') {
                    if (key === 'phoneNumber') {
                        if (user[key] && user[key].length){
                            let checkPhone = user[key].slice(0, 2)
                            if (checkPhone === CONSTANTS.MOBILE_PHONE_CHECK || checkPhone === CONSTANTS.SERVICE_PHONE_CHECK)
                                newFields[indexOfKey].value = `4${user[key]}`
                            else
                                newFields[indexOfKey].value = user[key]
                        }
                        else
                             newFields[indexOfKey].value = user[key]
                    }
                    else newFields[indexOfKey].value = user[key]
                }
            })

            setFields(newFields)
        })

    let onSubmitHandler = data => {
        let isOk = true

        let fieldsCopy = [...fields]
        fieldsCopy.forEach(field => {
            field.error = false
            switch (field.name) {
                case 'title':
                    if (!validator.isLength(data.title, { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'firstName':
                    if (!validator.isLength(data.firstName, { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'lastName':
                    if (!validator.isLength(data.lastName, { min: CONSTANTS.VALIDATION.MIN_LENGTH, max: CONSTANTS.VALIDATION.MAX_LENGTH })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'email':
                    if (!validator.isEmail(data.email)) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'phoneNumber':
                    if (!validator.isMobilePhone(data.phoneNumber, "any", { strictMode: true })) {
                        field.error = true
                        isOk = false
                    }
                    break
                case 'password':
                    if (!validator.isEmpty(data.password))
                        if (data.password !== data.confirmPassword)
                            field.error = true
                    break
                case 'confirmPassword':
                    if (!validator.isEmpty(data.password))
                        if (data.password !== data.confirmPassword)
                            field.error = true
                    break
                default:
                    break
            }
        })
        setFields(fieldsCopy)

        if (!isOk) {
            return NOTIFICATIONS.error(language.toastr.user.invalidForm.errors)
        }

        return props.editMe(data)
            .then(() => {
                if (formDataProfileImage)
                    props.uploadImageTo(props.loginReducer._id, formDataProfileImage)
            })
            .then(() => populate())
            .then(() => {
                NOTIFICATIONS.success(language.login.loginForm.success)
                props.onCancel()
            })
            .catch((err) => {
                if (err.message.includes(CONSTANTS.PASSWORDS_DID_NOT_MATCH_CODE))
                    NOTIFICATIONS.error(language.login.loginForm.passwordsDidNotMatch)
                else
                    NOTIFICATIONS.error(language.login.loginForm.error)
            })
    }

    let imageUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        if (filesArray[0].size <= CONSTANTS.VALIDATION.MAX_IMAGE_SIZE) {
            fileFormData.append('file', filesArray[0])
            setFormDataProfileImage(fileFormData)
        }
        else return NOTIFICATIONS.error(props.language.toastr.image.tooLarge)
    }

    return (
        <FormGenerator
            open={props.open}
            title={props.language.titles.editProfile}
            acceptButtonText={props.language.buttons.edit}
            cancelButtonText={props.language.buttons.drop}
            onCancel={props.onCancel}
            fields={fields}
            withPhoto={true}
            imageUploadHandler={event => imageUploadHandler(event)}
            imageTooltip={props.language.tooltip.uploadImage}
            onAccept={onSubmitHandler}
        />
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(USER_REQUESTS.getMe()),
    editMe: newUser => dispatch(USER_REQUESTS.editMe(newUser)),
    uploadImageTo: (id, images) => dispatch(USER_REQUESTS.uploadImage(id, images))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)