import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import Editable from './editable/Editable'
import Comments from './comments/Comments'
import Header from '../../common/Header'

import * as TICKET_REQUESTS from '../../../redux/actions/tickets'


const styles = theme => ({
    container: {
        width: 'calc(100% - 216px)',
        height: '100%',
        backgroundColor: '#F9FBFF'
    },
    editableContainer: {
        height: '100%',
        flex: 1,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    header: {
        height: 70,
        padding: '0px 25px 0px 30px'
    },
    commentsContainer: {
        height: '100%',
        flex: 3
    },
    containerData: {
        backgroundColor: 'white',
        margin: 20,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        height: 'calc(100% - 90px)'
    }
})

class TicketDetails extends Component {

    state = {
        ticket: null
    }

    componentDidMount() {
        this.getTicket()
    }

    getTicket = () => {
        return this.props.getById(this.props.match.params.ID).then(result => {
            this.setState({ ticket: result })

            return true
        })
    }

    render() {
        let { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Header
                        simple
                        title={this.props.language.titles.ticketTable}
                        profileText={this.props.language.headerTooltip.profile}
                    />
                </div>
                <div className={classes.containerData}>
                    <div className={classes.editableContainer}>
                        {this.state.ticket && <Editable history={this.props.history} ticketId={this.props.match.params.ID} ticket={this.state.ticket} getTicket={() => this.getTicket()} />}
                    </div>
                    <div className={classes.commentsContainer}>
                        {this.state.ticket && <Comments ticketId={this.props.match.params.ID} ticket={this.state.ticket} getTicket={() => this.getTicket()} />}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    getById: id => dispatch(TICKET_REQUESTS.getById(id)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketDetails))