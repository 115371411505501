import axios from '../../utils/axios'

import { errorHandler } from '../../utils/handlers'

import * as TYPES from '../actionTypes'

export const login = (email, password) => dispatch => axios.post('/auth', {
    email,
    password
}).then(response => {
    localStorage.setItem("tokenHelpdeskSolvvo", response.data.token)
    dispatch({
        type: TYPES.IS_LOGGED,
        payload: {
            username: `${response.data.user.firstName} ${response.data.user.lastName}`,
            email: response.data.user.email,
            _id: response.data.user._id,
            role: response.data.user.role,
            profileImagePath: response.data.user.profileImage ? response.data.user.profileImage.path : null,
            parentCompany: response.data.user.parentCompany ? response.data.user.parentCompany._id : null ,
            companyId: response.data.user.companyId ? response.data.user.companyId : null,
            isOfficial: response.data.user.isOfficial,
            licenseAccepted: response.data.user.licenseAccepted
        }
    })
    return Promise.resolve(response);
})

export const isLogged = () => dispatch => axios.get('/isLogged').then(decodedToken => {
    var loggedUser = decodedToken.data

    if (loggedUser) {
        dispatch({
            type: TYPES.IS_LOGGED,
            payload: {
                username: `${loggedUser.firstName} ${loggedUser.lastName}`,
                email: loggedUser.email,
                _id: loggedUser._id,
                role: loggedUser.role,
                profileImagePath: loggedUser.profileImagePath ? loggedUser.profileImagePath : null,
                parentCompany: loggedUser.parentCompany,
                companyId: loggedUser.companyId,
                isOfficial: loggedUser.isOfficial,
                licenseAccepted: loggedUser.licenseAccepted
            }
        })
    }
}).catch(err => {
    localStorage.removeItem("tokenHelpdeskSolvvo")
    dispatch({
        type: TYPES.IS_NOT_LOGGED
    })
    return Promise.reject(err)
})

export const recover = email => dispatch => axios.put('/auth/recover', { email })

export const logout = () => dispatch => {
    localStorage.removeItem("tokenHelpdeskSolvvo")
    dispatch({
        type: TYPES.IS_NOT_LOGGED
    })
}

export const updateToken = updatedUser => dispatch => {
    localStorage.setItem("tokenHelpdeskSolvvo", updatedUser.token)

    dispatch({
        type: TYPES.IS_LOGGED,
        payload: {
            username: `${updatedUser.user.firstName} ${updatedUser.user.lastName}`,
            email: updatedUser.user.email,
            _id: updatedUser.user._id,
            role: updatedUser.user.role,
            profileImagePath: updatedUser.user.profileImagePath ? updatedUser.user.profileImagePath : null,
            parentCompany: updatedUser.user.parentCompany,
            isOfficial: updatedUser.user.isOfficial,
            licenseAccepted: updatedUser.user.licenseAccepted
        }
    })
}

export const verifyToken = token => dispatch => axios.get(`/auth/reset/${token}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const resetPassword = (id, password) => dispatch => axios.post(`/auth/reset/${id}`, { password })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

