import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../../utils/constants'
import * as NOTIFICATIONS from '../../../../utils/notification'
import * as COMPANY_REQUESTS from '../../../../redux/actions/companies'

import Table from '../../../common/SimpleTable'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    }
})


class Files extends Component {

    state = {
        count: 0,
        from: 0,
        search: '',
        rowsPerPage: CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE,
        rows: [],
        allRows: []
    }

    componentDidUpdate(prevProps) {
        if(prevProps.files !== this.props.files) {
            this.setFiles(this.props.files)
          }
    }

    componentDidMount() {
        this.setFiles(this.props.files) 
    }

    setFiles = files => {
        this.setState({
            rows: files.map((file, index) => ({ index: index + 1, _id: file._id, name: file.originalName, _fileName: file.fileName })),
            allRows: files.map((file, index) => ({ index: index + 1, _id: file._id, name: file.originalName, _fileName: file.fileName })),
            count: files.length
        }, this.setFilesChangePage)
    }

    setFilesChangePage = () => {
        let filesCopy = this.state.allRows.map(field => ({ ...field }))
        if (this.state.search) {
            filesCopy = filesCopy.filter(file => file.name.includes(this.state.search))
        }
        filesCopy = filesCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: filesCopy })
    }

    setSearch = search => {
        this.setState({ search }, () => {
            this.onSearch()
        })
    }

    onSearch = () => {
        let filesCopy = [...this.state.allRows].map(field => ({ ...field }))
        filesCopy = filesCopy.filter(file => file.name.includes(this.state.search))
        let searchCount = filesCopy.length
        filesCopy = filesCopy.slice(this.state.from, this.state.from + this.state.rowsPerPage)
        this.setState({ rows: filesCopy, count: searchCount })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.setFilesChangePage)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.setFilesChangePage)
    }

    fileUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        fileFormData.append('file', filesArray[0])
        this.props.uploadFileTo(this.props.companyId, this.props.buildingId, fileFormData)
            .then(() => {
                this.props.getCompanyDetails()
                NOTIFICATIONS.success(this.props.language.toastr.file.successUpload)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.file.errorUpload)
            })
    }

    render() {
        let { classes } = this.props
        
        return (
            <div className={classes.container}>
                <Table
                    title={this.props.language.titles.companyFiles}
                    header={this.props.language.tableHeaders.companyFiles}
                    rows={this.state.rows}
                    onClickRow={item => {
                        this.props.download(this.props.companyId, this.props.buildingId, item._id, item.name)
                    }}
                    headerButton={{
                        upload: true,
                        tooltip: this.props.language.buttons.add,
                        onClick: (event) => {
                            this.fileUploadHandler(event)
                        },
                        icon: <Button color="primary" variant="contained" className={classes.button} >
                            {this.props.language.buttons.add}
                        </Button>
                    }}
                    search={{
                        onSearch: this.setSearch
                    }}
                    actions={[
                        {
                            tooltip: this.props.language.buttons.deleteFile,
                            onClick: (_, file) => {
                                let confirmed = window.confirm(this.props.language.toastr.file.confirmDelete)

                                if (!confirmed) return

                                this.props.deleteFile(this.props.companyId, this.props.buildingId, file._id).then(() => {
                                    this.props.getCompanyDetails()
                                    NOTIFICATIONS.success(this.props.language.toastr.file.successDelete)
                                })
                                    .catch(() => {
                                        NOTIFICATIONS.error(this.props.language.toastr.file.errorDelete)
                                    })
                            },
                            icon: <DeleteIcon />
                        }
                    ]}
                    count={this.state.count}
                    rowsPerPage={this.state.rowsPerPage}
                    hasNoMargin={true}
                    changeRowsPerPage={this.changeRowsPerPageHandler}
                    changePageHandler={this.changePageHandler}
                />
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    uploadFileTo: (id, buildingId, files) => dispatch(COMPANY_REQUESTS.uploadFile(id, buildingId, files)),
    deleteFile: (id, buildingId, fileId) => dispatch(COMPANY_REQUESTS.deleteFile(id, buildingId, fileId)),
    download: (id, buildingId, fileId, fileName) => dispatch(COMPANY_REQUESTS.download(id, buildingId, fileId, fileName))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Files))